<site-header></site-header>
<link rel="stylesheet" href="/assets/css/bootstrap-table.css">
<style type="text/css">
    img {
        height: auto;
        max-width: 100%;
    }

    .cell {
        display: table-cell;
    }

    .cell-fluid {
        width: 100%;
    }
</style>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Video Scheduler</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Video Scheduler</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row video_main">
            <div class="col-xl-12">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <!-- <header class="panel-heading">
                        <h3 class="panel-title">
                            Video Scheduler
                        </h3>
                    </header> -->

                    <div class="panel-body">
                        <form [formGroup]="bannerForm" #myForm="ngForm" (ngSubmit)="onSubmit(bannerForm, myForm)"
                            class="">
                            <div class="row">

                                <!-- <div class="col-md-4">
                                    <div class="mb-15">
                                        <input autocomplete="off" type="text" class="form-control"
                                            formControlName="name"
                                            [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors , 'disabledTable':silver=='silver' || diamond=='diamond' || gold=='gold' }"
                                            placeholder="Main Category Name">
                                        <div *ngIf="(f.name.touched || submitted) && f.name.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.name.errors?.required">Main category is required</div>
                                        </div>
                                        <div *ngIf="(f.name.touched || submitted) && f.name.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.name.errors?.maxlength">Maximum 100 characters are allowed
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-md-4">
                                    <!-- <div class="mb-15">
                                        <input autocomplete="off" type="text" class="form-control"
                                            formControlName="name"
                                            [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors , 'disabledTable':silver=='silver' || diamond=='diamond' || gold=='gold' }"
                                            placeholder="Main Category Name">
                                        <div *ngIf="(f.name.touched || submitted) && f.name.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.name.errors?.required">Main category is required</div>
                                        </div>
                                        <div *ngIf="(f.name.touched || submitted) && f.name.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.name.errors?.maxlength">Maximum 100 characters are allowed
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="mb-15">
                                        <div class="input-group _input_group filter_date">

                                            <input angular-mydatepicker (click)="dp1.toggleCalendar();"
                                                class="form-control" placeholder="Select Date Range"
                                                [options]="myDatePickerOptions1" formControlName="date"
                                                (dateChanged)="onDateRangeChanged($event)"
                                                [ngClass]="{ 'is-invalid': (f.date.touched || submitted) && f.date.errors }"
                                                #dp1="angular-mydatepicker">



                                            <div class="input-group-append no_zindex">
                                                <button type="button" class="select_date_btn_cancel"
                                                    *ngIf="selected_date1" (click)="dp1.clearDate()">
                                                    <i class="fa fa-times"></i>
                                                </button>
                                            </div>
                                            <div class="input-group-append no_zindex">
                                                <button type="button" class="select_date_btn"
                                                    (click)="dp1.toggleCalendar()">
                                                    <i class="nav-icon fas fa-calendar" aria-hidden="true"></i>
                                                </button>
                                            </div>

                                            <div *ngIf="(f.date.touched || submitted) && f.date.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="f.date.errors.required">Date is required</div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <!-- <div class="col-md-4">
                                    <div class="mb-15 ">
                                        <input #myInput *ngIf="!imageExist" type="file" class="form-control"
                                            id="large_image" formControlName="large_image"
                                            [ngClass]="{ 'is-invalid': (f.large_image.touched || submitted) && f.large_image.errors }"
                                            placeholder="Image" (change)="handleFileInput($event, $event.target.files)">
                                        <div *ngIf="(f.large_image.touched || submitted) && f.large_image.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="f.large_image.errors.required">Image is required</div>
                                        </div>
                                        <a *ngIf="imageExist" class="mr-10" href="{{media_domain+imageExist}}"
                                            target="_blank">Main
                                            Category
                                            Image</a><a *ngIf="imageExist" (click)="imageEdit($event)">| Change</a>
                                        <small style="display: block">(Image Dimensions:
                                            {{maincatwidth_limit}}*{{maincatheight_limit}})</small>
                                    </div>
                                </div> -->
                                <!-- <div class="col-md-1">
                                    <div class="checkbox-custom checkbox-primary">
                                        <input type="checkbox" id="inputForProject" formControlName="status" value="1">
                                        <label for="inputForProject">Active</label>
                                    </div>
                                </div> -->
                                <div class="col-md-3">
                                    <div class="mb-15">
                                        <button [disabled]="loading" class="btn btn-primary video_topbtn" type="submit">
                                            <i class="icon md-plus" aria-hidden="true"></i>
                                            <div *ngIf="cancelButton;then update else add">here is ignored</div>
                                            <ng-template #add>Add</ng-template>
                                            <ng-template #update>Update</ng-template>
                                        </button>
                                        <button style="margin-left:5px" (click)="cancelSubmit($event)"
                                            class="btn btn-danger" *ngIf="cancelButton" type="button">
                                            <i class="icon md-close" aria-hidden="true"></i> Cancel
                                        </button>
                                        <img *ngIf="loading"
                                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="tb-wrapper">
                            <div class="table-responsive">
                                <table cellspacing="0" id="table"
                                    class="table table-hover dataTable table-striped  table-bordered video_table w-full" datatable
                                    [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                    <thead>
                                        <tr>
                                            <th width="8%">Sl No.</th>
                                            <!-- <th width="10%">Image</th>
                                        <th width="10%">Name</th>
                                        <th width="10%">Status</th> -->
                                            <th width="10%">From Date</th>
                                            <th width="10%">To Date</th>
                                            <th width="10%">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="users$">
                                        <tr *ngFor="let user of users$; let i = index">
                                            <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                            <td>{{ user.from }}</td>
                                            <td>{{ user.to }}</td>
                                            <!-- <td>
                                            <div class="cell"><img class="cell-fluid img"
                                                    src="{{media_domain}}{{ user.image }}" />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="cell">{{ user.title}}
                                            </div>
                                        </td>
                                        <td>
                                            <div *ngIf="user.active == 1 ;then content else other_content">here is
                                                ignored</div>
                                            <ng-template #content>Active</ng-template>
                                            <ng-template #other_content>Inactive</ng-template>
                                        </td> -->
                                            <td>
                                                <a (click)="userEdit($event, user, i)" href="javascript:void(0);"
                                                    class="btn btn-sm btn-icon btn-pure btn-default on-default edit-row"
                                                    data-toggle="tooltip"><i class="icon md-edit text-success"
                                                        aria-hidden="true"></i>
                                                </a>
                                                <a (click)="userDelete($event, user, i)" href="javascript:void(0);"
                                                    class="btn btn-sm btn-icon btn-pure btn-default on-default remove-row"
                                                    data-toggle="tooltip"><i class="icon md-delete text-danger"
                                                        aria-hidden="true"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!users$">
                                        <th></th>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
                <!-- End Panel Kitchen Sink -->
            </div>
        </div>
    </div>
</div>
<script src="/assets/js/bootstrap-table.js"></script>
<!-- End Page -->
<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }
</style>