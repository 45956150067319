import { Component, OnInit } from '@angular/core';
import { ProductService, AlertService,HomeService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';


declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-user-feedback',
  templateUrl: './user-feedback.component.html',
  styleUrls: ['./user-feedback.component.css']
})
export class UserFeedbackComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  feedback$: any;
  loading =  false;
  length:any;
  curUrl:any;
  from:any;
  to:any;
  total:any;
  last_page:any;
  perPage = 10;
  curPage = 1;
  fq1:any;
  fq2:any;
  fq3:any;
  fq4:any;
  fq5:any;
  question$:any;
  constructor(private pro: ProductService,
    private home: HomeService,
     private toastr: ToastrService) { }

  ngOnInit() {

    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };

this.loading = true;
    this.home.getQuestion().subscribe(data => {
      this.loading = false;
      this.question$ = data.data;
     this.fq1 = this.question$[0].question;
     this.fq2 = this.question$[1].question;
     this.fq3 = this.question$[2].question;
     this.fq4 = this.question$[3].question;
     this.fq5 =  this.question$[4].question;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });


    this.pro.getUserfeedback('').subscribe(data => {
      this.loading = false;
      this.feedback$ = data.data.data;
      this.total= data.data.total;
      this.from = data.data.from;
      this.to = data.data.to;
      this.last_page = data.data.last_page;
      this.curPage = data.data.current_page;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    })
  }

  clickFeedback(message) {
    $('.message_text').text(message);
  }


  pageChange(e){
    this.loading = true;
    this.pro.getUserfeedback(e).subscribe(data => {
      this.loading = false;
      this.feedback$ = data.data.data;
      this.total= data.data.total;
      this.from = data.data.from;
      this.to = data.data.to;
      this.last_page = data.data.last_page;
      this.curPage = data.data.current_page;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }



  goBack() {
    this.curUrl = localStorage.getItem('currentUrl');
    location.href = this.curUrl;
  }

  editSingleOrder(){}

 
}
