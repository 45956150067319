import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { CategoryService, AlertService,ProductService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-back-chain',
  templateUrl: './back-chain.component.html',
  styleUrls: ['./back-chain.component.css']
})
export class BackchainComponent implements OnInit {
  ids:any;
  bannerForm: FormGroup;
  loading = false;
  imageExist = '';
  imageExist1 = '';
  fileToUpload: File = null;
  fileToUpload1: File = null;
mainCat : any;
  submitted = false;
  //table = false;
  cancelButton = false;
  users$: Observable<any[]>;
  main$: Observable<any[]>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  width_limit = `${environment.bannerWidth}`;
  height_limit = `${environment.bannerHeight}`;
  media_domain = `${environment.media_domain}`;
  subcatwidth_limit = `${environment.subcatWidth}`;
  subcatheight_limit = `${environment.subcatHeight}`;
  main_cat = [];
  main_cat_name = [];
  curPage = 1;
  perPage = 10;

  subCategory = {

    displayKey: 'title', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select main category', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No main category found', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search main category',
    padding: '15px', // label thats displayed in search input,


    selectAllBtnText: 'Select',
    searchOnKey: 'title' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys

    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private bannersService: CategoryService,
    private pro: ProductService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
  
    const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    this.bannerForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      _id: [{ value: null, disabled: true }],
     // name: ['', [Validators.required]],
     // mainCat: ['', [Validators.required]],
      large_image: [''],
      large_image1: [''],
     // type: [''],
    //  sku: [''],
    //  weight: ['']
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };

    this.pro.getChaintype().subscribe(data => {
      this.users$ = data.data.details;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });

    this.bannersService.getMainCat().subscribe(data => {
      this.main$ = data.data.main;
    }, error => {
      console.error(error);
    });

  }

  handleFileInput(event: any, files: FileList) {
    var that = this;
    var file = files[0];
    var fileType = file["type"];
    var validImageTypes = ["image/gif", "image/jpeg", "image/png","image/jpg"];
    if ($.inArray(fileType, validImageTypes) < 0) {
       that.toastr.error('Please upload image only');
       that.bannerForm.get('large_image').setValue('');
       that.fileToUpload = null;
       return false;
    }
    var reader = new FileReader();

    reader.onload = function (e) {
      var img = new Image;
      img.onload = function () {
        var imgWidth = parseInt(that.subcatwidth_limit)
        var imgHeight = parseInt(that.subcatheight_limit)
          that.fileToUpload = files.item(0);
      };
      img.src = reader.result as string;
    };

    reader.readAsDataURL(files.item(0));

  }
  handleFileInput1(event: any, files: FileList) {
    var that = this;
    var file = files[0];
    var fileType = file["type"];
    var validImageTypes = ["image/gif", "image/jpeg", "image/png","image/jpg"];
    if ($.inArray(fileType, validImageTypes) < 0) {
       that.toastr.error('Please upload image only');
       that.bannerForm.get('large_image').setValue('');
       that.fileToUpload1 = null;
       return false;
    }
    var reader = new FileReader();

    reader.onload = function (e) {
      var img = new Image;
      img.onload = function () {
        var imgWidth = parseInt(that.subcatwidth_limit)
        var imgHeight = parseInt(that.subcatheight_limit)
          that.fileToUpload1 = files.item(0);
      };
      img.src = reader.result as string;
    };

    reader.readAsDataURL(files.item(0));

  }

  selectmainCategory(e) {
    console.log(e.value[0]._id);
    this.main_cat_name = [];
    this.main_cat = [];
    for (let ids of e.value) {
      this.main_cat.push(ids._id);
    }
this.mainCat = e.value[0]._id;
    this.main_cat_name = e.value;
  }


  // convenience getter for easy access to form fields
  get f() { return this.bannerForm.controls; }

  onSubmit(form, formWrap) {

    this.submitted = true;

    //let id = form.getRawValue()._id;
    let index = form.getRawValue().index;

    // if (id == null || !this.imageExist) {
    //   this.bannerForm.get('large_image').setValidators([Validators.required]);
    //   this.bannerForm.get('large_image').updateValueAndValidity();
    // }
    // stop here if form is invalid
    if (this.bannerForm.invalid) {
      return;
    }
var that =  this;

    if (that.ids != null) {
    //  document.body.classList.add('jw-modal-open');
      that.loading = true;
      that.pro.updatebackchain(that.ids, that.fileToUpload, that.fileToUpload1).subscribe(data => {
      
        if (data.status && data.status.code == 0) {
           //  location.reload();
              that.submitted = false;
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
              that.cancelButton = false;
           
              that.bannerForm.setValue({
                index: null,
                _id: null,
                name: '',
                weight: '',
                mainCat: '',
                sku: '',
                type: '',
                large_image: ''
              })
              that.bannerForm.reset();
              that.imageExist = '';
              that.fileToUpload = null;
              that.imageExist1 = '';
              that.fileToUpload1 = null;
             location.reload();
             location.href = "/back-chain";

            } else {
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
              that.toastr.error(data.status.message)
              location.reload();
            }
          },
          error => {
            that.toastr.error(error)
           
            document.body.classList.remove('jw-modal-open');
            that.loading = false;
          });
    } else {
      document.body.classList.add('jw-modal-open');
      that.loading = true;
   
      that.pro.addbackchain(that.fileToUpload, that.fileToUpload1 ? that.fileToUpload1 : null)
        .subscribe(
          data => {
          console.log(data)
            if (data.status.code == 0) {
              that.toastr.success('successfully added');
              that.users$ = data.data.main;
              location.reload();
              that.pro.getChaintype().subscribe(data => {
             
                that.users$ = data.data.details;
                that.dtTrigger.next();
              }, error => {
                console.error(error);
              });
              that.submitted = false;
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
              that.cancelButton = false;
           location.reload();

              //that.users$.unshift(data.data.banner)

              that.bannerForm.setValue({
                index: null,
                _id: null,
                name: '',
                weight: '',
                mainCat: '',
                sku: '',
                type: '',
                large_image: ''
              })
              that.bannerForm.reset();
            //  location.reload();
              that.fileToUpload = null;
              that.fileToUpload1 = null;

              //that.router.navigate(['/banners']);
             location.href = "/back-chain";

            } else {
              document.body.classList.remove('jw-modal-open');
              that.loading = false;
              that.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //that.alertService.error(data.status.message);
            }
          },
          error => {
            that.toastr.error(error)
            // alertify.error(error)
            //that.alertService.error(error);
            document.body.classList.remove('jw-modal-open');
            that.loading = false;
          });
    }
    //that.bannerForm.reset() // reset form to empty


  }

  imageEdit(event) {
    this.imageExist = '';
    this.imageExist1 = '';
  }
  userEdit(event, user, i) {
    this.ids = user._id;
    this.main_cat_name = [];
    this.main_cat = [];
this.mainCat = user.maincat && user.maincat.title;
    this.cancelButton = true;
    //this.table = event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
    document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
    this.bannerForm.patchValue({
      index: i,
      _id: user._id,
   
      large_image: ''
    })

    this.main_cat_name = user.main_category;

    // for (let mc of user.main_category) {
    //   this.main_cat.push(mc._id);
    // }

    this.imageExist = user.image;
    this.imageExist1 = user.laceimage;
  }

  cancelSubmit(event) {
    location.reload();
    this.ids = '';
    this.imageExist1 = '';
    this.imageExist = '';
    this.cancelButton = false;
    document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
    this.bannerForm.patchValue({
      index: null,
      _id: null,
   
      large_image: ''
    
    });
    this.submitted = false;
  }
  userDelete(event, user, i) {
    var that = this;
    alertify.confirm('Are you sure want to delete this record', function () {
      that.pro.deleteBackchain(user._id)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.toastr.success('Deleted Successfully')
              // alertify.success('Deleted Successfully')
              setTimeout(function () {
                location.reload();
              }, 1000);
              //that.users$.splice(i, 1);
            } else {
              that.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            that.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
          });
    }
      , function () { //alertify.error('Delete action cancelled')
        that.toastr.error('Delete action cancelled')

      });

  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }
}
