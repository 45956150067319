<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>

<div class="page">
	<div class="page-header">
		<h1 class="page-title">Daily Rates</h1>
		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a href='/home'>Home</a></li>
			<li class="breadcrumb-item active">Daily Rates</li>
		</ol>
	</div>
</div>
<div class="container-fluid cont">
	<div class="row">
		<div class="col-md-6">

			<div class="panel">
				<div class="panel-body">

					<h4>
						<!-- <span class="panel_title">Daily Rates</span> -->
						<button class="btn btn-info btn-sm pull-right edit daily_edit" name="edit-rates"
							(click)="editDaily()"><i class="fas fa-pencil-alt"></i> Edit </button>
						<div class="pull-right daily_editaction">
							<button class="btn btn-success btn-sm hidden sc daily_save" (click)="submitDaily()"
								name="edit-save">Save
							</button>
							<button class="btn btn-danger btn-sm hidden dn ml-2 daily_cancel" name="edit-cancel"
								(click)="cancelDaily()">Cancel </button>
						</div>
					</h4>

					<form [formGroup]="dailyrateForm" (ngSubmit)="submitDaily()" class="form-horizontal" id="300"
						name="form-insert">
						<table class="table table-bordered table-striped" width="100%">
							<thead>
								<tr>
									<th width="65%">Metal Type
									</th>
									<th width="35%">Per Gram
									</th>
								</tr>
							</thead>

							<tbody name="daily-rates">
								<tr>
									<td>Gold 22 kt (22)

									</td>
									<td class="disp" style="display: none;">
										<input type="text" class="form-control disp" name="0"
											[ngClass]="{ 'is-invalid': (f.gold22.touched ||submitted) && f.gold22.errors }"
											formControlName="gold22" value="22" autocomplete="off" id="gold22"
											placeholder="Enter only 5 digits">
										<div *ngIf="f.gold22.errors?.pattern" class="invalid-feedback">
											<div *ngIf="f.gold22.errors?.pattern">Enter valid digits</div>
										</div>
										<div *ngIf="f.gold22.errors?.maxlength" class="invalid-feedback">
											<div *ngIf="f.gold22.errors?.maxlength">Enter only 5 digits</div>
										</div>
									</td>
									<td class="form-inline"><span>Rs.</span> <span>{{gold22?gold22:'0'}}</span>


									</td>
								</tr>
								<tr>
									<td>
										Gold 18 kt (18)
									</td>
									<td class="disp" style="display: none;">
										<input type="text" class="form-control disp" name="1"
											[ngClass]="{ 'is-invalid': (f.gold18.touched || submitted) && f.gold18.errors }"
											formControlName="gold18" value="18" autocomplete="off" id="gold18"
											placeholder="Enter only 5 digits">
										<div *ngIf="f.gold18.errors?.pattern" class="invalid-feedback">
											<div *ngIf="f.gold18.errors?.pattern">Enter valid digits</div>
										</div>
										<div *ngIf="f.gold18.errors?.maxlength" class="invalid-feedback">
											<div *ngIf="f.gold18.errors?.maxlength">Enter only 5 digits</div>
										</div>
									</td>
									<td class="form-inline"><span>Rs.</span> <span>{{gold18?gold18:'0'}}</span>


									</td>
								</tr>
								<tr>
									<td>
										Silver
									</td>
									<td class="disp" style="display: none;">
										<input type="text" class="form-control disp" name="2" value="0"
											autocomplete="off" formControlName="silver" [ngClass]="{ 'is-invalid': 
							(f.silver.touched || submitted) && f.silver.errors }" id="silver" placeholder="Enter only 5 digits">
										<div *ngIf="f.silver.errors?.pattern" class="invalid-feedback">
											<div *ngIf="f.silver.errors?.pattern">Enter valid digits</div>
										</div>
										<div *ngIf="f.silver.errors?.maxlength" class="invalid-feedback">
											<div *ngIf="f.silver.errors?.maxlength">Enter only 5 digits</div>
										</div>
									</td>
									<td class="form-inline"><span>Rs.</span> <span>{{silver?silver:'0'}}</span>

									</td>
								</tr>
							</tbody>
						</table>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>