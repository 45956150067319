<nav class="site-navbar navbar navbar-default navbar-fixed-top navbar-mega navbar-inverse" role="navigation">
    <div class="navbar-header">
        <button type="button" class="navbar-toggler hamburger hamburger-close navbar-toggler-left hided"
        data-toggle="menubar">
        <span class="sr-only">Toggle navigation</span>
        <span class="hamburger-bar"></span>
        </button>
        <a class="navbar-brand navbar-brand-center" href='/home'>
            <img class="navbar-brand-logo navbar-brand-logo-normal" src="/assets/logo.svg">
           
        </a>
        <a class="navbar-toggler" href='/login'>
            <i class="icon md-power" aria-hidden="true"></i>
        </a>
    </div>
    <div class="navbar-container container-fluid">
        <div class="collapse navbar-collapse navbar-collapse-toolbar" id="site-navbar-collapse">
            <ul class="nav navbar-toolbar">
                <li class="dropdown nav-item">
                    <a class="dropdown-toggle nav-link" data-toggle="dropdown" href="#">
                        <i class="site-menu-icon md-image" aria-hidden="true"></i><span
                        class="site-menu-title">Orders</span>
                        <span class="caret"></span></a>
                        <ul class="dropdown-menu">
                            <li><a href="/orders" class="dropdownLink">Customer Orders</a></li>
                            <!-- <li><a href="/missed-order" class="dropdownLink">Missed Orders</a></li> -->
                        </ul>
                    </li>
                    <li class="dropdown nav-item">
                        <a class="nav-link" href="/daily-rates">
                            <i class="site-menu-icon md-image" aria-hidden="true"></i><span
                            class="site-menu-title">Daily Rates</span>
                        </a>
                        
                    </li>
                    <li class="dropdown nav-item">
                        <a class="dropdown-toggle nav-link" data-toggle="dropdown" href="#">
                            <i class="site-menu-icon md-image" aria-hidden="true"></i><span
                            class="site-menu-title">Product Details</span>
                            <span class="caret"></span></a>
                            <ul class="dropdown-menu">
                                <li><a href="/product" class="dropdownLink">Add Products</a>
                            </li>
                            <li><a href="/delete-product" class="dropdownLink">Delete Products</a></li>
                            <li><a href="/make-outofstock" class="dropdownLink">Out of stock </a></li>
                            <li><a href="/product-images" class="dropdownLink">Image Bulk Upload</a></li>
                            <li><a href="/main-category" class="dropdownLink">Add Main Category</a></li>
                            <li><a href="/sub-category" class="dropdownLink">Add Sub Category</a></li>
                            <li><a href="/promocode" class="dropdownLink">Promo code </a></li>
                            <!-- <li><a href="/product-offer" class="dropdownLink">Category Offer</a></li> -->
                            <!-- <li><a href="/back-chain" class="dropdownLink">Back Chain</a></li> -->
                        </ul>
                    </li>
                    <li class="dropdown nav-item">
                        <a class="dropdown-toggle nav-link" data-toggle="dropdown" href="#">
                            <i class="site-menu-icon md-image" aria-hidden="true"></i><span
                            class="site-menu-title">Customers</span>
                            <span class="caret"></span></a>
                            <ul class="dropdown-menu">
                                <!-- <li><a href="/feedback" class="dropdownLink">Customer Feedback</a></li>
                                <li><a href="/enquiries" class="dropdownLink">Product Enquiries</a></li> -->
                                <li><a href="/users" class="dropdownLink">Users</a></li>
                                <!-- <li><a href="/schemes" class="dropdownLink">Schemes</a></li> -->
                               
                            </ul>
                        </li>



                        <li class="dropdown nav-item">
                        <a class="dropdown-toggle nav-link" data-toggle="dropdown" >
                            <i class="site-menu-icon md-truck" aria-hidden="true"></i><span
                            class="site-menu-title">Delivery Charges</span>
                            <span class="caret"></span></a>
                            <ul class="dropdown-menu">
                                <li><a href="/del-states" class="dropdownLink">States</a></li>
                                <li><a href="/del-districts" class="dropdownLink">Districts</a></li>
                                
                            </ul>
                        </li>



                        <!-- <li class="dropdown nav-item">
                            <a class="dropdown-toggle nav-link" data-toggle="dropdown" href="#">
                                <i class="site-menu-icon md-image" aria-hidden="true"></i><span
                                class="site-menu-title">Special Offer</span>
                                <span class="caret"></span></a>
                                <ul class="dropdown-menu">
                                    <li><a href="/offer-products" class="dropdownLink">Add Special Product</a></li>
                                    <li><a href="/website-product" class="dropdownLink">Product Offer</a></li>
                                    <li><a href="/mantra" class="dropdownLink">Special Offer Banner</a></li>
                                    
                                </ul>
                            </li> -->

                            <li class="dropdown nav-item">
                                <a class="dropdown-toggle nav-link" data-toggle="dropdown" href="#">
                                    <i class="site-menu-icon md-settings" aria-hidden="true"></i><span
                                    class="site-menu-title">Settings</span>
                                    <span class="caret"></span></a>
                                    <ul class="dropdown-menu">
                                        <!-- <li><a href="/approve-transaction" class="dropdownLink">
                                            Approve PAN Card</a></li> -->
                                        <!-- <li><a href="/banners" class="dropdownLink">
                                        Add Banner images</a></li> -->
                                        <!-- <li><a href="/promotion" class="dropdownLink">
                                        Pop Up Banner</a></li> -->
                                        <!-- <li><a href="/meta" class="dropdownLink">
                                        Add Page Meta Data</a></li> -->
                                        <!-- <li><a href="/branches" class="dropdownLink">
                                        Add Branches</a></li> -->
                                        <li><a href="/email" class="dropdownLink">
                                        Email</a></li>
                                        <li><a href="/privacy" class="dropdownLink">
                                        Privacy</a></li>
                                        <li><a href="/faq" class="dropdownLink">
                                            Add FAQs</a></li>
                                       
                                        <li><a href="/settings" class="dropdownLink">Change Password</a></li>
                                        <!-- <li><a href="/video-request" class="dropdownLink">Video Request Block</a></li> -->
                                    </ul>
                                </li>
                                
                                <li class="dropdown nav-item">
                                    <a class="dropdown-toggle nav-link" data-toggle="dropdown" href="#">
                                        <i class="site-menu-icon md-image" aria-hidden="true"></i><span
                                        class="site-menu-title">New</span>
                                        <span class="caret"></span></a>
                                        <ul class="dropdown-menu">
                                            <li><a href="/whatsnew" class="dropdownLink">What's New</a></li>
                                            <!-- <li><a href="/feedback-question" class="dropdownLink">Feedback Question</a></li>
                                            <li><a href="/video-calling" class="dropdownLink">Video Calling</a></li>
                                            <li><a href="/whatsappclick" class="dropdownLink">Whatsapp Click</a></li>
                                            <li><a href="/report-problem" class="dropdownLink">Report Problem</a></li>
                                            <li><a href="/video-scheduler" class="dropdownLink">Video Scheduler Block</a></li>
                                            <li><a href="/remarketing-ads" class="dropdownLink">
                                                Remarketing Ads</a></li>
                                                <li><a href="/user-feedback" class="dropdownLink">
                                                   User Feedback</a></li>
                                                   <li><a href="/product-click" class="dropdownLink">
                                                    Most Ordered Product</a></li>
                                                    <li><a href="/most-viewed" class="dropdownLink">
                                                        Most Clicked Product</a></li> -->
                                                  
                                        </ul>
                                    </li>

                            </ul>

                            
                            <!-- Navbar Toolbar Right -->
                            <ul class="nav navbar-toolbar navbar-right navbar-toolbar-right">
                                <!--  <li class="nav-item">
                                    <a class="nav-link" title="Logout" href='javascript:void(0);'>
                                        <strong>Welcome {{adminName}}!</strong>
                                    </a>
                                </li> -->
                                <li class="nav-item">
                                    <a class="nav-link" title="Logout" href='/login'>
                                        <i class="icon md-power" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>