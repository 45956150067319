import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RouterModule } from '@angular/router';
@Component({
  selector: 'site-header',
  templateUrl: './site-header.component.html',
})
export class SiteHeaderComponent implements OnInit {
  constructor() { }
  adminName = localStorage.getItem(`${environment.appName}` + '_adminName');
  role = localStorage.getItem(`${environment.appName}` + '_role')
  ngOnInit() {
  }

}