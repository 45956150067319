import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient) { }

    login(username: string, password: string) {

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/login`, { email: username, password: password }).pipe(map(user => {
            // login successful if there's a jwt token in the response
          
            if (user.status.code == 0) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
                localStorage.setItem(`${environment.appName}` + '_adminName', user.user.name);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.user.roleId);
                localStorage.setItem(`${environment.appName}` + '_status', user.user.status);
            }

            return user;
        }));

    }




    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem(`${environment.appName}` + 'vendor_user');
        localStorage.removeItem(`${environment.appName}` + '_role');
        localStorage.removeItem(`${environment.appName}` + '_user');
        localStorage.removeItem(`${environment.appName}` + '_adminName');
        localStorage.removeItem(`${environment.appName}` + '_adminId');
        localStorage.removeItem(`${environment.appName}` + '_status');
    }
}
