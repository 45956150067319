import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ModalService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var moment: any;

@Component({
  selector: 'app-consultant',
  templateUrl: './consultant.component.html',
  styleUrls: ['./consultant.component.css']
})
export class ConsultantComponent implements OnInit {

  sel_usr_type = '';
  media_domain = `${environment.media_domain}`;
  dob:any;
  previousRoute: string;
  creditForm: FormGroup;
  creditUser: any = [];
  currentIndex = null;
  loading = false;
  previousUrl = '';
  submitted = false;
  users$: any;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
user_details:any;
  curPage = 1;
  perPage = 10;
  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {
  }
  get f() { return this.creditForm.controls; }

  ngOnInit() {

    const that = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: that.perPage,
      order: [],
      serverSide: true,
      processing: true,
      language: {
        searchPlaceholder: "Name/Mobile no"
      },
      columnDefs: [
        { orderable: false, targets: 3 }
      ],
      // lengthChange: false,
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters['filter_date'] = $('#fini').val();
        that.http
        .post<any>(
          `${environment.apiUrl}${environment.apiPrefix}/admin/consultant`,
          dataTablesParameters, {}
        ).subscribe(resp => {
            that.users$ = resp.data.users;

            that.curPage = resp.data.cur_page;
            $('.custom-filters').appendTo('.dataTables_filter').show();

            callback({
              recordsTotal: resp.data.recordsTotal,
              recordsFiltered: resp.data.recordsFiltered,
              data: []
            });
          });
      }
    };

    this.creditForm = this.formBuilder.group({
      comments: ['', [Validators.required, Validators.maxLength(500)]],
      credits: ['', [Validators.required, Validators.min(1), Validators.max(100000)]],
      type: '1'
    });


  }

  userControl(id: string, state: number) {
    var that = this;
    var state_dips = state == 1 ? "unblock" : "block";
    alertify.confirm('Are you sure want to ' + state_dips + ' user ?', function () {
      that.http
        .post<any>(
          `${environment.apiUrl}${environment.apiPrefix}/user/control/` + id + `/` + state, {}).subscribe(data => {
            if (data.status.code == 0) {
              that.toastr.success('User is ' + state_dips + 'ed')
              setTimeout(function () {
                location.reload();
              }, 1000);
            } else {
              that.toastr.error(data.status.message)
            }
          });
    });
  }

  handleRadioSelection(val) {
    this.submitted = false;
    this.creditForm = this.formBuilder.group({
      comments: ['', [Validators.required, Validators.maxLength(500)]],
      credits: ['', [Validators.required, Validators.min(1), Validators.max(100000)]],
      type: val
    });
  }

  creditsOpenModal(id: string, event, user, index) {
    this.currentIndex = index;
    this.creditUser = user;
    this.modalService.open(id);
    this.creditForm = this.formBuilder.group({
      comments: ['', [Validators.required, Validators.maxLength(500)]],
      credits: ['', [Validators.required, Validators.min(1), Validators.max(100000)]],
      type: '1'
    });
  }

  closeModal(id: string) {

    this.modalService.close(id);

  }

  userDetails(user) {

    this.user_details = user;





    this.modalService.open('user_details');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      $('#fini').daterangepicker({
        autoUpdateInput: false,
        opens: 'left',
        locale: {
          format: 'DD/MM/YYYY'
        },
        ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
      });
      $('#fini').on('apply.daterangepicker', function (ev, picker) {
        $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
        $(this).trigger('change');
      });
      if ($.fn.dataTable.isDataTable('#table')) {

        var table = $('#table').DataTable();

        // $.fn.dataTable.ext.search.push(
        //     function( settings, data, dataIndex ) {
        //       var expolod = (<HTMLInputElement>document.getElementById('fini')).value;
        //       if (expolod) {
        //         var expolod_arr = expolod.split('-');

        //         var iFini_arr = expolod_arr[0].split('/');
        //         var iFini_str = iFini_arr[2]+'/'+iFini_arr[1]+'/'+iFini_arr[0];
        //         var iFini = new Date(iFini_str);

        //         var iFfin_arr = expolod_arr[1].split('/');
        //         var iFfin_str = iFfin_arr[2]+'/'+iFfin_arr[1]+'/'+iFfin_arr[0];
        //         var iFfin = new Date(iFfin_str);

        //         var iStartDateCol = 5;
        //         var date_booked_arr= data[iStartDateCol].split('/');
        //         var date_booked_str = date_booked_arr[2]+'/'+date_booked_arr[1]+'/'+date_booked_arr[0];
        //         var date_booked= new Date(date_booked_str);

        //         if ( !( iFini <= date_booked && date_booked <= iFfin ) ) {
        //           return false;
        //         }
        //       }

        //       return true;
        //     }
        // );


        // Event listener to the two range filtering inputs to redraw on input
        $(document).on('change', '#fini', function () {
          table.draw();
          // table.draw();
        });
      }

    }, 1000);
  }

}
