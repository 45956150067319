<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
    <div class="page-header">
        <h1 class="page-title">Feedback Question</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active">Feedback Question</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row">
            <div class="col-sm-9">
                <div class="panel">
                    <div class="panel-body">
                        <form [formGroup]="faqForm" (ngSubmit)="faqSubmit()">
                            <br>
                            <div class="row">
                                <div class="col-sm-9">
                                    <label>Question</label>
                                    <input type="text" formControlName="question" class="form-control qust_cls"
                                        placeholder="Add question" autocomplete="off">
                                    <div *ngIf="f.question.errors?.required" class="invalid-feedback">
                                        <div *ngIf="f.question.errors?.required">Question is required</div>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <button type="submit" class="btn btn-info mt-4 faq" *ngIf="!id">Save</button>
                                    <ng-container *ngIf="cancelButton">
                                        <button type="submit" class="btn btn-info mt-4 faq" *ngIf="id">Update</button>
                                    </ng-container>
                                    <button type="button" class="btn btn-danger mt-4 ml-2"
                                        (click)="cancelClick()">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="panel">
                    <div class="panel-body">
                        <div class="tb-wrapper">
                            <div class="table-responsive">
                                <table cellspacing="0" id="table"
                                    class="table table-hover dataTable table-striped table-bordered faq_table w-full"
                                    datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                    <thead>
                                        <tr>
                                            <th width="5%">Sl No.</th>
                                            <th width="60%">Question</th>
                                            <th width="10%">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let privacy of privacy$;let j=index">
                                            <td>{{j+1}}</td>
                                            <td>
                                                {{privacy.question}}
                                            </td>
                                            <td>
                                                <i style="cursor: pointer;" class="fa fa-edit text-success"
                                                    (click)="editPrivacy(privacy)" aria-hidden="true"></i>
                                                <!-- <i (click)="deleteFaq(privacy)" title="Delete"
                                                    class="fas fa-trash-alt dlt_cls"></i> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>