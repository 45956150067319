<site-header></site-header>
<link rel="stylesheet" href="/assets/css/bootstrap-table.css">
<style type="text/css">
    img {
        height: auto;
        max-width: 100%;
    }

    .cell {
        display: table-cell;
    }

    .cell-fluid {
        width: 100%;
    }

</style>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Video Request Block</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Video Request Block</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row email_main">
            <div class="col-xl-12">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <!-- <header class="panel-heading">
                        <h3 class="panel-title">
                            Add Email
                        </h3>
                    </header> -->

                    <div class="panel-body">
                     
                        <div class="tb-wrapper">
                         <div class="video_cls">
                             <div class="row">
                                 <div class="col-sm-3">Video Request Block</div>
                                 <div class="col-sm-2" *ngFor="let user of users$;let i=index">
                                  
                                        <label class="switch">
                                            <input
                                                type="checkbox"
                                                id="togBtn{{i}}"
                                                [checked]="user.status==1"
                                                (change)="statusChange(user)">
                                            <span class="slider round">
                                            </span>
                                        </label>
                                    
                                </div>
                             </div>
                         </div>
                          
                        </div>
                    </div>
                </div>
                <!-- End Panel Kitchen Sink -->
            </div>
        </div>
    </div>
</div>
<script src="/assets/js/bootstrap-table.js"></script>
<!-- End Page -->
<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }

</style>
