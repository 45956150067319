import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ModalService, HomeService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var moment: any;
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UserComponent implements OnInit, AfterViewInit {

  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
  sel_usr_type = '';
  media_domain = `${environment.media_domain}`;
  dob: any;
  previousRoute: string;
  creditForm: FormGroup;
  creditUser: any = [];
  currentIndex = null;
  loading = false;
  previousUrl = '';
  submitted = false;
  users$: any;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  user_details: any;
  curPage = 1;
  perPage = 10;
  apiUrl="";
  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private home: HomeService
  ) {
  }
  get f() { return this.creditForm.controls; }


  public async down(): Promise<void> {
    this.submitted = true;
    document.body.classList.add('jw-modal-open');
    this.loading = true;



    const blob = await this.home.download(null


    );
    let binaryData = [];
    binaryData.push(blob);
    const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
    // const url = window.URL.createObjectURL(blob);

    const link = this.downloadZipLink.nativeElement;
    //$('.select_check').prop('checked', false);
    link.href = url;
    console.log(link.href);
    link.download = 'orders.xlsx';
    link.click();

    // window.URL.revokeObjectURL(url);
    this.loading = false;
    document.body.classList.remove('jw-modal-open');



    // link.stop();
  }

  ngOnInit() {

    const that = this;

that.loading = true;

  this.apiUrl = `${environment.apiUrl}`;
    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name/Email/Mobile No."
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };


    this.home.getUser().subscribe(data => {
      that.loading = false;
      console.log(data);
      this.users$ = data.data;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });


    this.creditForm = this.formBuilder.group({
      comments: ['', [Validators.required, Validators.maxLength(500)]],
      credits: ['', [Validators.required, Validators.min(1), Validators.max(100000)]],
      type: '1'
    });


  }

  userControl(id: string, state: number) {
    var that = this;
    var state_dips = state == 1 ? "unblock" : "block";
    alertify.confirm('Are you sure want to ' + state_dips + ' user ?', function () {
      that.http
        .post<any>(
          `${environment.apiUrl}${environment.apiPrefix}/user/control/` + id + `/` + state, {}).subscribe(data => {
            if (data.status.code == 0) {
              that.toastr.success('User is ' + state_dips + 'ed')
              setTimeout(function () {
                location.reload();
              }, 1000);
            } else {
              that.toastr.error(data.status.message)
            }
          });
    });
  }

  handleRadioSelection(val) {
    this.submitted = false;
    this.creditForm = this.formBuilder.group({
      comments: ['', [Validators.required, Validators.maxLength(500)]],
      credits: ['', [Validators.required, Validators.min(1), Validators.max(100000)]],
      type: val
    });
  }

  creditsOpenModal(id: string, event, user, index) {
    this.currentIndex = index;
    this.creditUser = user;
    this.modalService.open(id);
    this.creditForm = this.formBuilder.group({
      comments: ['', [Validators.required, Validators.maxLength(500)]],
      credits: ['', [Validators.required, Validators.min(1), Validators.max(100000)]],
      type: '1'
    });
  }

  closeModal(id: string) {

    this.modalService.close(id);

  }

  userDetails(user) {

    this.user_details = user;





    this.modalService.open('user_details');
  }


  placeMarker(e, user) {
    window.open('https://www.google.com/maps/search/'+ user.lattitude +','+ user.longitude)
    }

  ngAfterViewInit() {

  }
}
