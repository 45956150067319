import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { CategoryService, AlertService, ProductService, HomeService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';

declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
  orders$: any[];
  orderss$: any[];
  loading = false;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  dtTrigger2: Subject<any> = new Subject();

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private bannersService: CategoryService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private home: HomeService,
    private pro: ProductService) { }
    type = '1';
  ngOnInit() {
    this.loading = true;
    this.makeRefresh();

    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name/Invoice No"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };

  }


makeRefresh(){
  this.loading = true;
  this.pro.getOrders(this.type).subscribe(data => {
    console.log(data);
    this.loading = false;
    this.orders$ = data.data;
  }, error => {
    console.error(error);
  });
}


userEdit(e,order){
  location.href = '/order-view/' + order._id;
// this.router.navigate(['/order-view/' + order._id]);
}

  makeOrderConfirm(e,obj){
    alertify.confirm('Are you sure you want to confirm?',()=>{
      this.pro.makeOrderConfirm(obj._id).subscribe(data=>{
        if(data.status.code==0){
          this.toastr.success(data.status.message);
          this.makeRefresh();
        }else{
          this.toastr.error(data.status.message);
        }
      },()=>{

      });
    },()=>{

    });
  }


  public async pdfDownload(e, order): Promise<void> {

    // const blob = this.home.download(order._id);

    // console.error(blob);
    // let binaryData = [];
    // binaryData.push(blob);
    // const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf' }));
    // console.log(url);
    // const link = this.downloadZipLink.nativeElement;
    // console.log(link);
    // console.log(order._id);

    location.href = `${environment.apiUrl}${environment.apiPrefix}/print/printOrder/` + order._id;;
    //`${environment.apiUrl}${environment.apiPrefix}/abharana/printOrder/` + order._id;
    // link.download = 'order.pdf';
    // link.click();

  }
}



