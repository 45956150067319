import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class DeliveryService {
 
  constructor(private http: HttpClient) { }

	  getStateDelivery(search,page,paginate){
	  	return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/state-delivery-charges?search=`+search+`&page=`+page+'&paginate='+paginate).pipe(map(user => {
	            return user;
	        }));
	  }

	  getDistDelivery(search,page,paginate){
	  	return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/dist-delivery-charges?search=`+search+`&page=`+page+'&paginate='+paginate).pipe(map(user => {
	            return user;
	        }));
	  }


	  deleteState(id){
	  	return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/state/`+id).pipe(map(user => {
	            return user;
	        }));
	  }


	  deleteDist(id){
	  	return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/district/`+id).pipe(map(user => {
	            return user;
	        }));
	  }


	  uploadStateExcel(file){
	  	const formData: FormData = new FormData();

	        formData.append('file', file);

	        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/state-delivery-charges`, formData).pipe(map(user => {
	            return user;
	        }));
	  }

	  uploadDistrictExcel(file,state){
	  	const formData: FormData = new FormData();

	        formData.append('file', file);
	        formData.append('state', state);

	        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/dist-delivery-charges`, formData).pipe(map(user => {
	            return user;
	        }));
	  }

  }