import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { CategoryService, AlertService, ProductService, HomeService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})

export class FaqComponent implements OnInit {

  submitted = false;

  cancelButton = false;

  loading = false;

  privacy$: Observable<any[]>;
   dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  faqForm: FormGroup;
id: any;

config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    
    sanitize: true,
    toolbarPosition: 'top',
    uploadWithCredentials: false,
     toolbarHiddenButtons: [
      ['bold']
    ] 
    }

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private bannersService: CategoryService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private home: HomeService) { }

  ngOnInit() {

    this.faqForm = this.formBuilder.group({
      id: [''],
      question: ['',[Validators.required]],
      answer: ['',[Validators.required]],
    });

     this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Question"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };


    this.home.getFaq('').subscribe(data => {
      console.log(data);
      this.privacy$ = data.data;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }


  get f() { return this.faqForm.controls; }
  faqSubmit() {
    console.log(this.faqForm);
    var that = this;
    if(that.faqForm.invalid){
      that.toastr.error('Please enter all fields');
      return false;
    }
    if(that.id != null){
      alertify.confirm('Are you sure want to update this record?', function () {
        that.loading = true;
        that.home.updateFaq(that.id, that.f.question.value, that.f.answer.value).subscribe(data => {
          console.log(data);
          if (data.status.code == 0) {
            that.toastr.success('Successfully updated');
        location.reload();
          } else {
            that.loading = false;
            that.toastr.error(data.status.message);
          }
        }, error => {
          console.error(error);
          that.loading = false;
        });
      }, function () {
        that.loading = false;
        that.toastr.error('Action cancelled');
      });
    }else{
    alertify.confirm('Are you sure want to create this record?', function () {
      that.loading = true;
      that.home.saveFaq(that.f.question.value, that.f.answer.value).subscribe(data => {
        console.log(data);
        if (data.status.code == 0) {
          that.toastr.success('Successfully created');
      location.reload();
        } else {
          that.loading = false;
          that.toastr.error(data.status.message);
        }
      }, error => {
        console.error(error);
        that.loading = false;
      });
    }, function () {
      that.loading = false;
      that.toastr.error('Action cancelled');
    });
    }
  }


  editPrivacy(obj) {
    this.id = obj._id;
    $('.new_s').addClass('disable');
    console.log(obj);
    this.cancelButton = true;
    this.faqForm.patchValue({
      id: obj._id,
      question: obj.question,
      answer: obj.answer
    });
  }


  deleteFaq(faq){
    var that = this;
    alertify.confirm('Are you sure want to delete?', function () {
      that.loading = true;
      that.home.deleteFaq(faq._id).subscribe(data => {
        location.reload();
      }, error => {
        console.error(error);
      })
    }
      , function () {
        that.toastr.error('Delete action cancelled')

      });
  }

  cancelClick() {
    $('.new_s').removeClass('disable');
    this.cancelButton = false;
    this.faqForm.patchValue({
      id: '',
      question: '',
      answer: ''
    });
  }

   ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }


}
