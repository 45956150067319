<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Promocode</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active">Promocode</li>
        </ol>
    </div>
    <div class="container-fluid">
        <div class="news-list promocode_main" style="padding-top: 10px">
            <div class="row">
                <div class="col-md-3">
                    <section id="up" class="panel panel-default">
                        <!-- <header class="panel-heading font-bold text-center pnl"> Promocode </header> -->
                        <div class="panel-body cat insert promo_left" id="100" cat="100">
                            <form [formGroup]="promoForm" (ngSubmit)="promoSubmit()" class="form-horizontal" id="300"
                                name="form-insert">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class=" col-sm-12 control-label">Coupon code</label>
                                            <div class="col-sm-12"> <input formControlName="coupon_code" type="text"
                                                    id="coupon_code"
                                                    [ngClass]="{ 'is-invalid': (f.coupon_code.touched || submitted) && f.coupon_code.errors }"
                                                    name="coupon_code" class="form-control"
                                                    placeholder="Enter Coupon Code " required="true" autocomplete="off"
                                                    style="text-transform: uppercase;">
                                                <div *ngIf="f.coupon_code.errors?.required" class="invalid-feedback">
                                                    <div *ngIf="f.coupon_code.errors?.required">Coupon code is required
                                                    </div>
                                                </div>
                                                <div *ngIf="f.coupon_code.errors?.maxlength" class="invalid-feedback">
                                                    <div *ngIf="f.coupon_code.errors?.maxlength">Maximum 5 charaters
                                                        required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group ">
                                            <label class="col-sm-12 control-label">Mobile Number</label>
                                            <div class="col-sm-12">
                                                <input type="text"
                                                    [ngClass]="{ 'is-invalid': (f.mobile.touched || submitted) && f.mobile.errors }"
                                                    formControlName="mobile" class="form-control" name="mobile_number"
                                                    id="mobile_number" placeholder="Enter Mobile Number" required="true"
                                                    autocomplete="off">
                                                <div *ngIf="f.mobile.errors?.required" class="invalid-feedback">
                                                    <div *ngIf="f.mobile.errors?.required">Mobile No. is required</div>
                                                </div>
                                                <div *ngIf="f.mobile.errors?.pattern" class="invalid-feedback">
                                                    <div *ngIf="f.mobile.errors?.pattern">Enter only digits</div>
                                                </div>
                                                <div *ngIf="f.mobile.errors?.maxlength" class="invalid-feedback">
                                                    <div *ngIf="f.mobile.errors?.maxlength">Enter maximum 15 digits
                                                    </div>
                                                </div>
                                                <div *ngIf="f.mobile.errors?.minlength" class="invalid-feedback">
                                                    <div *ngIf="f.mobile.errors?.minlength">Enter minimum 10 digits
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="col-sm-12 control-label">Status</label>
                                            <div class="col-sm-12">
                                                <select (change)="changeActive($event)" name="status" id="status"
                                                    class="form-control">
                                                    <option value="Active" selected="selected">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="col-sm-12 control-label">Category</label>
                                            <div class="col-sm-12">
                                                <ngx-select-dropdown #id [config]="subCategory" [options]="main$"
                                                    [multiple]=false formControlName="mainCat"
                                                    (change)="selectmainCategory($event)" class="clientSelect"
                                                    style="height:35px;"
                                                    [ngClass]="{ 'is-invalid': (f.mainCat.touched || submitted) && f.mainCat.errors?.required }">
                                                </ngx-select-dropdown>
                                                <div *ngIf="f.mainCat.errors?.required" class="invalid-feedback">
                                                    <div *ngIf="f.mainCat.errors?.required">Category is required</div>
                                                </div>
                                                <!--
                                            [ngClass]="{ 'is-invalid': (f.mainCat.touched || submitted) && f.mainCat.errors?.required }" -->
                                                <!-- <i class="fa fa-check-square " aria-hidden="true"></i><span
                                            style="padding: 10px;">Gold</span> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="line line-dashed b-b line-lg pull-in"> </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="col-sm-12 control-label">Coupon Type</label>
                                            <div class="col-sm-12" style="margin-top: 7px;">
                                                <input (change)="changeCoupon('1')" type="radio" name="coupon_type"
                                                    id="flat" value="1" [checked]="coupon_type=='1'"><span
                                                    style="padding: 10px;">Flat/gram</span><br />
                                                <input type="radio" [checked]="coupon_type=='0'"
                                                    (change)="changeCoupon('0')" name="coupon_type" id="percentage"
                                                    value="0"><span style="padding: 10px;">Percentage/gram</span> <br />
                                                <input type="radio" [checked]="coupon_type=='2'"
                                                    (change)="changeCoupon('2')" name="coupon_type" id="flat_amount"
                                                    value="2"><span style="padding: 10px;">Flat
                                                    on amount</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="col-sm-12 control-label">Amount / Percentage</label>
                                            <div class="col-sm-12">
                                                <input type="text"
                                                    [ngClass]="{ 'is-invalid': (f.amount.touched || submitted) && f.amount.errors }"
                                                    formControlName="amount" class="form-control"
                                                    placeholder="Enter Amount / Percentage" required="true"
                                                    autocomplete="off">
                                                <div *ngIf="f.amount.errors?.required" class="invalid-feedback">
                                                    <div *ngIf="f.amount.errors?.required">Amount / Percentage is
                                                        required</div>
                                                </div>
                                                <div *ngIf="f.amount.errors?.max" class="invalid-feedback">
                                                    <div *ngIf="f.amount.errors?.max">Maximum 99 allowed</div>
                                                </div>
                                                <!-- <ng-template #error><span style="color: red">Maximum 99 allowed</span></ng-template> -->
                                                <!--  <div *ngIf="f.amount.errors?.maxlength" class="invalid-feedback">
                                                <div *ngIf="f.amount.errors?.maxlength">Enter only 3 digits</div>
                                            </div> -->
                                                <div *ngIf="f.amount.errors?.maxlength || f.amount.errors?.pattern"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.amount.errors?.maxlength || f.amount.errors?.pattern">
                                                        Enter
                                                        only 7 digits</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="line line-dashed b-b line-lg pull-in"> </div>
                                <div class="row">


                                </div>
                                <div class="row">
                                    <div class="col-md-1 col-md-offset-3 ml-2">
                                        <!--   <button *ngIf="!cancelButton" type="submit" name="btninsert" id="btninsert"
                                    class="btn btn-primary new_class">Save</button>
                                    <button *ngIf="cancelButton" type="submit" name="btnupdate" id="btnupdate"
                                    class="btn btn-info new_class">Update</button>
                                    <ng-template #update>Update</ng-template> -->
                                        <button class="btn btn-primary mt-3 promocode_btn">
                                            <div *ngIf="cancelButton;then update else add"></div>
                                            <ng-template #add>Submit</ng-template>
                                            <ng-template #update>Update</ng-template>
                                        </button>
                                    </div>
                                    <div class="col-md-1 ">
                                        <button *ngIf="cancelButton" type="button" name="btncancel" id="btncancel"
                                            class="btn btn-danger " (click)="cancelClick()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
                <div class="col-md-9">
                    <section id="content" class="cls">
                        <section class="vbox">
                            <section class="scrollable padder">
                                <!--   <div class="m-b-md">
                                <h3 class="m-b-none">Datatable</h3>
                            </div> -->
                                <section class="panel panel-default" id="down">
                                    <div class="row">
                                        <!-- <div class="col-md-3 ml-2 mt-2">
                                            <header class="panel-heading promocode_title">
                                                <h4>Promocode </h4><i class="fa fa-info-sign text-muted"
                                                    data-toggle="tooltip" data-placement="bottom"></i>
                                            </header>
                                        </div> -->

                                        <!-- <div class="col-md-9 form-inline" style="margin-top: 5px;position: relative;left: 570px;">
                                        <label>Search: </label>
                                        <input type="text" name="search_promocode" id="search_promocode" value="" accesskey="4"
                                        autocomplete="off" class="form-control search_promocode"
                                        placeholder="Mobile Number / Coupon code">
                                    </div> -->
                                    </div>
                                    <div class="table-responsive tbl">
                                        <table cellspacing="0" id="table"
                                            class="table table-hover dataTable table-striped w-full" datatable
                                            [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                            <!--  <table class="table table-striped m-b-none  table-hover"> -->
                                            <thead>
                                                <tr>
                                                    <th width="5%">Sl.No</th>
                                                    <th width="10%">Coupon Code</th>
                                                    <th width="10%">Mobile Number</th>
                                                    <th width="15%">Status</th>
                                                    <th width="10%">Category</th>
                                                    <th width="15%">Coupon type</th>
                                                    <th width="15%">Amount</th>
                                                    <th width="10%">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody class="company-list">
                                                <tr *ngFor="let promo of promo$;let y=index">
                                                    <td width="5%">{{y+1}}</td>
                                                    <td width="10%">{{promo.coupon_code}}</td>
                                                    <td width="10%">{{promo.mobile}}</td>
                                                    <td width="10%">{{promo.status}}</td>
                                                    <td width="10%">{{promo.all?'All':promo.cat[0] &&
                                                        promo.cat[0].title}}</td>
                                                    <td width="10%">
                                                        <ng-container *ngIf="promo.coupon_type=='1'">Flat/gram
                                                        </ng-container>
                                                        <ng-container *ngIf="promo.coupon_type=='2'">Flat on amount
                                                        </ng-container>
                                                        <ng-container *ngIf="promo.coupon_type=='0'">Percentage/gram
                                                        </ng-container>
                                                    </td>
                                                    <td width="10%">{{promo.amount_or_percent}}</td>
                                                    <td width="5%">
                                                        <div class="btn-group promo_action">
                                                            <button type="button"
                                                                class="btn btn-default">Action</button>
                                                            <button type="button"
                                                                class="btn btn-default dropdown-toggle"
                                                                data-toggle="dropdown" aria-expanded="true">
                                                                <span class="caret"></span>
                                                                <span class="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <ul class="dropdown-menu action_control" role="menu">
                                                                <li id="101">
                                                                    <a style="cursor: pointer;"
                                                                        (click)="editPromo(promo)"
                                                                        href="javascript:void(0);">Edit</a>
                                                                </li>
                                                                <li id="103"><a style="cursor: pointer;"
                                                                        href="javascript:void(0)"
                                                                        (click)="deletePromo(promo._id)">Delete</a></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="box-footer clearfix companypage text-right">
                                            <ul class="pagination pagination-sm no-margin pull-right"></ul>
                                        </div>
                                    </div>
                                </section>
                            </section>
                        </section>
                        <a href="#" class="hide nav-off-screen-block" data-toggle="class:nav-off-screen,open"
                            data-target="#nav,html"></a>
                    </section>
                </div>
            </div>

        </div>
    </div>