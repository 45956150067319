import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class WhatsnewService {
    constructor(private http: HttpClient) { }

    save(name: string, url: string, image: File) {
        const formData: FormData = new FormData();
        formData.append('image', image, image.name);
        formData.append('title', name);
        formData.append('url_link', url);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/site/whatsnew`, formData).pipe(map(user => {
            return user;
        }));

    }

    update(id: string, name: string, url: string, image: File) {
        const formData: FormData = new FormData();
        if(image){
            formData.append('image', image, image.name);
        }
     
        formData.append('title', name);
        formData.append('url_link', url);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/site/whatsnew/` + id, formData).pipe(map(user => {
            return user;
        }));

    }

    getWhatsnew(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/site/whatsnew`).pipe(map(user => user));

    }

}
