import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { CategoryService, AlertService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {
  bannerForm: FormGroup;
  loading = false;
  imageExist = '';
  fileToUpload: File = null;

  submitted = false;
  //table = false;
  cancelButton = false;
  users$: Observable<any[]>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  width_limit = `${environment.bannerWidth}`;
  height_limit = `${environment.bannerHeight}`;
  media_domain = `${environment.media_domain}`;
  maincatwidth_limit = `${environment.maincatWidth}`;
  maincatheight_limit = `${environment.maincatHeight}`;
  curPage = 1;
  perPage = 10;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private bannersService: CategoryService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    this.bannerForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      _id: [{ value: null, disabled: true }],
      name: ['', [Validators.required, Validators.maxLength(500)]],
      large_image: '',
      email: ['', [Validators.required, Validators.email]],
      status: ''
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };

    this.bannersService.getMail().subscribe(data => {
      console.log(data);
      this.users$ = data.data;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });

  }

  handleFileInput(event: any, files: FileList) {
    var that = this;
    var reader = new FileReader();

    reader.onload = function (e) {
      var img = new Image;
      img.onload = function () {
        var imgWidth = parseInt(that.maincatwidth_limit)
        var imgHeight = parseInt(that.maincatheight_limit)
        if (img.width != imgWidth || img.height != imgHeight) {
          that.toastr.error('Please upload image of ' + imgWidth + '*' + imgHeight)
          that.bannerForm.get('large_image').setValue('');

          return false;
        } else {
          that.fileToUpload = files.item(0);
        }
      };
      img.src = reader.result as string;
    };

    reader.readAsDataURL(files.item(0));

  }
  // convenience getter for easy access to form fields
  get f() { return this.bannerForm.controls; }

  onSubmit(form, formWrap) {
    this.submitted = true;
    let id = form.getRawValue()._id;
    let index = form.getRawValue().index;

    if (this.bannerForm.invalid) {
      return;
    }


    if (id != null) {
      document.body.classList.add('jw-modal-open');
      this.loading = true;
      this.bannersService.updateMail(id, this.f.name.value, this.f.status.value, this.f.email.value)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              this.submitted = false;
              document.body.classList.remove('jw-modal-open');
              this.loading = false;
              this.cancelButton = false;
              // document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
              // this.users$[index] = data.data.banner;
              location.reload();
              this.bannerForm.setValue({
                index: null,
                _id: null,
                name: '',
                status: '',
                large_image: ''
              })
              formWrap.reset();
              this.imageExist = '';

              this.fileToUpload = null;

              //this.router.navigate(['/banners']);

            } else {
              document.body.classList.remove('jw-modal-open');
              this.loading = false;
              this.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            this.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
            document.body.classList.remove('jw-modal-open');
            this.loading = false;
          });
    } else {
      document.body.classList.add('jw-modal-open');
      this.loading = true;
      this.bannersService.addMail(this.f.name.value, this.f.status.value, this.f.email.value)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              this.toastr.success('successfully added');
              this.users$ = data.data.main;

              this.submitted = false;
              document.body.classList.remove('jw-modal-open');
              this.loading = false;
              this.cancelButton = false;
              location.reload();

              //this.users$.unshift(data.data.banner)

              this.bannerForm.setValue({
                index: null,
                _id: null,
                name: '',
                status: '',
                large_image: ''
              })
              formWrap.reset();
              this.fileToUpload = null;

              //this.router.navigate(['/banners']);
              location.href = "/main-category";

            } else {
              document.body.classList.remove('jw-modal-open');
              this.loading = false;
              this.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            this.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
            document.body.classList.remove('jw-modal-open');
            this.loading = false;
          });
    }
    //this.bannerForm.reset() // reset form to empty


  }

  imageEdit(event) {
    this.imageExist = '';
  }
  userEdit(event, user, i) {
    console.log(this.bannerForm);
    this.cancelButton = true;
    //this.table = event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
    document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
    this.bannerForm.patchValue({
      index: i,
      _id: user._id,
      name: user.name,
      status: parseInt(user.status),
      email: user.email

    })
    this.imageExist = user.image;
  }

  cancelSubmit(event) {
    this.imageExist = '';
    this.cancelButton = false;
    document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
    this.bannerForm.patchValue({
      index: null,
      _id: null,
      name: '',
      status: '',
      email: ''
    })
    this.submitted = false;
  }
  userDelete(event, user, i) {
    var that = this;
    alertify.confirm('Are you sure want to delete this record', function () {
      that.bannersService.deleteMail(user._id)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.toastr.success('Deleted Successfully')
              // alertify.success('Deleted Successfully')
              setTimeout(function () {
                location.reload();
              }, 1000);
              //that.users$.splice(i, 1);
            } else {
              that.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            that.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
          });
    }
      , function () { //alertify.error('Delete action cancelled')
        that.toastr.error('Delete action cancelled')

      });

  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }
}
