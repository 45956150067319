<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
    <div class="page-header">
        <h1 class="page-title">Approve Transaction</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active">Approve Transaction</li>
        </ol>
    </div>
</div>
<div class="container-fluid">

    <div class="panel approve_main">
        <div class="panel-body">
            <div class="row">
                <!-- <div class="col-md-9">
            <h4 class="ng-binding">Customer Feedbacks ({{length}})</h4>
        </div> -->
                <!-- <div class="col-md-3 form-inline">
            <label>Search: </label>
            <input type="text" name="email" autocomplete="off" class="form-control"
                placeholder="Email">
        </div> -->
            </div>
            <div class="table-responsive1 approve_transmain">
                <!-- <h3>Approve Transactions</h3> -->


                <!--   <table class="table table-bordered"> -->
                <table cellspacing="0" id="table" class="table table-hover dataTable table-striped table-bordered apprvtrans_table w-full">
                    <thead>
                        <tr style="font-weight: bold;" align="center">
                            <th width="10%">Sl. No.</th>
                            <th width="15%">NAME</th>
                            <th width="15%">ORDER ID</th>
                            <th width="20%">IMAGE</th>
                            <!-- <th width="20%">USER NAME</th> -->
                            <th width="15%">PAN NUMBER </th>
                            <th width="25%">ACTION </th>

                            <!-- <th width="10%">EMAIL </th>
                            <th width="40%">MESSAGE</th>
                            <th width="15%">CREATED ON.</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let transcation of approvedTranscation$;let n=index" align="center">
                            <td>{{ n+1 }}</td>
                            <td>{{ transcation && transcation.user && transcation.user.first_name ? transcation.user.first_name : '-' }}</td>
                            <td>{{ transcation && transcation.order_id ? transcation.order_id : '-' }}</td>

                            <td>
                                

                                <ng-container *ngIf="transcation.image;else no_image">
                                    <a (click)="viewImage(transcation)" style="cursor:pointer;">
                                        <img src="{{media_domain}}{{transcation.image}}"
                                        class="image">
                                    </a>
                                </ng-container>
                                <ng-template #no_image>-</ng-template>
                            </td>
                            <!-- <td>{{ transcation.name }}</td> -->
                            <td>{{ transcation && transcation.pan_no ? transcation.pan_no : '-' }}</td>
                            <td>
                                <a href="javascript:void(0);"
                                    *ngIf="transcation.order_status == 0 || transcation.order_status == -1 || transcation.order_status == 2"
                                    class="btn btn-sm btn-icon btn-pure btn-default on-default edit-row accept_btn"
                                    (click)="acceptTranscation(transcation.order_id)" data-toggle="tooltip">
                                    Accept
                                </a>
                                <a href="javascript:void(0);" *ngIf="transcation.order_status == 0 || transcation.order_status == 1"
                                    class="btn btn-sm btn-icon btn-pure btn-default on-default remove-row reject_btn"
                                    data-toggle="tooltip" (click)="rejectTranscation(transcation.order_id)">
                                    Reject
                                </a>
                                <a href="javascript:void(0);" *ngIf="transcation.order_status == 2 "
                                class="btn btn-sm btn-icon btn-pure btn-default on-default remove-row reject_btn"
                                data-toggle="tooltip" (click)=" secrejectTranscation(transcation.order_id)">
                                Second Reject
                            </a>
                            </td>

                            <!-- <td>{{feed.email}}</td>
                            <td><a style="color:#337ab7;" data-toggle="modal" (click)="clickFeedback(feed.message)"
                                    class="pop" data-target="#exampleModalCenter">{{feed.message}}</a></td>
                            <td>{{feed.created_at}}</td> -->
                        </tr>
                    </tbody>
                </table>
                <div class="row paginate">
                    <div class="col-sm-8">
                    <div class="shloka_result" *ngIf="approvedTranscation$ != 0">
                                             
Total :  {{total}}  &nbsp; 
Showing &nbsp; {{from}} - {{to}}                     
                   </div>
                      </div>
                   <div class="col-sm-4" *ngIf="approvedTranscation$ && approvedTranscation$.length">
                    <div class="temple_pagination pg_cls">
                        <a class="backward" [ngClass]="{'disable':curPage==1}" (click)="pageChange(curPage-1)"><i
                                class="fas fa-step-backward"></i></a> Page
                        <span>{{curPage}}</span>of {{last_page}}<a [ngClass]="{'disable':curPage==last_page}"
                            class="forward" (click)="pageChange(curPage+1)"><i class="fas fa-step-forward"></i></a>
                    </div>
                </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <span>PAN CARD</span>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <img src="{{media_domain}}{{image}}"
                class="image1">
            </div>
        </div>
    </div>
</div>