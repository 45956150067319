<site-header></site-header>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Order View</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Order View</li>
        </ol>
    </div>
    <div class="container">

        <div class="page-content fff container-fluid pt-0" style="display: none">
            <div name="product_edit" class="mmm">
                <h4>
                    <span>Edit Order</span>
                    <div class="pull-right mb-2">
                        <a class="btn btn-danger btn-sm" (click)="cancelOp()" href="javascript:void(0);"
                            name="edit_cancel">
                            <i class="fa fa-arrow-left"></i> Cancel
                        </a>
                    </div>
                </h4>
                <form [formGroup]="productForm" (ngSubmit)="productSubmit()" class="form-horizontal"
                    name="update_product">
                    <div class="table-responsive">
                        <table class="table table-striped table_view" style="background:#fff !important;">
                            <tbody>
                                <tr>
                                    <td colspan="1">
                                        <fieldset>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">Order-ID</label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="orderid"
                                                            [ngClass]="{ 'is-invalid': (f.orderid.touched || submitted) && f.orderid.errors }"
                                                            name="orderid" placeholder="Order-ID" class="form-control"
                                                            required="true" type="text" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">Address</label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="address"
                                                        [ngClass]="{ 'is-invalid': (f.address.touched || submitted) && f.address.errors }"
                                                        name="address" placeholder="Address" class="form-control"
                                                        required="true" type="text" readonly>
                                                </div>

                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label class="col-md-12 control-label">Net Weight</label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="net_weight"
                                                        [ngClass]="{ 'is-invalid': (f.net_weight.touched || submitted) && f.net_weight.errors }"
                                                        name="net_weight" placeholder="Net Weight" class="form-control"
                                                        required="true" type="text" readonly>
                                                </div>

                                                </div>
                                            </div>

                                        </fieldset>
                                    </td>
                                    <td colspan="1">
                                        <fieldset>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">Invoice No 
                                                </label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="invoice_no"
                                                            [ngClass]="{ 'is-invalid': (f.invoice_no.touched || submitted) && f.invoice_no.errors }"
                                                            name="invoice_no" placeholder="Invoice No"
                                                            class="form-control" required="true" type="text" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">City <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="city"
                                                            [ngClass]="{ 'is-invalid': (f.city.touched || submitted) && f.city.errors }"
                                                            name="city" placeholder="City" class="form-control"
                                                            required="true" type="text" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">Extra Weight</label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="extra_weight"
                                                            [ngClass]="{ 'is-invalid': (f.extra_weight.touched || submitted) && f.extra_weight.errors }"
                                                            name="extra_weight" placeholder="Extra Weight" class="form-control"
                                                            required="true" type="text">
                                                    </div>
                                                </div>
                                            </div>

                                        </fieldset>
                                    </td>
                                    <td colspan="1">
                                        <fieldset>

                                            <div class="form-group">
                                                <label class="col-md-12 control-label">Name <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="name"
                                                            [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors }"
                                                            name="name" placeholder="Name" class="form-control"
                                                            required="true" type="text" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">State</label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="state"
                                                            [ngClass]="{ 'is-invalid': (f.state.touched || submitted) && f.state.errors }"
                                                            name="state" placeholder="State" class="form-control"
                                                            required="true" type="text" readonly>
                                                    </div>
                                                   
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label class="col-md-12 control-label">Amount</label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="amount"
                                                            [ngClass]="{ 'is-invalid': (f.amount.touched || submitted) && f.amount.errors }"
                                                            name="amount" placeholder="Amount" class="form-control"
                                                            required="true" type="text">
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </fieldset>
                                    </td>
                                    <td colspan="1">
                                        <fieldset>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">Mobile <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="mobile_no"
                                                            [ngClass]="{ 'is-invalid': (f.mobile_no.touched || submitted) && f.mobile_no.errors }"
                                                            placeholder="Mobile" class="form-control" required="true"
                                                            type="text" readonly></div>
                                                   
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">District <span
                                                        class="text-danger">*</span>
                                                </label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="district"
                                                            [ngClass]="{ 'is-invalid': (f.district.touched || submitted) && f.district.errors }"
                                                            name="clarity" placeholder="District" class="form-control"
                                                            required="true" type="text" readonly></div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label class="col-md-12 control-label">Payment Link</label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="payment_link"
                                                            [ngClass]="{ 'is-invalid': (f.payment_link.touched || submitted) && f.payment_link.errors }"
                                                            name="payment_link" placeholder="Payment Link" class="form-control"
                                                            required="true" type="text">
                                                    </div>
                                                   
                                                </div>
                                            </div>
                               
                                        </fieldset>
                                    </td>
                                </tr>
                                <button type="submit" class="btn btn-primary ml-4 mb-2 prodedit_update">Update</button>
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<script src="/assets/js/bootstrap-table.js"></script>
<!-- End Page -->
<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }
</style>