import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { CategoryService, AlertService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { IAngularMyDpOptions, IMyDateModel, IMyDate } from 'angular-mydatepicker';
import { DatePipe, formatDate } from '@angular/common';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';

declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-video-scheduler',
  templateUrl: './video-scheduler.component.html',
  styleUrls: ['./video-scheduler.component.css']
})

export class VideoSchedulerComponent implements OnInit {
  bannerForm: FormGroup;
  loading = false;
  imageExist = '';
  fileToUpload: File = null;

  submitted = false;
  //table = false;
  cancelButton = false;
  users$: Observable<any[]>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  width_limit = `${environment.bannerWidth}`;
  height_limit = `${environment.bannerHeight}`;
  media_domain = `${environment.media_domain}`;
  maincatwidth_limit = `${environment.maincatWidth}`;
  maincatheight_limit = `${environment.maincatHeight}`;
  curPage = 1;
  perPage = 10;

  gold: any;
  silver: any;
  diamond: any;
  edit = false;
  dateVal: any;
  constructor(
    private datepipe: DatePipe,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private bannersService: CategoryService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  myDatePickerOptions: IAngularMyDpOptions = {
    dateRange: false,
    inline: false,
    dateFormat: 'dd-mmm-yyyy',
    openSelectorTopOfInput: false,
    disableSince: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() + 1
    }
  }

  myDatePickerOptions1: IAngularMyDpOptions = {
    dateRange: true,
    inline: false,
    dateFormat: 'dd-mmm-yyyy',
    openSelectorTopOfInput: false,
  }

  selected_date = null;
  selected_date1 = null;
  is_auth = false;
  // auth = localStorage.getItem(`${environment.appName}` + '_user');
  petty = [];
  start_date = '';
  end_date = '';


  ngOnInit() {


    // $('table').dataTable({searching: false})
    // $('table').dataTable({dom: 'lrt'});
    const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    this.bannerForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      _id: [{ value: null, disabled: true }],
      // name: ['', [Validators.required, Validators.maxLength(500)]],
      date: ['', [Validators.required]],
      // large_image: '',

      // status: ''
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };

    this.refreshOnce()

  }

  refreshOnce() {
    this.bannersService.getVideoSchedulerList().subscribe(data => {
      console.log(data);
      this.users$ = data.data.details;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }

  // handleFileInput(event: any, files: FileList) {
  //   var that = this;
  //   var reader = new FileReader();
  //   var file = files[0];
  //   var fileType = file["type"];
  //   var validImageTypes = ["image/gif", "image/jpeg", "image/png","image/jpg"];
  //   if ($.inArray(fileType, validImageTypes) < 0) {
  //      that.toastr.error('Please upload image only');
  //     //  $('#large_image').val('');
  //      that.fileToUpload = null;
  //      return false;
  //   }
  //   reader.onload = function (e) {
  //     var img = new Image;
  //     img.onload = function () {
  //       var imgWidth = parseInt(that.maincatwidth_limit)
  //       var imgHeight = parseInt(that.maincatheight_limit)
  //       if (img.width != imgWidth || img.height != imgHeight) {
  //         that.toastr.error('Please upload image of ' + imgWidth + '*' + imgHeight)
  //         // that.bannerForm.get('large_image').setValue('');

  //         return false;
  //       } else {
  //         that.fileToUpload = files.item(0);
  //       }
  //     };
  //     img.src = reader.result as string;
  //   };

  //   reader.readAsDataURL(files.item(0));

  // }
  // convenience getter for easy access to form fields


  get f() { return this.bannerForm.controls; }

  onSubmit(form, formWrap) {


    console.log(this.f.date.value);

    this.submitted = true;
    let id = form.getRawValue()._id;
    let index = form.getRawValue().index;

    // if (id == null || !this.imageExist) {
    //   this.bannerForm.get('large_image').setValidators([Validators.required]);
    //   this.bannerForm.get('large_image').updateValueAndValidity();
    // }
    // stop here if form is invalid
    if (this.bannerForm.invalid) {
      return;
    }


    if (id != null) {
      document.body.classList.add('jw-modal-open');
      this.loading = true;
      this.bannersService.updateVideoScheduler(id,this.start_date, this.end_date)
        .subscribe(
          data => {
            if (data.status.code == 0) {

              this.toastr.success('Successfully Updated');
              this.submitted = false;
              document.body.classList.remove('jw-modal-open');
              this.loading = false;
              this.cancelButton = false;

              location.reload();
              document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
              this.users$[index] = data.data.banner;



              this.bannerForm.setValue({
                index: null,
                _id: null,
                // name: '',
                // status: '',
                // large_image: ''
              })
              formWrap.reset();
              this.imageExist = '';

              this.fileToUpload = null;

              //this.router.navigate(['/banners']);
              location.href = "/main-category";

            } else {
              document.body.classList.remove('jw-modal-open');
              this.loading = false;
              this.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            this.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
            document.body.classList.remove('jw-modal-open');
            this.loading = false;
          });
    } else {
      document.body.classList.add('jw-modal-open');
      this.loading = true;
      this.bannersService.saveVideoScheduler(this.start_date, this.end_date)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              this.toastr.success('Successfully Added');
              this.submitted = false;
              document.body.classList.remove('jw-modal-open');
              this.loading = false;
              this.cancelButton = false;
              location.reload();
              // this.refreshOnce()
              //this.users$.unshift(data.data.banner)

              // this.bannerForm.setValue({
              //   index: null,
              //   _id: null,
              //   date:''
              // })
              // formWrap.reset();
              // this.fileToUpload = null;

              // location.href = "/main-category";

            } else {
              document.body.classList.remove('jw-modal-open');
              this.loading = false;
              this.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            this.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
            document.body.classList.remove('jw-modal-open');
            this.loading = false;
          });
    }
    //this.bannerForm.reset() // reset form to empty


  }

  imageEdit(event) {
    this.imageExist = '';
  }

  userEdit(event, user, i) {

    this.bannersService.getSingleVideoScheduler(user._id)
      .subscribe(
        data => {
          // console.log(data.data.details[0].from);
          // console.log(data.data.details[0].to);

          var ary1 = data.data.details[0].from.split('-');
          var ary2 = data.data.details[0].to.split('-');

          // console.log(ary1);
          // console.log(ary2);

          // console.log(this.getMonth(parseInt(ary1[1])));



          this.edit = true;
          // console.log(this.bannerForm);
          this.cancelButton = true;

          // this.gold = user.title;
          // this.silver = user.title;
          // this.diamond = user.title;
          var formt = parseInt(ary1[0]) + '-' + this.getMonth(parseInt(ary1[1])) + '-' + parseInt(ary1[2]) + ' - ' + parseInt(ary2[0]) + '-' + this.getMonth(parseInt(ary2[1])) + '-' + parseInt(ary2[2])

          console.log(parseInt(ary1[2]));
          console.log(this.getMonth(parseInt(ary1[1])));
          console.log(parseInt(ary1[0]));

          // this.table = event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
          document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
          this.bannerForm.setValue({
            index: i,
            _id: data.data.details[0]._id,
            date: {
              isRange: true,
              dateRange: {
                formatted: formt.toString(),
                beginDate: {
                  year: parseInt(ary1[2]),
                  month: parseInt(ary1[1]),
                  day: parseInt(ary1[0])
                },
                endDate: {
                  year: parseInt(ary2[2]),
                  month:parseInt(ary2[1]),
                  day: parseInt(ary2[0])
                },
              }
            }


            // date: user.date,
            // status: parseInt(user.active),
            // large_image: ''

          })






          // this.imageExist = user.image;

        },
        error => {
          this.toastr.error(error)
        });




  }


  getMonth(e) {
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[e - 1]
  }

  cancelSubmit(event) {
    this.edit = false;
    this.gold = '';
    this.silver = '';
    this.diamond = '';
    this.imageExist = '';
    this.cancelButton = false;
    document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
    this.bannerForm.setValue({
      index: null,
      _id: null,
      // name: '',
      // status: '',
      // large_image: ''
    })
    this.submitted = false;
  }
  userDelete(event, user, i) {
    var that = this;
    alertify.confirm('Are you sure want to delete this record', function () {
      that.bannersService.deleteVideoScheduler(user._id)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.toastr.success('Deleted Successfully')

              setTimeout(function () {
                location.reload();
              }, 500);

            } else {
              that.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            that.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
          });
    }
      , function () { //alertify.error('Delete action cancelled')
        that.toastr.error('Delete action cancelled')

      });

  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }

  onDateRangeChanged(e) {

    console.log(this.bannerForm.controls['date']);
    console.log(e);
    this.dateVal = e;
    this.selected_date1 = '';
    this.start_date = '';
    this.end_date = '';
    if (e.dateRange.formatted) {
      this.selected_date1 = e.dateRange.formatted;
      this.start_date = e.dateRange.formatted.split(' - ')[0];
      this.end_date = e.dateRange.formatted.split(' - ')[1];


      console.log("Start date = " + this.start_date);
      console.log("End date = " + this.end_date);

      this.start_date = this.datepipe.transform(this.start_date, 'dd-MM-yyyy')
      this.end_date = this.datepipe.transform(this.end_date, 'dd-MM-yyyy')

      // this.employeeService.getWorkAllotmentDetail(this.start_date, this.end_date).subscribe(data => {
      //   this.workAllotment$ = data && data.data && data.data.workallotment && data.data.workallotment.docs ? data.data.workallotment.docs : [];
      //   console.log(this.workAllotment$);

      //   this.has_Next_Page = data.data.workallotment.hasNextPage;
      //   this.has_Prev_Page = data.data.workallotment.hasPrevPage;
      // }, error => {

      // })

    }

  }

  refresh() {
    this.selected_date = '';
  }

  onDateChanged(e) {
    this.selected_date = e.singleDate.formatted;
  }
}
