<site-header></site-header>
<link rel="stylesheet" href="/assets/css/bootstrap-table.css">
<style type="text/css">
    img {
        height: auto;
        max-width: 100%;
    }

    .cell {
        display: table-cell;
    }

    .cell-fluid {
        width: 100%;
    }
</style>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Languages</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Languages</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row">
            <div class="col-xl-12">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <header class="panel-heading">
                        <h3 class="panel-title">
                            Languages
                        </h3>
                    </header>
                    <div class="panel-body">
                        <form [formGroup]="bannerForm" #myForm="ngForm" (ngSubmit)="onSubmit(bannerForm, myForm)"
                            class="">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="mb-15">
                                        <input autocomplete="off" type="text" class="form-control"
                                            formControlName="name"
                                            [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors }"
                                            placeholder="Title">
                                        <div *ngIf="(f.name.touched || submitted) && f.name.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.name.errors?.required">Title is required</div>
                                        </div>

                                        <div *ngIf="(f.name.touched || submitted) && f.name.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.name.errors?.maxlength">Maximum 100 characters are allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-md-3">
                                    <div class="mb-15">
                                        <button [disabled]="loading" class="btn btn-primary " type="submit">
                                            <i class="icon md-plus" aria-hidden="true"></i>
                                            <div *ngIf="cancelButton;then update else add">here is ignored</div>
                                            <ng-template #add>Add</ng-template>
                                            <ng-template #update>Update</ng-template>
                                        </button>
                                        <button style="margin-left:5px" (click)="cancelSubmit($event)"
                                            class="btn btn-danger" *ngIf="cancelButton" type="button">
                                            <i class="icon md-close" aria-hidden="true"></i> Cancel
                                        </button>
                                        <img *ngIf="loading"
                                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="tb-wrapper">
                            <table cellspacing="0" id="table" class="table table-hover dataTable table-striped w-full"
                                datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                <thead>
                                    <tr>
                                        <th width="8%">Sl No.</th>
                                        <th width="10%">Title</th>
                                        <th width="10%">Actions</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="languages$">
                                    <tr *ngFor="let language of languages$; let i = index">
                                        <td>{{ perPage * (curPage - 1) + i + 1 }}</td>

                                        <td>
                                          <div class="cell">{{ language.title}}
                                          </div>
                                      </td>


                                        <td>
                                            <a (click)="languageEdit($event, language, i)" href="javascript:void(0);"
                                                class="btn btn-sm btn-icon btn-pure btn-default on-default edit-row"
                                                data-toggle="tooltip"><i class="icon md-edit text-success"
                                                    aria-hidden="true"></i>
                                            </a>
                                            <a (click)="languageDelete($event, language, i)" href="javascript:void(0);"
                                                class="btn btn-sm btn-icon btn-pure btn-default on-default remove-row"
                                                data-toggle="tooltip"><i class="icon md-delete text-danger"
                                                    aria-hidden="true"></i>
                                            </a></td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="!languages$">
                                  <th></th>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- End Panel Kitchen Sink -->
            </div>
        </div>
    </div>
</div>
<script src="/assets/js/bootstrap-table.js"></script>
<!-- End Page -->
<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }
</style>
