import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { BannersService, AlertService, ProductService, CategoryService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-promocode',
  templateUrl: './promocode.component.html',
  styleUrls: ['./promocode.component.css']
})
export class PromocodeComponent implements OnInit {

  promoForm: FormGroup;
  submitted = false;
  active = true;
  cancelButton = false;
  status = "Active";
  coupon_type = '1';
  category = 'gold';
  loading = false;

  main$: any[];

  checked_val: any;
  length: any;
  promo$: Observable<any[]>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  constructor(private pro: ProductService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private cat:CategoryService
  ) { }


  subCategory = {

    displayKey: 'title', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select main category', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No main category found', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search main category',
    padding: '15px', // label thats displayed in search input,


    selectAllBtnText: 'Select',
    searchOnKey: 'title' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys

    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  ngOnInit() {
    this.pro.getPromo().subscribe(data => {
      console.log(data);
      this.promo$ = data.data;
      this.length = data.data.length;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });


    this.cat.getMainCat().subscribe(data => {
      console.log(data);
    this.main$ = data.data.main;

    this.main$.push('All');

    }, error => {
      console.error(error);
    });

    this.promoForm = this.formBuilder.group({
      id: [''],
      coupon_code: ['', [Validators.required, Validators.maxLength(5)]],
    mainCat: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.pattern(/^-?(0|[+0-9]\d*)?$/), Validators.maxLength(15), Validators.minLength(10)]],
      amount: ['', [Validators.required, Validators.maxLength(7), Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/)]],
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Coupon code"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };
  }



  selectmainCategory(e){
    console.log(e);
  }


  changeActive(e) {
    console.log(e);
    this.status = e.target.value;
  }

  changeCoupon(e) {
    this.coupon_type = e;
    if (e == '0') {
      this.promoForm.controls.amount.setValidators([Validators.required, Validators.max(99), Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/)]);
      this.promoForm.controls.amount.updateValueAndValidity();
    } else {
      this.promoForm.controls.amount.setValidators([Validators.required, Validators.maxLength(5), Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/)]);
      this.promoForm.controls.amount.updateValueAndValidity();
    }

  }

  get f() { return this.promoForm.controls; }
  promoSubmit() {
    console.log(this.f.mainCat.value);
    console.log(this.promoForm);
    this.submitted = true;
    if (this.promoForm.invalid) {
      return false;
    }

    var that = this;

    if (that.f.id.value) {
      alertify.confirm('Are you sure want to update this record', function () {
        that.loading = true;
        that.pro.updatePromo(that.f.id.value, that.f.coupon_code.value, that.f.mobile.value, that.f.amount.value, that.status, that.category, that.coupon_type, that.f.mainCat.value).subscribe(data => {
          console.log(data);
          if (data.status.code == 0) {
            that.loading = false;
            that.toastr.success('Successfully updated');
            that.submitted = false;
            that.coupon_type = '1';
            that.status = 'Active';
            $('.cls').removeClass('disable');
            $('#status').find('option[value="Active"').prop("selected", true);
            location.reload();
          } else {
            that.toastr.error(data.status.message);
          }
        }, error => {
          console.error(error);
        })
      }, function () {
        that.toastr.error("Action cancelled");
      });
    } else {
      alertify.confirm('Are you sure want to submit this record', function () {
        that.loading = true;
        that.pro.addPromo(that.f.coupon_code.value, that.f.mobile.value, that.f.amount.value, that.status, that.category, that.coupon_type,that.f.mainCat.value).subscribe(data => {
          console.log(data);
          if (data.status.code == 0) {
            that.loading = false;
            that.toastr.success('Successfully added');
            that.submitted = false;
            that.coupon_type = '1';
            that.status = 'Active';
            $('.cls').removeClass('disable');
            $('#status').find('option[value="Active"').prop("selected", true);
            that.cancelButton = false;
            location.reload();
          } else {
            that.toastr.error(data.status.message);
          }
        }, error => {
          console.error(error);
        })
      }, function () {
        that.toastr.error("Action cancelled");
      });
    }
  }

  deletePromo(id) {
    var that = this;
    alertify.confirm('Are you sure want to delete this record', function () {
      that.loading = true;
      that.pro.deletePromocode(id).subscribe(data => {
        console.log(data);
        that.loading = false;
        that.toastr.success('Successfully deleted');
        that.pro.getPromo().subscribe(data => {
          console.log(data);
          that.promo$ = data.data;
          that.length = data.data.length;
          this.dtTrigger.next();
        }, error => {
          console.error(error);
        });
      }, error => {
        console.error(error);
      });

    }, function () {
      that.toastr.error('Action cancelled');
    });
  }

  /* editPromo(branch) {
      $('.cls').addClass('disable');
      this.cancelButton = true;
  
      this.promoForm.patchValue({
        id: branch._id,
        coupon_code: branch.coupon_code,
         mobile: branch.mobile,
        amount: branch.amount_or_percent
       
      });*/

  editPromo(obj) {
    $('.cls').addClass('disable');
    this.cancelButton = true;
    this.active = obj.status == 'Active' ? true : false;
    this.status = obj.status;
    $('#status').find('option[value=' + obj.status + '').prop("selected", true);

    //$('[name="coupon_type"]').find('[value=' + obj.coupon_type + ']').prop('checked', true);
    this.coupon_type = obj.coupon_type;
    this.status = obj.status;
    this.promoForm.patchValue({
      id: obj._id,
      coupon_code: obj.coupon_code,
      mobile: obj.mobile,
      amount: obj.amount_or_percent,
      mainCat:obj.cat
    });
  }



  cancelClick() {
    $('.cls').removeClass('disable');
    $('#status').find('option[value="Active"').prop("selected", true);
    this.coupon_type = '1';
    this.status = 'Active';
    this.cancelButton = false;
    location.reload();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }

}
