import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { BannersService, AlertService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.css']
})
export class BranchesComponent implements OnInit, OnDestroy, AfterViewInit {
  brachForm: FormGroup;
  meta$: Observable<any[]>;
  loading = false;
  submitted = false;
  cancelButton = false;
  fileToUpload: File = null;
  fileToUpload2: File = null;
  fileToUpload3: File = null;
  fileToUpload4: File = null;
  media_domain = `${environment.media_domain}`;
  branchwidth_limit = `${environment.branchWidth}`;
  branchheight_limit = `${environment.branchHeight}`;

  imageExist = '';
  imageExist2 = '';
  imageExist3 = '';
  imageExist4 = '';


  perPage = 10;

  constructor(private service: BannersService,
    private http: HttpClient, private alert: AlertService, private formBuilder: FormBuilder, private toastr: ToastrService) { }

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnInit() {

    this.brachForm = this.formBuilder.group({
      id: [''],
      title: ['', [Validators.required]],
      latitude: ['', [Validators.pattern(/^\d+(\.\d{1,9})?$/)]],
      longitude: ['', [Validators.pattern(/^\d+(\.\d{1,9})?$/)]],
      address: ['', [Validators.required]],
      image: [''],
      // image2: [''],
      // image3: [''],
      // image4: [''],
      email: ['', [Validators.required, Validators.email]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      district: ['', [Validators.required]],
      fromTime: [''],
      toTime: [''],
      contact1: ['', [Validators.required, Validators.pattern(/^-?(0|[+0-9]\d*)?$/), Validators.maxLength(15), Validators.minLength(10)]],
      contact2: ['', [Validators.pattern(/^-?(0|[+0-9]\d*)?$/), Validators.maxLength(15), Validators.minLength(10)]]
    });





    var that = this;
    that.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: that.perPage,

      serverSide: true,
      searching: true,
      ordering: true,
      language: {
        searchPlaceholder: "Search Meta title"
      },
      order: [],
      columnDefs: [{
        "targets": [],
        "orderable": false
      }],
      lengthChange: false,


      ajax: (dataTablesParameters: any, callback) => {
        var that = this;
       
        that.http.post<any>(
          `${environment.apiUrl}${environment.apiPrefix}/branches/getBranch`,
          dataTablesParameters, {}
        ).subscribe(resp => {
          that.meta$ = resp.data.branches;
          console.log(that.meta$)

          //$('.custom-filters').appendTo('.dataTables_filter').show();


          callback({
            recordsTotal: resp.data.recordsTotal,
            recordsFiltered: resp.data.recordsFiltered,
            data: []
          });
        });

      }
    };

    // this.service.getBranch().subscribe(data => {
    //   console.log(data);
    //   this.meta$ = data.data.branch;
    //   this.dtTrigger.next();
    // }, error => {
    //   console.error(error);
    // });



    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   processing: true,
    //   pageLength: 10,
    //   language: {
    //     searchPlaceholder: "Title"
    //   },
    //   columnDefs: [{
    //     searchable: false,
    //     orderable: false,
    //     targets: 0
    //   }]

    // };




  }

  //, Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)

  get f() { return this.brachForm.controls }
  brachSubmit() {
    console.log(this.brachForm);
    this.submitted = true;
    if (this.brachForm.invalid && this.cancelButton == false) {
      return false;
    }

    var that = this;

    if (this.f.id.value) {
      alertify.confirm('Are you sure want to update?', function () {
        that.loading = true;
        that.service.updateBranch(that.f.id.value, that.fileToUpload, that.fileToUpload2, that.fileToUpload3, that.fileToUpload4,
          that.f.title.value, that.f.contact1.value, that.f.contact2.value,
          that.f.address.value, that.f.email.value, that.f.latitude.value,
          that.f.longitude.value,
that.f.city.value,
that.f.state.value,
that.f.district.value,
          that.f.fromTime.value,
          that.f.toTime.value

        ).subscribe(data => {
          console.log(data);
          if (data.status.code == 0) {
            that.toastr.success('successfully added');
            location.reload();
            that.meta$ = data.data.branch;
            that.service.getBranch().subscribe(data => {
              console.log(data);
              location.reload();
              that.loading = false;
              that.submitted = false;
              $('.pro').removeClass('disable');

              that.brachForm.patchValue({
                id: '',
                title: '',
                contact1: '',
                contact2: '',
                latitude: '',
                longitude: '',
                address: '',
                email: '',
                image: ''

              });
            }, error => {
              console.error(error);
            });
          }
          else {
            that.toastr.error(data.status.message);
            that.loading = false;

          }
        }, error => {
          console.error(error);
        });
      }
        , function () {
          that.toastr.error('Update action cancelled')

        });
    } else {
      if (that.fileToUpload == null && that.fileToUpload2 == null && that.fileToUpload3 == null && that.fileToUpload4 == null) {

        that.toastr.error('Atleast one image is required');
        return false;
      }
      alertify.confirm('Are you sure want to submit?', function () {
        that.loading = true;
        that.service.addBranches(that.fileToUpload, that.fileToUpload2, that.fileToUpload3, that.fileToUpload4,
          that.f.title.value, that.f.contact1.value, that.f.contact2.value, that.f.address.value,
          that.f.email.value, that.f.latitude.value, that.f.longitude.value,
          that.f.city.value,
          that.f.state.value,
          that.f.district.value,
          that.f.fromTime.value,
          that.f.toTime.value

        ).subscribe(data => {
          console.log(data);
          if (data.status.code == 0) {
            that.toastr.success('successfully added');
            location.reload();
            that.meta$ = data.data.branch;
            that.service.getBranch().subscribe(data => {
              console.log(data);
              that.loading = false;
              $('.pro').removeClass('disable');



            }, error => {
              console.error(error);
            });
          }
          else {
            that.toastr.error(data.status.message);
            that.loading = false;

          }
        }, error => {
          console.error(error);
        });
      }
        , function () {
          that.toastr.error('Submit action cancelled')

        });
    }
  }



  handleFileInput(event: any, files: FileList) {
    var that = this;
    var reader = new FileReader();

    reader.onload = function (e) {
      var img = new Image;
      img.onload = function () {
        var imgWidth = parseInt(that.branchwidth_limit)
        var imgHeight = parseInt(that.branchheight_limit)
        if (img.width != imgWidth || img.height != imgHeight) {
          that.toastr.error('Please upload image of ' + imgWidth + '*' + imgHeight)
          that.brachForm.get('image').setValue('');

          return false;
        } else {
          that.fileToUpload = files.item(0);

        }
      };
      img.src = reader.result as string;
    };

    reader.readAsDataURL(files.item(0));

  }
  handleFileInput2(event: any, files: FileList) {
    var that = this;
    var reader = new FileReader();

    reader.onload = function (e) {
      var img = new Image;
      img.onload = function () {
        var imgWidth = parseInt(that.branchwidth_limit)
        var imgHeight = parseInt(that.branchheight_limit)
        if (img.width != imgWidth || img.height != imgHeight) {
          that.toastr.error('Please upload image of ' + imgWidth + '*' + imgHeight)
          that.brachForm.get('image').setValue('');

          return false;
        } else {
          that.fileToUpload2 = files.item(0);

        }
      };
      img.src = reader.result as string;
    };

    reader.readAsDataURL(files.item(0));

  }
  handleFileInput3(event: any, files: FileList) {
    var that = this;
    var reader = new FileReader();

    reader.onload = function (e) {
      var img = new Image;
      img.onload = function () {
        var imgWidth = parseInt(that.branchwidth_limit)
        var imgHeight = parseInt(that.branchheight_limit)
        if (img.width != imgWidth || img.height != imgHeight) {
          that.toastr.error('Please upload image of ' + imgWidth + '*' + imgHeight)
          that.brachForm.get('image').setValue('');

          return false;
        } else {
          that.fileToUpload3 = files.item(0);

        }
      };
      img.src = reader.result as string;
    };

    reader.readAsDataURL(files.item(0));

  }
  handleFileInput4(event: any, files: FileList) {
    var that = this;
    var reader = new FileReader();

    reader.onload = function (e) {
      var img = new Image;
      img.onload = function () {
        var imgWidth = parseInt(that.branchwidth_limit)
        var imgHeight = parseInt(that.branchheight_limit)
        if (img.width != imgWidth || img.height != imgHeight) {
          that.toastr.error('Please upload image of ' + imgWidth + '*' + imgHeight)
          that.brachForm.get('image').setValue('');

          return false;
        } else {
          that.fileToUpload4 = files.item(0);

        }
      };
      img.src = reader.result as string;
    };

    reader.readAsDataURL(files.item(0));

  }


  deleteMeta(branch) {
    var that = this;
    alertify.confirm('Are you sure want to delete?', function () {
      that.loading = true;
      that.service.branchDelete(branch._id).subscribe(data => {
        console.log(data);
        location.reload();
        // that.service.getBranch().subscribe(data => {
        //   console.log(data);
        //   that.loading = false;
        //   that.toastr.success('successfully added');
        //   that.meta$ = data.data.branch;
        // }, error => {
        //   console.error(error);
        // });
      }, error => {
        console.error(error);
      })
    }
      , function () {
        that.toastr.error('Delete action cancelled')

      });
  }

  cancel() {
    $('.pro').removeClass('disable');
    this.cancelButton = false;
    location.reload();
  }

  imageEdit(e) {
    this.imageExist = '';
  }

  imageEdit2(e) {
    this.imageExist2 = '';
  }
  imageEdit3(e) {
    this.imageExist3 = '';
  }
  imageEdit4(e) {
    this.imageExist4 = '';
  }

  editMeta(branch) {
    console.log(branch);
    $('.pro').addClass('disable');
    this.cancelButton = true;
    this.imageExist = branch.image ? branch.image : '';
    this.imageExist2 = branch.image2 ? branch.image2 : '';
    this.imageExist3 = branch.image3 ? branch.image3 : '';
    this.imageExist4 = branch.image4 ? branch.image4 : '';
    this.brachForm.patchValue({
      id: branch._id,
      title: branch.branch_name,
      contact1: branch.contact1,
      contact2: branch.contact2,
      address: branch.address ? branch.address : 'NA',
      email: branch.email,
      latitude: branch.lat,
      longitude: branch.long,
      city: branch.city,
      state: branch.state,
      district: branch.district,
      fromTime: branch.from,
      toTime: branch.to,
      image: ''
    });
  }

  ngOnDestroy(): void {
    //this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {

    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }



}
