<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Most Clicked Products</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Most Clicked Products</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row">
            <div class="col-xl-12">

                <div class="panel">
                    <div class="abharan_main">
                        <br>
<div class="row">
                        <div class="col-md-2">
                            <select ([ngModel])="selectedYear" class="form-control"
                                (change)="searchFilter($event.target.value,'year')">
                                <option disabled>Select Year</option>
                                <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                            </select>
                        </div>
                        <!-- <div class="col-md-2">
                            <select ([ngModel])="selectedYear" class="form-control"
                                (change)="searchFilter($event.target.value,'month')">
                                <option disabled>Select Month</option>
                                <option *ngFor="let month of monthrray" [value]="month.name">{{ month.name }}</option>
                            </select>
                        </div> -->
                    </div>
                        <header class="panel-heading">
                            <h3 class="panel-title">
                                Main Category Clicked Details
                            </h3>
                        </header>


                        <div class="panel-body">
                            <div class="col-md-3">
                            </div>
                            <div class="tb-wrapper">
                                <table cellspacing="0" id="table"
                                    class="table table-hover dataTable table-striped w-full">
                                    <!-- dtoption -->

                                    <thead>
                                        <tr>
                                            <th>Sl.No.</th>
                                            <th>MAIN CATEGORY</th>
                                            <th>MONTH</th>
                                            <th>CLICKED COUNT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let pro of main$;let i=index">
                                            <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                            <td>
                                                {{ pro && pro.main && pro.main.title ? pro && pro.main && pro.main.title
                                                : '' }}

                                            </td>
                                            <td>
                                                {{ pro && pro.Month ? pro.Month : '-' }}</td>
                                            <td>
                                                {{ pro && pro.visits ? pro.visits : '' }}

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row paginate">
                                    <div class="col-sm-8">
                                    <div class="shloka_result" *ngIf="main$ != 0">
                                                             
      Total :  {{total}}  &nbsp; 
       Showing &nbsp; {{from}} - {{to}}                     
                                   </div>
                                      </div>
                                   <div class="col-sm-4" *ngIf="main$ && main$.length">
                                    <div class="temple_pagination pg_cls">
                                        <a class="backward" [ngClass]="{'disable':curPage==1}" (click)="pageChange(curPage-1)"><i
                                                class="fas fa-step-backward"></i></a> Page
                                        <span>{{curPage}}</span>of {{last_page}}<a [ngClass]="{'disable':curPage==last_page}"
                                            class="forward" (click)="pageChange(curPage+1)"><i class="fas fa-step-forward"></i></a>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-xl-12">

                <div class="panel">
                    <div class="abharan_main">
                        <header class="panel-heading">
                            <h3 class="panel-title">
                                Sub Category Clicked Details
                            </h3>
                        </header>

                        <div class="panel-body">
                            <!-- <div class="col-md-3">
                                <span class="bold"> {{stock_filter == '-1' ? 'Out of Stock Products count' :
                                    stock_filter == '1' ? 'In Stock Products count' : 'All Most Clicked Product count'}}
                                    {{total}}</span>
                            </div> -->
                            <div class="tb-wrapper">
                                <table cellspacing="0" id="table1"
                                    class="table table-hover dataTable table-striped w-full">

                                    <thead>
                                        <tr>
                                            <th>Sl.No.</th>
                                            <th>MAIN CATEGORY</th>
                                            <th>SUB CATEGORY</th>
                                            <th>MONTH</th>
                                            <th>CLICKED COUNT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let pro of sub$;let i=index">
                                            <td>{{ perPage * (curPage1 - 1) + i + 1 }}</td>
                                            <td>
                                                {{ pro && pro.main && pro.main.title ? pro.main.title : 'NA' }}

                                            </td>
                                            <td>
                                                {{ pro && pro.sub && pro.sub.title ? pro.sub.title : 'NA'}}
                                            </td>
                                            <td>
                                                {{ pro && pro.Month ? pro && pro.Month  : '-'}}
                                            </td>
                                            <td>
                                                {{ pro && pro.visits ? pro.visits : 'NA' }}
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row paginate">
                                    <div class="col-sm-8">
                                    <div class="shloka_result" *ngIf="sub$ != 0">
                                                             
      Total :  {{total1}}  &nbsp; 
       Showing &nbsp; {{from1}} - {{to1}}                     
                                   </div>
                                      </div>
                                   <div class="col-sm-4" *ngIf="sub$ && sub$.length">
                                    <div class="temple_pagination pg_cls">
                                        <a class="backward" [ngClass]="{'disable':curPage1==1}" (click)="pageChange1(curPage1-1)"><i
                                                class="fas fa-step-backward"></i></a> Page
                                        <span>{{curPage1}}</span>of {{last_page1}}<a [ngClass]="{'disable':curPage1==last_page1}"
                                            class="forward" (click)="pageChange1(curPage1+1)"><i class="fas fa-step-forward"></i></a>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <!-- sub category listing end -->



                    </div>



                </div>
              
            </div>


          

            <div class="col-xl-12">
             
                <div class="panel">
                    <div class="abharan_main">
                        <header class="panel-heading">
                            <h3 class="panel-title">
                                Products Clicked Details
                            </h3>
                        </header>
                        <div class="panel-body">
                            <!-- <div class="col-md-3">
                                <span class="bold"> {{stock_filter == '-1' ? 'Out of Stock Products count' :
                                    stock_filter == '1' ? 'In Stock Products count' : 'All Most Clicked Product count'}}
                                    {{total1}}</span>
                            </div> -->
                            <div class="tb-wrapper">
                                <div class="row">

                                </div>

                                <table cellspacing="0" id="table2"
                                    class="table table-hover dataTable table-striped w-full">

                                    <thead>
                                        <tr>
                                            <th>Sl.No.</th>
                                            <th>MAIN CATEGORY</th>
                                            <th>SUB CATEGORY</th>
                                            <th>PRODUCT NAME</th>
                                            <th>PRODUCT IMAGE</th>
                                            <th>MONTH</th>
                                            <th>CLICKED COUNT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let pro of prod$;let i=index">
                                            <td>{{ perPage * (curPage2 - 1) + i + 1 }}</td>
                                            <td>{{ pro && pro.product && pro.product.main_cat &&
                                                pro.product.main_cat.title ? pro.product.main_cat.title : 'NA' }}</td>
                                            <td>{{ pro && pro.product && pro.product.sub_cat &&
                                                pro.product.sub_cat.title ? pro.product.sub_cat.title : 'NA' }}</td>
                                            <td>{{ pro && pro.product && pro.product.name ? pro.product.name : 'NA' }}
                                            </td>
                                            <td *ngIf="pro.productimage && pro.productimage.image">
                                               
                                                <img src="{{media_domain}}{{pro.productimage.image}}" class="image">
                                               
                                            </td>
                                            <td *ngIf="pro.productimage && !pro.productimage.image">
                                                <img class="image" src="/assets/default.png" />
                                            </td>
                                            <td>
                                                {{ pro && pro.Month ? pro && pro.Month  : '-'}}
                                            </td>
                                            <td>{{ pro && pro.visits ? pro.visits : 'NA' }}</td>

                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row paginate">
                                    <div class="col-sm-8">
                                    <div class="shloka_result" *ngIf="prod$ != 0">
                                                             
      Total :  {{total2}}  &nbsp; 
       Showing &nbsp; {{from2}} - {{to2}}                     
                                   </div>
                                      </div>
                                   <div class="col-sm-4" *ngIf="prod$ && prod$.length">
                                    <div class="temple_pagination pg_cls">
                                        <a class="backward" [ngClass]="{'disable':curPage2==1}" (click)="pageChange2(curPage2-1)"><i
                                                class="fas fa-step-backward"></i></a> Page
                                        <span>{{curPage2}}</span>of {{last_page2}}<a [ngClass]="{'disable':curPage2==last_page2}"
                                            class="forward" (click)="pageChange2(curPage2+1)"><i class="fas fa-step-forward"></i></a>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>





                    </div>



                </div>
             
            </div>
           



        </div>
    </div>
</div>
<script src="/assets/js/bootstrap-table.js"></script>

<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }
</style>