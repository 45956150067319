<site-header></site-header>
<!-- <link rel="stylesheet" href="/assets/css/bootstrap-table.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"> -->
<style type="text/css">
    img {
        height: auto;
        max-width: 100%;
    }

    .cell {
        display: table-cell;
    }

    .cell-fluid {
        width: 100%;
    }

</style>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Meta</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Meta</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row meta_main">
            <div class="col-md-4 meta_left">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <!-- <header class="panel-heading">
                        <h3 class="panel-title">
                            Meta
                        </h3>
                    </header> -->
                    <div class="panel-body">
                        <form [formGroup]="metaForm" (ngSubmit)="metaSubmit()">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="url_en_point">Url end point</label>
                                        <input type="text"
                                            [ngClass]="{ 'is-invalid': (f.url.touched || submitted) && f.url.errors }"
                                            formControlName="url" id="url_en_point" placeholder="Enter url end point"
                                            class="form-control">
                                        <div *ngIf="f.url.errors?.required" class="invalid-feedback">
                                            <div *ngIf="f.url.errors?.required">Url is required</div>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="desc">Meta title </label>
                                        <input type="text"
                                            [ngClass]="{ 'is-invalid': (f.title.touched || submitted) && f.title.errors }"
                                            id="desc" formControlName="title" placeholder="Enter description"
                                            class="form-control">
                                        <div *ngIf="f.title.errors?.required" class="invalid-feedback">
                                            <div *ngIf="f.title.errors?.required">Title is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="desc">Description </label>
                                        <textarea type="text"
                                            [ngClass]="{ 'is-invalid': (f.desc.touched || submitted) && f.desc.errors }"
                                            id="desc" formControlName="desc" placeholder="Enter description"
                                            class="form-control"></textarea>
                                        <div *ngIf="f.desc.errors?.required" class="invalid-feedback">
                                            <div *ngIf="f.desc.errors?.required">Description is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="meta_tag">Meta keyword </label>
                                        <input type="text"
                                            [ngClass]="{ 'is-invalid': (f.keyword.touched || submitted) && f.keyword.errors }"
                                            id="meta_tag" formControlName="keyword" placeholder="Enter meta keyword"
                                            class="form-control">
                                        <div *ngIf="f.keyword.errors?.required" class="invalid-feedback">
                                            <div *ngIf="f.keyword.errors?.required">Keyword is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <button class="btn btn-primary mt-3 metaleft_btn">
                                            <div *ngIf="cancelButton;then update else add"></div>
                                            <ng-template #add>Submit</ng-template>
                                            <ng-template #update>Update</ng-template>
                                        </button>
                                        <button *ngIf="cancelButton" class="btn btn-danger mt-3 float-right"
                                            (click)="cancel()">Cancel</button>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-8 pro meta_right">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <!-- <header class="panel-heading">
                        <h3 class="panel-title">
                            Meta
                        </h3>
                    </header> -->
                    <div class="panel-body">
                        <div class="tb-wrapper">
                            <div class="table-responsive">
                                <table cellspacing="0" id="table" class="table table-hover table-striped table-bordered meta_table w-full"
                                    datatable [dtOptions]="dtOptions">
                                    <tr>
                                        <th>Url end point</th>
                                        <th>Meta title</th>
                                        <th>Meta Desc.</th>
                                        <th>Meta keyword</th>
                                        <th>Action</th>
                                    </tr>
                                    <tr *ngFor="let met of meta$; let n=index">
                                        <td>{{met.url}}</td>
                                        <td>{{met.meta_title}}</td>
                                        <td>{{met.desc}}</td>
                                        <td>{{met.meta_keyword}}</td>
                                        <td><i class="fa fa-edit mr-2" (click)="editMeta(met)" title="Edit"></i><i
                                                (click)="deleteMeta(met._id)" title="Delete"
                                                class="fas fa-trash-alt"></i>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
