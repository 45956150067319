import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, } from '@angular/core';
import { ProductService, AlertService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['./product-images.component.css']
})
export class ProductImagesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef<HTMLInputElement>;
  imageForm: FormGroup;
  imageList$: any;
  loading = false;
  submitted = false;
  enquiry$: Observable<any[]>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  length: any;
  new_promofile: File = null;
  selectedArray = [];
  files_array = [];
  images_array = [];
  video_array = [];
  curPage = 1;
  perPage = 10;
  

  tutorials: any;
  currentTutorial = null;
  currentIndex = -1;
  title = '';
  page = 1;
  count = 0;
  pageSize = 10;
  pagingCounter=0;

  viewall$ : any;
  media_domain = `${environment.media_domain}`;

  branchwidth_limit = `${environment.prodImgWidth}`;
  branchheight_limit = `${environment.prodImgHeight}`;
  constructor(private pro: ProductService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder, private http: HttpClient,) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "SKU"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };

    this.refreshOnce()
    this.imageForm = this.formBuilder.group({
      image: ['', [Validators.required]],
      video: ['']
    });
  }


  handlePageseachChange(event) {
    this.title = event.target.value;
    console.log(this.title);
    this.refreshOnce();
  }

  getRequestParams(searchTitle, page, pageSize) {
    // tslint:disable-next-line:prefer-const
    let params = {};

    params[`search`]='';
    if (searchTitle) {
      params[`search`] = searchTitle;
    }
    if (page)
 {
      params[`page`] = page;
    }
    if (pageSize) {
      params[`size`] = pageSize;
    }
    return params;
  }

 
  refreshOnce() {
    console.log(this.title);
    const params = this.getRequestParams(this.title, this.page,this.pageSize);
    this.pro.getProImage1(params).subscribe(data => {
      this.imageList$ = data.data.images.data;
     // this.curPage = data.data.images.current_page;

     this.count = data.data.images.total;
     this.pagingCounter=data.data.images.per_page;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }


 
  handlePageChange(event) {
    this.page = event;
    this.refreshOnce();
  }



  videoplay(e,imag){
    e.stopPropagation();
    var url = this.media_domain + imag.video;
    window.open(url,'_blank');
  }

  changeFile(e) {
    console.error(e.target.files[0]);
    this.new_promofile = e.target.files[0];
  }

  uploadFile(e, type) {
    e.stopPropagation();
    $('#files').val();
    $('#image').val();
    if (type == 1) {
      // $('#files').trigger('hide');
      // $('#image').trigger('hide');
      // $('#video').trigger('click');
      this.fileInput.nativeElement.accept = '';
      this.fileInput.nativeElement.accept = 'video/*';
      this.fileInput && this.fileInput.nativeElement.click();
    }
  }

  selectFile(e, type) {
  
    e.stopPropagation();
    this.files_array = [];

    if (e.target.files[0].size > 2000000) {

      $('.undo_btn').css('display', 'none');
      $('[type="file"]').val('');
      var that = this;
      that.toastr.error('Maximum 2MB is allowed');
      return;
    } else if (e.target.files[0].size < 2000000) {

      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (_event) => {
        this.files_array.push(e.target.files[0]);
        this.selectedArray.push({ 'type': { 'type': 'video', 'video': reader.result } });
        $('[type="file"]').val('');
        $('.img_select').css('display', 'block')
      }
    } else {
      var that = this;
      if ((e.target.files[0].type !== 'video/mp4') || (e.target.files[0].type !== 'video/mov') || (e.target.files[0].type !== 'video/x-matroska') || (e.target.files[0].type !== 'video/quicktime') || (e.target.files[0].type !== 'video/webm')) {
        that.toastr.error('Only video is allowed');
        return false;
      }
    }

  }



  get f() { return this.imageForm.controls }
  imageUpload() {
    this.submitted = true;
    if (this.imageForm.invalid) {
      return false;
    }

    if (this.new_promofile == null) {
      this.toastr.error("File is required");
      return false;
    }

    var that = this;
    alertify.confirm('Are you sure want to upload image?', function () {
      console.log(that.new_promofile);
      that.loading = true;
      that.pro.uploadProductVideo(that.video_array).subscribe(data=>{
     
      })
      that.pro.uploadProductImage(that.images_array).subscribe(data => {
        if (data.status.code == 0) {
       
          that.loading = false;
          that.toastr.success(data.status.message);
      location.reload();

        } else {
          that.loading = false;
          that.toastr.error(data.status.message);
        }
      }, error => {
        console.error(error);
      });


    }, function () {
      that.toastr.error("Action cancelled");
    })
  }

  playVideo(event) {
    event.toElement.play();
 }

  userDelete(event, user) {
    event.stopPropagation();
    var that = this;
    alertify.confirm('Are you sure want to delete this record', function () {
      that.pro.deleteImage(user._id)
        .subscribe(
          data => {
          
            if (data.status.code == 0) {
              that.toastr.success('Deleted Successfully')
              // alertify.success('Deleted Successfully')
              setTimeout(function () {
                location.reload();
              }, 1000);
              //that.users$.splice(i, 1);
            } else {
              that.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            that.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
          });
    }
      , function () { //alertify.error('Delete action cancelled')
        that.toastr.error('Delete action cancelled')

      });

  }

  handleFileInput(event: any, files: FileList, type) {
    console.log(event.target.files);
    var that = this;
    var file = files[0];
    var fileType = file["type"];
    if (type == 1) {
      var validImageTypes = ["image/gif", "image/jpeg", "image/png", "image/jpg"];
      if ($.inArray(fileType, validImageTypes) < 0) {
        that.toastr.error('Please upload image only');
        that.imageForm.get('image').setValue('');
        that.new_promofile = null;
        return false;
      }
      this.images_array = [];
      var reader = new FileReader();
      reader.onload = function (e) {
        var img = new Image;
        img.onload = function () {
          var imgWidth = parseInt(that.branchwidth_limit)
          var imgHeight = parseInt(that.branchheight_limit)
          if (img.width != imgWidth || img.height != imgHeight) {
            that.toastr.error('Please upload image of ' + imgWidth + '*' + imgHeight)
            that.imageForm.get('image').setValue('');
            return false;
          } else {
            that.new_promofile = files.item(0);
            for (let dt of event.target.files) {
              if(dt.size < 4000000){
                that.images_array.push(dt);
              }else{
                $('#url_en_point').val('');
                that.toastr.error('Maximum $MB is allowed');
                return;
              }
            }
          }
        };
        img.src = reader.result as string;
      };
      reader.readAsDataURL(files.item(0));
    }

    if (type == 2) {
      // var validImageTypes = ["image/gif", "image/jpeg", "image/png", "image/jpg"];
      // if ($.inArray(fileType, validImageTypes) < 0) {
      //   that.toastr.error('Please upload image only');
      //   that.imageForm.get('image').setValue('');
      //   that.new_promofile = null;
      //   return false;
      // }
      that.video_array = [];
      var reader = new FileReader();
      reader.onload = function (e) {
            that.new_promofile = files.item(0);
            for (let dt of event.target.files) {
              if(dt.size < 8000000){
                that.video_array.push(dt);
              }else{
                $('#url_en_point1').val('');
                that.toastr.error('Maximum 8MB is allowed');
                return;
              }
            }
        };
      reader.readAsDataURL(files.item(0));
    }
  }

 
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }

  setImage(image, img) {
   
    var that = this;
    that.pro.getAllimage(img.name).subscribe(data=>{
     that.viewall$ = data.data.images;
    })
    $('#mmm').attr('src', image);
  }

}
