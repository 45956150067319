<site-header></site-header>
<style type="text/css">
img {
    height: auto;
    max-width: 100%;
}

.cell {
    display: table-cell;
}

.cell-fluid {
    width: 100%;
}
</style>
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Purchase Track</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Purchase Track</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row">
            <div class="col-xl-12">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <header class="panel-heading">
                        <h3 class="panel-title">
                            Purchase Track
                        </h3>
                    </header>
                    <div class="panel-body">
                        <div class="tb-wrapper">
                            <table cellspacing="0" id="table" class="table table-hover dataTable table-striped w-full" datatable [dtOptions]="dtOptions">
                                <thead>
                                    <tr>
                                        <th width="5%">Sl</th>
                                        <th width="22%">User Name </th>
                                        <th width="22%">Mobile </th>
                                        <th width="22%">Song Name </th>
                                        <th width="22%">Amount </th>
                                        <th width="10%">View</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="payments?.length != 0">
                                    <tr *ngFor="let payment of payments; let i = index">
                                        <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                        <td *ngFor="let user of payment.user">{{ user.first_name}} {{ user.last_name}}</td>
                                        <td *ngFor="let user of payment.user">{{ user.mobile_no}}</td>
                                        <td *ngFor="let song of payment.song">{{ song.title}}</td>
                                        <td>{{ payment.amount}}
                                        </td>
                                        <td>
                                            <button (click)="detailsPayment(payment)" class="btn btn-info waves-effect waves-classic btn-sm">View</button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="payments?.length == 0">
                                    <tr>
                                        <td colspan="7" class="no-data-available">No data!</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- End Panel Kitchen Sink -->
            </div>
        </div>
    </div>
</div>
<jw-modal id="payment_details">
    <div id="login">
        <div class="col-md-12">
            <div class="modal-header" style=" padding: 0; padding-bottom: 10px;">
                <h4 style="width: 100%" class="modal-title" id="myModalLabel"> Payment Details <a href="javascript:void(0);" style="float: right" (click)="closeModal('payment_details' )"><i class="icon md-close   text-primary "></i></a>
                </h4>
            </div>
            <!-- Example Horizontal Form -->
            <div class="">
                <div class="example">
                    <table class="table">
                        <tbody *ngIf="payment_details">
                            <tr>
                                <td width="20%"><b>Title</b> </td>
                                <td>{{ payment_details.song[0].title }}</td>
                            </tr>
                            <tr>
                                <td width="20%"><b>Album</b> </td>
                                <td>{{payment_details.song[0].album}}</td>
                            </tr>
                            <tr>
                                <td width="20%"><b>Category</b> </td>
                                <td>{{payment_details.song[0].category}} </td>
                            </tr>
                            <tr>
                                <td width="20%"><b>Song File</b> </td>
                                <td><audio controls>
                                        <source src="{{media_domain}}{{payment_details.song[0].link}}" type=""></audio> </td>
                            </tr>
                            <tr>
                                <td width="20%"><b>Thumbnail</b> </td>
                                <td><img src="{{media_domain}}{{payment_details.song[0].thumbnail}}"></td>
                            </tr>
                            <tr>
                                <td width="20%"><b>Amount</b> </td>
                                <td> $ {{ payment_details.amount }} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</jw-modal>
<!-- End Page -->
<style type="text/css">
.example-wrap {
    margin-bottom: 30px;
}

.disabledTable {
    pointer-events: none;
    opacity: 0.4;
}
</style>