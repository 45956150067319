import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { CategoryService, AlertService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.css']
})
export class SubCategoryComponent implements OnInit {
  bannerForm: FormGroup;
  loading = false;
  imageExist = '';
  fileToUpload: File = null;

  submitted = false;
  //table = false;
  cancelButton = false;
  users$: Observable<any[]>;
  main$: Observable<any[]>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  width_limit = `${environment.bannerWidth}`;
  height_limit = `${environment.bannerHeight}`;
  media_domain = `${environment.media_domain}`;
  subcatwidth_limit = `${environment.subcatWidth}`;
  subcatheight_limit = `${environment.subcatHeight}`;
  main_cat = [];
  main_cat_name = [];
  curPage = 1;
  perPage = 10;

  subCategory = {

    displayKey: 'title', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select main category', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No main category found', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search main category',
    padding: '15px', // label thats displayed in search input,


    selectAllBtnText: 'Select',
    searchOnKey: 'title' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys

    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private bannersService: CategoryService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    this.bannerForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      _id: [{ value: null, disabled: true }],
      name: ['', [Validators.required, Validators.maxLength(500)]],
      mainCat: ['', [Validators.required]],
      large_image: '',
      status: '',
      gst: ''
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };

    this.bannersService.getSubCategory().subscribe(data => {
      console.log(data);
      this.users$ = data.data.main;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });

    this.bannersService.getMainCat().subscribe(data => {
      console.log(data);
      this.main$ = data.data.main;
    }, error => {
      console.error(error);
    });

  }

  handleFileInput(event: any, files: FileList) {
    var that = this;
    var file = files[0];
    var fileType = file["type"];
    var validImageTypes = ["image/gif", "image/jpeg", "image/png","image/jpg"];
    if ($.inArray(fileType, validImageTypes) < 0) {
       that.toastr.error('Please upload image only');
       that.bannerForm.get('large_image').setValue('');
       that.fileToUpload = null;
       return false;
    }
    var reader = new FileReader();

    reader.onload = function (e) {
      var img = new Image;
      img.onload = function () {
        var imgWidth = parseInt(that.subcatwidth_limit)
        var imgHeight = parseInt(that.subcatheight_limit)
        if (img.width != imgWidth || img.height != imgHeight) {
          that.toastr.error('Please upload image of ' + imgWidth + '*' + imgHeight)
          that.bannerForm.get('large_image').setValue('');


        } else {
          that.fileToUpload = files.item(0);

        }
      };
      img.src = reader.result as string;
    };

    reader.readAsDataURL(files.item(0));

  }

  selectmainCategory(e) {
    console.log(e);
    this.main_cat_name = [];
    this.main_cat = [];
    for (let ids of e.value) {
      this.main_cat.push(ids._id);
    }

    this.main_cat_name = e.value;
  }


  // convenience getter for easy access to form fields
  get f() { return this.bannerForm.controls; }

  onSubmit(form, formWrap) {
    this.submitted = true;
    console.log(this.f.gst.value);

    let id = form.getRawValue()._id;
    let index = form.getRawValue().index;

    // if (id == null || !this.imageExist) {
    //   this.bannerForm.get('large_image').setValidators([Validators.required]);
    //   this.bannerForm.get('large_image').updateValueAndValidity();
    // }
    // stop here if form is invalid
    if (this.bannerForm.invalid) {
      return;
    }


    if (id != null) {
      document.body.classList.add('jw-modal-open');
      this.loading = true;
      this.bannersService.updateSub(id, this.f.name.value, this.f.status.value, this.fileToUpload, this.main_cat, this.main_cat_name, this.f.gst.value)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              location.reload();
              this.submitted = false;
              document.body.classList.remove('jw-modal-open');
              this.loading = false;
              this.cancelButton = false;
              // document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
              // this.users$[index] = data.data.banner;
              this.bannerForm.setValue({
                index: null,
                _id: null,
                name: '',
                status: '',
                large_image: ''
              })
              this.bannerForm.reset();

              this.imageExist = '';

              this.fileToUpload = null;
              location.reload();

              //this.router.navigate(['/banners']);
              location.href = "/sub-category";

            } else {
              document.body.classList.remove('jw-modal-open');
              this.loading = false;
              this.toastr.error(data.status.message)
              // location.reload();

              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            this.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
            document.body.classList.remove('jw-modal-open');
            this.loading = false;
          });
    } else {
      document.body.classList.add('jw-modal-open');
      this.loading = true;
      this.bannersService.saveSub(this.f.name.value, this.f.status.value, this.fileToUpload, this.main_cat, this.main_cat_name, this.f.gst.value)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              this.toastr.success('successfully added');
              this.users$ = data.data.main;
              location.reload();
              this.bannersService.getSubCategory().subscribe(data => {
                console.log(data);
                this.users$ = data.data.main;
                this.dtTrigger.next();
              }, error => {
                console.error(error);
              });
              this.submitted = false;
              document.body.classList.remove('jw-modal-open');
              this.loading = false;
              this.cancelButton = false;
              location.reload();

              //this.users$.unshift(data.data.banner)

              this.bannerForm.setValue({
                index: null,
                _id: null,
                name: '',
                mainCat: '',
                status: '',
                large_image: ''
              })
              this.bannerForm.reset();
              location.reload();
              this.fileToUpload = null;

              //this.router.navigate(['/banners']);
              location.href = "/sub-category";

            } else {
              document.body.classList.remove('jw-modal-open');
              this.loading = false;
              this.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            this.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
            document.body.classList.remove('jw-modal-open');
            this.loading = false;
          });
    }
    //this.bannerForm.reset() // reset form to empty


  }

  imageEdit(event) {
    this.imageExist = '';
  }
  userEdit(event, user, i) {
    this.main_cat_name = [];
    this.main_cat = [];

    this.cancelButton = true;
    //this.table = event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
    document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
    this.bannerForm.patchValue({
      index: i,
      _id: user._id,
      name: user.title,
      gst: user.exclude_gst,
      mainCat: user.main_category,
      status: parseInt(user.active),
      large_image: ''
    })

    this.main_cat_name = user.main_category;

    for (let mc of user.main_category) {
      this.main_cat.push(mc._id);
    }

    this.imageExist = user.image;
  }

  cancelSubmit(event) {
    location.reload();
    this.imageExist = '';
    this.cancelButton = false;
    document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
    this.bannerForm.patchValue({
      index: null,
      _id: null,
      name: '',
      status: '',
      mainCat: '',
      large_image: '',
      gst: ''
    });
    this.submitted = false;
  }
  userDelete(event, user, i) {
    var that = this;
    alertify.confirm('Are you sure want to delete this record', function () {
      that.bannersService.deleteSub(user._id)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.toastr.success('Deleted Successfully')
              // alertify.success('Deleted Successfully')
              setTimeout(function () {
                location.reload();
              }, 1000);
              //that.users$.splice(i, 1);
            } else {
              that.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            that.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
          });
    }
      , function () { //alertify.error('Delete action cancelled')
        that.toastr.error('Delete action cancelled')

      });

  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }
}
