<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> States</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> States</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row delstates_main">
            <div class="col-md-12">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <div class="abharan_main">
                        <!-- <header class="panel-heading">
                            <h3 class="panel-title">
                                States
                            </h3>
                        </header> -->

                        <div class="abharan_upload">
                            <div class="row del_top">
                                <div class="col-sm-6">
                                    <div class="upload_left">
                                        <ul>
                                            <li><b>Upload Excel</b></li>
                                            <li>
                                                <div class="upload-input"><input type="file" accept=".xls,.xlsx"
                                                        (change)="uploadExcel($event,$event.target.files)"
                                                        id="upload_file" name="file-upload"></div>
                                            </li>
                                            <li>
                                                <button class="upload_btn" (click)="uploadExcelFile()">Upload</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <!-- <div class="col-sm-6 ">

                                    <div class="upload_right">
                                        <ul>
                                            <li class="diamond">
                                                <a (click)="download()" style="cursor: pointer;">
                                                    <i class="fas fa-download"></i> Download Template
                                                </a>
                                            </li>



                                        </ul>
                                    </div>
                                    <div class="upload_right">
                                        <input type="text" class="form-control search_del"
                                            (keyup)="search($event.target.value)" placeholder="Search for state">
                                    </div>
                                </div> -->
                               
                                <div class="col-sm-6 top_btn">
                                    
                                        <div class="upload_right download_btn1">
                                            <ul>
                                                <li class="diamond">
                                                    <a (click)="download()" style="cursor: pointer;">
                                                        <i class="fas fa-download"></i> Download Template
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="upload_right search_area1">
                                            <input type="text" class="form-control search_del"
                                                (keyup)="search($event.target.value)" placeholder="Search for state">
                                        </div>
                                    
                                </div>

                                <!-- <div class="col-sm-3">

                                   
                                </div>
                                <div class="col-sm-2">
                                    
                                </div> -->

                            </div>
                        </div>


                        <div class="panel-body">
                         
                                <div class="tb-wrapper">

                                    <div class="table-responsive">
                                        <table cellspacing="0" id="table"
                                        class="table table-hover dataTable table-striped table-bordered delstates_table w-full">
    
                                        <thead>
                                            <tr>
                                                <!-- <th>Sl.No.</th> -->
                                                <th>State</th>
                                                <th>Gold Delivery Charge (₹)</th>
                                                <th>Silver Delivery Charge (₹)</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let pro of products$;let i=index">
                                                <!-- <td>{{ perPage * (curPage - 1) + i + 1 }}</td> -->
                                                <td>{{pro.state ? pro.state : '-'}}</td>
                                                <td>{{pro.gold_delivery_charge && pro.gold_delivery_charge.charge ?
                                                    pro.gold_delivery_charge && pro.gold_delivery_charge.charge : '0'}}</td>
                                                <td>{{pro.silver_delivery_charge && pro.silver_delivery_charge.charge ?
                                                    pro.silver_delivery_charge && pro.silver_delivery_charge.charge : '0'}}
                                                </td>
                                                <td (click)="deleteState(pro)" style="color:crimson;cursor:pointer;"><i
                                                        class="fa fa-trash-alt"></i></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                   
    
                                    <div class="col-sm-12" *ngIf="last_page>1">
                                        <div class="temple_pagination float-right">
                                            <a class="backward" (click)="pageChange(current_page-1)"
                                                [ngClass]="{'disable':current_page==1}"><i
                                                    class="fas fa-step-backward"></i></a> page
                                            <span>{{current_page}}</span> of {{last_page}}<a class="forward"
                                                (click)="pageChange(current_page+1)"
                                                [ngClass]="{'disable':current_page==last_page}"><i
                                                    class="fas fa-step-forward"></i></a>
                                        </div>
                                    </div>
    
                                </div>

                           
                           
                        </div>


                    </div>
                </div>
                <!-- End Panel Kitchen Sink -->
            </div>
        </div>
    </div>
</div>
<script src="/assets/js/bootstrap-table.js"></script>
<!-- End Page -->
<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }
</style>