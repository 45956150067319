import { Component, OnInit } from '@angular/core';
import { ProductService, AlertService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-enquiries',
  templateUrl: './enquiries.component.html',
  styleUrls: ['./enquiries.component.css']
})
export class EnquiriesComponent implements OnInit {
   dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  enquiry$: Observable<any[]>;
  length: any;
  loading = false;
  constructor(private pro: ProductService, private toastr: ToastrService) { }

  ngOnInit() {

     this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "SKU"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };
    this.loading = true;
    this.pro.getEnquiry().subscribe(data => {
      this.loading = false;
      this.enquiry$ = data.data;
      this.length = data.data.length;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    })
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }

}

