<site-header></site-header>
<link rel="stylesheet" href="/assets/css/bootstrap-table.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<style type="text/css">
    img {
        height: auto;
        max-width: 100%;
    }

    .cell {
        display: table-cell;
    }

    .cell-fluid {
        width: 100%;
    }

</style>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Branches</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Branches</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row meta_main">
            <div class="col-md-4 meta_left">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <!-- <header class="panel-heading">
                        <h3 class="panel-title">
                            Meta
                        </h3>
                    </header> -->
                    <div class="panel-body">

                        <form [formGroup]="brachForm" (ngSubmit)="brachSubmit()">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="url_en_point">Title</label>
                                        <input type="text"
                                            [ngClass]="{ 'is-invalid': (f.title.touched || submitted) && f.title.errors }"
                                            formControlName="title" id="url_en_point" placeholder="Enter title"
                                            class="form-control" autocomplete="off">
                                        <div *ngIf="f.title.errors?.required" class="invalid-feedback">
                                            <div *ngIf="f.title.errors?.required">Title is required</div>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="desc">Contact No1.</label>
                                        <input type="text" 
                                            [ngClass]="{ 'is-invalid': (f.contact1.touched || submitted) && f.contact1.errors?.required ||  f.contact1.errors?.pattern || f.contact1.errors?.maxlength || f.contact1.errors?.minlength  }"
                                            id="desc" formControlName="contact1" placeholder="Enter Contact No1."
                                            class="form-control" required="true" autocomplete="off">
                                        <div *ngIf="f.contact1.errors?.required" class="invalid-feedback">
                                            <div *ngIf="f.contact1.errors?.required">Contact No1. is required</div>
                                        </div>

                                        <div *ngIf="f.contact1.errors?.pattern" class="invalid-feedback">
                                            <div *ngIf="f.contact1.errors?.pattern">Enter digits only</div>
                                        </div>

                                        <div *ngIf="f.contact1.errors?.maxlength" class="invalid-feedback">
                                            <div *ngIf="f.contact1.errors?.maxlength">Enter maximum 15 digits</div>
                                        </div>
                                        <div *ngIf="f.contact1.errors?.minlength" class="invalid-feedback">
                                            <div *ngIf="f.contact1.errors?.minlength">Enter minimum 10 digits</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="desc">Contact No2. </label>
                                        <input type="text"
                                            [ngClass]="{ 'is-invalid': (f.contact2.touched || submitted) && f.contact2.errors?.required || f.contact2.errors?.pattern || f.contact2.errors?.maxlength || f.contact2.errors?.minlength }"
                                            id="desc" formControlName="contact2" placeholder="Enter Conatct No2."
                                            class="form-control" autocomplete="off">
                                        <!--  <div *ngIf="f.contact2.errors?.required" class="invalid-feedback">
                                            <div *ngIf="f.contact2.errors?.required">Contact No2. is required</div>
                                        </div> -->
                                        <div *ngIf="f.contact2.errors?.pattern" class="invalid-feedback">
                                            <div *ngIf="f.contact2.errors?.pattern">Enter digits only</div>
                                        </div>

                                        <div *ngIf="f.contact2.errors?.maxlength" class="invalid-feedback">
                                            <div *ngIf="f.contact2.errors?.maxlength">Enter maximun 15 digits</div>
                                        </div>

                                        <div *ngIf="f.contact2.errors?.minlength" class="invalid-feedback">
                                            <div *ngIf="f.contact2.errors?.minlength">Enter minimum 10 digits</div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="desc">Address</label>
                                        <textarea type="text"
                                            [ngClass]="{ 'is-invalid': (f.address.touched || submitted) && f.address.errors?.required || f.address.errors?.pattern || f.address.errors?.maxlength || f.address.errors?.minlength }"
                                            id="desc" formControlName="address" placeholder="Enter Address"
                                            class="form-control" autocomplete="off"></textarea>
                                        <div *ngIf="f.contact1.errors?.required" class="invalid-feedback">
                                            <div *ngIf="f.contact1.errors?.required">Contact No1. is required</div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="desc">Email</label>
                                        <input type="text"
                                            [ngClass]="{ 'is-invalid': (f.email.touched || submitted) && f.email.errors?.required || f.email.errors?.pattern }"
                                            id="desc" formControlName="email" placeholder="Enter Email"
                                            class="form-control" autocomplete="off">
                                        <div *ngIf="f.email.errors?.required" class="invalid-feedback">
                                            <div *ngIf="f.email.errors?.required">Email is required</div>
                                        </div>
                                        <div *ngIf="f.email.errors?.pattern" class="invalid-feedback">
                                            <div *ngIf="f.email.errors?.pattern">Enter valid email</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="desc">From</label>
                                        <input type="time" autocomplete="gdfgdfg" name="date" id="desc"
                                            formControlName="fromTime" class="form-control"
                                            [ngClass]="{ 'is-invalid': (f.fromTime.touched || submitted) && f.fromTime.errors?.required}"
                                            autocomplete="off">
                                        <div *ngIf="f.fromTime.errors?.required" class="invalid-feedback">
                                            <div *ngIf="f.fromTime.errors?.required">From time is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="desc">To</label>
                                        <input type="time"
                                            [ngClass]="{ 'is-invalid': (f.toTime.touched || submitted) && f.toTime.errors?.required}"
                                            autocomplete="gdfgdfg" name="date" id="desc" formControlName="toTime"
                                            class="form-control" autocomplete="off">
                                        <div *ngIf="f.toTime.errors?.required" class="invalid-feedback">
                                            <div *ngIf="f.toTime.errors?.required">End time is required</div>
                                        </div>
                                    </div>
                                </div>



                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="meta_tag">Latitude </label>
                                        <input type="text"
                                            [ngClass]="{ 'is-invalid': (f.latitude.touched || submitted) && f.latitude.errors?.required ||  f.latitude.errors?.pattern }"
                                            id="meta_tag" formControlName="latitude" placeholder="Enter Latitude"
                                            class="form-control" autocomplete="off">
                                        <div *ngIf="f.latitude.errors?.required" class="invalid-feedback">
                                            <div *ngIf="f.latitude.errors?.required">Latitude is required</div>
                                        </div>
                                        <div *ngIf="f.latitude.errors?.pattern" class="invalid-feedback">
                                            <div *ngIf="f.latitude.errors?.pattern">Enter digits only</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="meta_tag">Longitude </label>
                                        <input type="text"
                                            [ngClass]="{ 'is-invalid': (f.longitude.touched || submitted) && f.longitude.errors?.required || f.longitude.errors?.pattern }"
                                            id="meta_tag" formControlName="longitude" placeholder="Enter longitude"
                                            class="form-control" autocomplete="off">
                                        <div *ngIf="f.longitude.errors?.required" class="invalid-feedback">
                                            <div *ngIf="f.longitude.errors?.required">Longitude is required</div>
                                        </div>
                                        <div *ngIf="f.longitude.errors?.pattern" class="invalid-feedback">
                                            <div *ngIf="f.longitude.errors?.pattern">Enter digits only</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="meta_tag">City </label>
                                        <input type="text"
                                            [ngClass]="{ 'is-invalid': (f.city.touched || submitted) && f.city.errors?.required || f.longitude.errors?.pattern }"
                                            id="meta_tag" formControlName="city" placeholder="Enter city"
                                            class="form-control" autocomplete="off">
                                        <div *ngIf="f.city.errors?.required" class="invalid-feedback">
                                            <div *ngIf="f.city.errors?.required">City is required</div>
                                        </div>
                                       
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="meta_tag">State </label>
                                        <input type="text"
                                            [ngClass]="{ 'is-invalid': (f.state.touched || submitted) && f.state.errors?.required || f.longitude.errors?.pattern }"
                                            id="meta_tag" formControlName="state" placeholder="Enter state"
                                            class="form-control" autocomplete="off">
                                        <div *ngIf="f.state.errors?.required" class="invalid-feedback">
                                            <div *ngIf="f.state.errors?.required">State is required</div>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="meta_tag">Districts </label>
                                        <input type="text"
                                            [ngClass]="{ 'is-invalid': (f.district.touched || submitted) && f.district.errors?.required || f.longitude.errors?.pattern }"
                                            id="meta_tag" formControlName="district" placeholder="Enter district"
                                            class="form-control" autocomplete="off">
                                        <div *ngIf="f.district.errors?.required" class="invalid-feedback">
                                            <div *ngIf="f.district.errors?.required">District is required</div>
                                        </div>
                                     
                                    </div>
                                </div>


                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="meta_tag">Image </label>
                                        <input *ngIf="! imageExist" type="file"
                                            (change)="handleFileInput($event,$event.target.files)"
                                            [ngClass]="{ 'is-invalid': (f.image.touched || submitted) && f.image.errors }"
                                            id="meta_tag" formControlName="image" placeholder="Enter meta keyword"
                                            class="form-control">

                                        <a *ngIf="imageExist" class="mr-10" href="{{media_domain+imageExist}}"
                                            target="_blank">Branch
                                            Image</a><a *ngIf="imageExist" (click)="imageEdit($event)">| Change</a>


                                        <div *ngIf="(f.image.touched || submitted) && f.image.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="f.image.errors?.required">Image is required</div>
                                        </div>

                                        <small style="display: block">(Image Dimensions:
                                            {{branchwidth_limit}}*{{branchheight_limit}})</small>
                                    </div>
                                </div>


                                <!-- <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="meta_tag">Image2 </label>
                                        <input *ngIf="!imageExist2" type="file"
                                            (change)="handleFileInput2($event,$event.target.files)" id="meta_tag"
                                            formControlName="image2" placeholder="Enter meta keyword"
                                            class="form-control">

                                        <a *ngIf="imageExist2" class="mr-10" href="{{media_domain+imageExist2}}"
                                            target="_blank">Branch
                                            Image</a><a *ngIf="imageExist2" (click)="imageEdit2($event)">| Change</a>

                                        <small style="display: block">(Image Dimensions:
                                            {{branchwidth_limit}}*{{branchheight_limit}})</small>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="meta_tag">Image3 </label>
                                        <input *ngIf="!imageExist3" type="file"
                                            (change)="handleFileInput3($event,$event.target.files)" id="meta_tag"
                                            formControlName="image3" placeholder="Enter meta keyword"
                                            class="form-control">

                                        <a *ngIf="imageExist3" class="mr-10" href="{{media_domain+imageExist3}}"
                                            target="_blank">Branch
                                            Image</a><a *ngIf="imageExist3" (click)="imageEdit3($event)">| Change</a>


                                        <small style="display: block">(Image Dimensions:
                                            {{branchwidth_limit}}*{{branchheight_limit}})</small>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="meta_tag">Image4 </label>
                                        <input *ngIf="!imageExist4" type="file"
                                            (change)="handleFileInput4($event,$event.target.files)" id="meta_tag"
                                            formControlName="image4" placeholder="Enter meta keyword"
                                            class="form-control">

                                        <a *ngIf="imageExist4" class="mr-10" href="{{media_domain+imageExist4}}"
                                            target="_blank">Branch
                                            Image</a><a *ngIf="imageExist4" (click)="imageEdit4($event)">| Change</a>


                                        <small style="display: block">(Image Dimensions:
                                            {{branchwidth_limit}}*{{branchheight_limit}})</small>
                                    </div>
                                </div> -->

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <button class="btn btn-primary mt-3 metaleft_btn">
                                            <div *ngIf="cancelButton;then update else add"></div>
                                            <ng-template #add>Submit</ng-template>
                                            <ng-template #update>Update</ng-template>
                                        </button>
                                        <button *ngIf="cancelButton" class="btn btn-danger mt-3 float-right"
                                            (click)="cancel()">Cancel</button>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-8 pro meta_right">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <!-- <header class="panel-heading">
                        <h3 class="panel-title">
                            Meta
                        </h3>
                    </header> -->
                    <div class="panel-body">
                        <div class="tb-wrapper">
                            <div class="table-responsive">
                                <table cellspacing="0" id="table"
                                    class="table table-hover dataTable table-striped table-bordered meta_table w-full" datatable
                                    [dtOptions]="dtOptions">

                                    <tr>
                                        <th>Title</th>
                                        <th>Image</th>
                                        <th>Contact No1.</th>
                                        <th>Contact No2.</th>
                                        <th>Action</th>
                                    </tr>
                                    <tr *ngFor="let branch of meta$;let j=index">
                                        <td>{{branch.branch_name?branch.branch_name:'NA'}}</td>
                                        <td><img src="{{media_domain}}{{branch.image}}"
                                                style="height: 70px;width:70px;"></td>
                                        <td>{{branch.contact1?branch.contact1:'NA'}}</td>
                                        <td>{{branch.contact2?branch.contact2:'NA'}}</td>
                                        <td><i class="fa fa-edit mr-2" (click)="editMeta(branch)" title="Edit"></i><i
                                                (click)="deleteMeta(branch)" title="Delete"
                                                class="fas fa-trash-alt"></i>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
