<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
    <div class="page-header">
        <h1 class="page-title">Product Enquiries</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active">Product Enquiries</li>
        </ol>
    </div>
</div>
<div class="container-fluid">

    <div class="panel">
        <div class="panel-body">
            <div class="row">
                <!-- <div class="col-md-9">
                    <h3 class="ng-binding">Product Enquiries </h3>
                </div> -->

                <!-- <div class="col-md-3 form-inline">
            <label>Search: </label>
            <input type="text" name="email" autocomplete="off" class="form-control"
                placeholder="Email">
        </div> -->
            </div>

            <div class="row enquiries_main ">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <!--  <table class="table table-bordered"> -->
                        <table cellspacing="0" id="table" class="table table-hover dataTable table-striped table-bordered enquiries_table w-full" datatable
                            [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr style="font-weight: bold;">
                                    <th width="">Sl. No.</th>
                                    <th width="">SKU</th>
                                    <th width="">NAME </th>
                                    <th width="">EMAIL </th>
                                    <th width="">MOBILE </th>
                                    <th width="">COMMUNICATION MODE</th>
                                    <th width="">COMMENTS</th>
                                    <th width="">CREATED ON.</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let enquiry of enquiry$;let j=index">
                                    <td>{{j+1}}</td>
                                    <td>{{enquiry.sku}}</td>
                                    <td>{{enquiry.name}}</td>
                                    <td>{{enquiry.email}}</td>
                                    <td>{{enquiry.mobile}}</td>
                                    <td>{{enquiry.call_type}}</td>
                                    <td><span class="desc_cls"> {{enquiry.message}}</span></td>
                                    <td>{{enquiry.created_at}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                Message
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <span class="message_text"></span>
            </div>
        </div>
    </div>
</div>
