import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductService, AlertService, BannersService, CategoryService, VideoService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { toArray } from 'rxjs/operators';
import { LoginComponent } from '../login';

declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var moment: any;

@Component({
  selector: 'app-video-view',
  templateUrl: './video-view.component.html',
  styleUrls: ['./video-view.component.css']
})



export class VideoViewComponent implements OnInit {

  videoForm: FormGroup;
  mainCategory = {

    displayKey: 'title', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select main category', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No main category found', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search main category',
    padding: '15px', // label thats displayed in search input,


    selectAllBtnText: 'Select',
    searchOnKey: 'title' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys

    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  subCategory = {

    displayKey: 'title', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select sub category', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No sub category found', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search sub category',
    padding: '15px', // label thats displayed in search input,


    selectAllBtnText: 'Select',
    searchOnKey: 'title' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys

    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  constructor(private route: ActivatedRoute, private bannerService: BannersService, private cat: CategoryService, private formBuilder: FormBuilder, private video: VideoService, private toastr: ToastrService) { }
  routeSub: any;

  main$: Observable<any[]>;

  sub$: Observable<any[]>;

  video$: Observable<any[]>;

  main_list: any;

  main: any;
  sub: any;

loading = false;
  images: any;

  submitted = false;

  media_domain = `${environment.media_domain}`;

  weblink = `${environment.WEBLINK}`;

  videoSingle$: any;

  curUrl: any;

  men_check: any;
  women_check: any;
  kids_check: any;
  in_stock: any;


  // branchwidth_limit = `${environment.prodImgWidth}`;
  // branchheight_limit = `${environment.prodImgHeight}`;


  new_promofile: any;
  images_array: any;

  ngOnInit() {

    this.curUrl = localStorage.getItem('currentUrl');

    this.videoForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      mobile_number:['', [Validators.required]],
      language:['', [Validators.required]],
      date:['', [Validators.required]],
      time:['', [Validators.required]],
      category:['', [Validators.required]],
      sub_category:['', [Validators.required]],
      message:['', [Validators.required]],
      city:['', [Validators.required]],
      status:['', [Validators.required]],
      url: ['', [Validators.required]]
    });



    this.cat.getMainCat().subscribe(data => {
   
      this.main$ = data.data.main;
      
    }, error => {
      console.error(error);
    });

    this.cat.getSubCategory().subscribe(data => {
      this.sub$ = data.data.main;
    }, error => {
      console.error(error);
    });
this.loading = true;
    this.routeSub = this.route.params.subscribe(params => {
      this.video.getsingleVideo(params['id']).subscribe(data => {
    this.loading = false;
        this.videoSingle$ = data.data;
       
       
      }, error => {
        console.log(error);
      })
    });
  }

  selectCategory(e) {
  
    this.cat = e.value.category;
  }

  


  get f() { return this.videoForm.controls; }
  videoSubmit() {

    this.submitted = true;
    if (this.videoForm.invalid) {
      return false;
    }
    var that = this;
 
    alertify.confirm('Are you sure want to update this video request?', function () {
      that.video.editVideo(
        that.videoSingle$._id,
        that.f.name.value,
        that.f.email.value,
        that.f.mobile_number.value,
        that.f.language.value,
        that.f.date.value,
        that.f.time.value,
        that.f.category.value.title ? that.f.category.value.title : that.videoSingle$.category,
        that.f.sub_category.value.title ? that.f.sub_category.value.title : that.videoSingle$.sub_category,
        that.f.city.value,
        that.f.message.value,
        that.f.status.value,
        that.f.url.value
      ).subscribe(data => {
        if (data.status.code == 0) {
          that.toastr.success('Successfully updated');
          location.reload();
        } else {
          that.toastr.error(data.status.message);
        }
      }, error => {
        console.error(error);
      });

    }, function () {
      that.toastr.error("Action cancelled");
    });

  }

  goBack() {
    this.curUrl = localStorage.getItem('currentUrl');
    location.href = this.curUrl;
  }



  selectmainCategory(e) {

    this.main = e.value.title;
  }

  selectSub(e) {
    this.sub = e.value.title;
  }

  editSingleOrder() {
    $('.fff').show();
    $('.ccc').hide();

    this.videoForm.patchValue({ 
      name: this.videoSingle$.name,
      email: this.videoSingle$.email,
      mobile_number:this.videoSingle$.mobile_number,
      date:this.videoSingle$.date,
      language: this.videoSingle$.language,
      time: this.videoSingle$.time,
      category: this.videoSingle$.category,
      sub_category: this.videoSingle$.sub_category,
      city:this.videoSingle$.city,
      message:this.videoSingle$.message,
      status:this.videoSingle$.status,
      url:this.videoSingle$.url
    });
  }

  cancelOp() {
    $('.fff').hide();
    $('.ccc').show();
  }
}
