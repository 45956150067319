<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<style type="text/css">
    google-chart {
        width: 100%;
    }
</style>
<!-- Page -->
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Whatsapp Click</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Whatsapp Click</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row whatsappclick_main">
            <div class="col-xl-12">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <div class="abharan_main">
                        <!-- <header class="panel-heading">
                            <h3 class="panel-title">
                                Whatsapp Click
                            </h3>
                        </header> -->

                        <div class="panel-body">

                            <div class="tb-wrapper">
                                <div class="row">
                                    <div class="col-md-2">
                                        <select ([ngModel])="selectedYear" class="form-control"
                                            (change)="searchFilter($event.target.value,'year')">
                                            <option disabled>Select year</option>
                                            <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                                        </select>
                                    </div>

                                    
                                <div class="col-md-2">
                   
                                   <button (click)="reset($event)" class="btn-primary reset_cls" style="cursor: pointer;">Reset</button>
                                </div>



                                </div>
                                <br>

                                <div class="tabke-responsive">
                                    <table cellspacing="0" id="table"
                                        class="table table-hover dataTable table-striped table-bordered videocall_table w-full">

                                    <thead>
                                        <tr>
                                            <th>Sl.No.</th>
                                            <th>Year</th>
                                            <th *ngIf="!year">Month</th>
                                            <th>Click</th>

                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let wc of wcount$;let i=index">
                                        <ng-container *ngIf="year">
                                            <tr *ngIf="wc.Type == 'Year'">
                                                <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                                <td>
                                                    {{wc.Year}}
                                                </td>
                                                <td>
                                                    {{wc.visits}}
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngIf="!year">
                                            <tr *ngIf="wc.Type == 'Month'">
                                                <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                                <td>
                                                    {{wc.Year}}
                                                </td>
                                                <td>
                                                    {{wc.Month}}
                                                </td>
                                                <td>
                                                    {{wc.visits}}
                                                </td>
                                            </tr>
                                        </ng-container>

                                    </tbody>
                                    <!-- <tbody *ngIf="wcount$.length == 0">
                                     <tr class="no_data">
                                         <td>
                                            No data found
                                         </td>
                                     
                                     </tr>  
                                    </tbody> -->
                                </table>
                                <div class="row paginate">
                                    <div class="col-sm-8">
                                    <div class="shloka_result" *ngIf="wcount$ != 0">
                                                             
      Total :  {{total}}  &nbsp; 
       Showing &nbsp; {{from}} - {{to}}                     
                                   </div>
                                      </div>
                                   <div class="col-sm-4" *ngIf="wcount$ && wcount$.length">
                                    <div class="temple_pagination pg_cls">
                                        <a class="backward" [ngClass]="{'disable':curPage==1}" (click)="pageChange(curPage-1)"><i
                                                class="fas fa-step-backward"></i></a> Page
                                        <span>{{curPage}}</span>of {{last_page}}<a [ngClass]="{'disable':curPage==last_page}"
                                            class="forward" (click)="pageChange(curPage+1)"><i class="fas fa-step-forward"></i></a>
                                    </div>
                                </div>
                                </div>
                                </div>
                                

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<script src="/assets/js/bootstrap-table.js"></script>
<!-- End Page -->
<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }
</style>