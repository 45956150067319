<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
    <div class="page-header">
        <h1 class="page-title">User Feedback</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active">User Feedback</li>
        </ol>
    </div>
</div>
<div class="page-content container-fluid">
    <div class="row videocall_main">
        <div class="col-xl-12">
            <!-- Panel Kitchen Sink -->
            <div class="panel">
                <div class="abharan_main">
                    <div class="panel-body">
                        <div class="tb-wrapper">
                            <div class="table-responsive1">
                                <table cellspacing="0" id="table"
                                    class="table table-hover dataTable table-striped table-bordered videocall_table w-full">
                                    <thead>
                                        <tr>
                                            <th width="5%">Sl.No.</th>
                                            <th width="15%">{{fq1}}</th>
                                            <th width="15%">{{fq2}}</th>
                                            <th width="15%">{{fq3}}</th>
                                            <th width="15%">{{fq4}}</th>
                                            <th width="15%">{{fq5}}</th>
                                            <th width="10%">User Name</th>
                                            <th width="10%">OrderId</th>
                                            
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr *ngFor="let pro of feedback$;let i=index">
                                            <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                        <td>{{pro.fq1 ? pro.fq1 : 0}} / 5</td>
                                        <td>{{pro.fq2 ? pro.fq2 : 0}} / 5</td>
                                        <td>{{pro.fq3 ? pro.fq3 : 0}} / 5</td>
                                        <td>{{pro.fq4 ? pro.fq4 : 0}} / 5</td>
                                        <td>{{pro.fq5 ? pro.fq5 : '-'}}</td>
                                        <td>{{pro.user.first_name}}</td>
                                        <td>{{pro.orderId}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row paginate">
                                    <div class="col-sm-8">
                                    <div class="shloka_result" *ngIf="feedback$ != 0">
                                                             
      Total :  {{total}}  &nbsp; 
       Showing &nbsp; {{from}} - {{to}}                     
                                   </div>
                                      </div>
                                   <div class="col-sm-4" *ngIf="feedback$ && feedback$.length">
                                    <div class="temple_pagination pg_cls">
                                        <a class="backward" [ngClass]="{'disable':curPage == 1}" (click)="pageChange(curPage-1)"><i
                                                class="fas fa-step-backward"></i></a> Page
                                        <span>{{curPage}}</span>of {{last_page}}<a [ngClass]="{'disable':curPage==last_page}"
                                            class="forward" (click)="pageChange(curPage+1)"><i class="fas fa-step-forward"></i></a>
                                    </div>
                                </div>
                                </div>
                            </div>

                        </div>
                    </div>
                <!-- End Panel Kitchen Sink -->
                </div>
            </div>
        </div>
    </div>
   
</div>
<script src="/assets/js/bootstrap-table.js">
   
</script>
<!-- End Page -->
<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }

</style>    