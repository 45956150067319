<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<style type="text/css">
    img {
        height: auto;
        max-width: 100%;
    }

    .cell {
        display: table-cell;
    }

    .cell-fluid {
        width: 100%;
    }
</style>
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Most Ordered Products</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Most Ordered Products</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row user_main">
            <div class="col-md-12">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <!-- <header class="panel-heading">
                        <h3 class="panel-title">
                            Users
                            <div class="row custom-filters" style="display:none;margin-left: 12px;">
                                <div class="form-group col-md-4">
                                    <input type="text" class="form-control" placeholder="Registered on"
                                        autocomplete="off" id="fini" readonly>
                                </div>
                            </div>
                        </h3>
                    </header> -->

                    <div class="panel-body">
                        <div class="tb-wrapper">
                          <div class="row">
                            <div class="col-md-2">
                                <select class="form-control"
                                (change)="searchFilter($event.target.value)">
                                <option value="products" [selected]>Products</option>
                                <option value="city">City</option>
                            </select>
                            </div>
                          </div>
                            <div class="main_table">
                                <table cellspacing="0" id="table"
                                    class="table table-hover dataTable table-striped table-bordered user_table w-full"
                                    >
                                    <thead>
                                        <tr>
                                            <th width="10%">Sl No.</th>
                                            <!-- <th width="15%">Order ID</th> -->
                                            <th width="15%">SKU</th>
                                            <!-- <th width="">Main Category</th>
                                            <th width="">Sub Category</th> -->
                                            <th width="15%">Product Name</th>
                                            <th width="15%">Product Image</th>
                                            <th width="20%">City</th>
                                            <th width="10%">Count</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="users$?.length != 0">
                                        <tr *ngFor="let user of users$; let i = index">
                                            <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                            <!-- <td *ngIf="user.order_no;else name">{{ user.order_no}}</td> -->
                                            <ng-template #name>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user.products.product.SKU;else email">{{ user.products.product.SKU}} </td>
                                            <ng-template #email>
                                                <td>-</td>
                                            </ng-template>
                                            <!-- <td>{{ user.product.name }}</td>
                                            <td>{{ user.product.name }}</td> -->
                                            <td>{{ user.products.product.name }}</td>
                                            <td *ngIf="user.products.product && user.products.product.pro_image && user.products.product.pro_image.image">
                                               
                                                <img src="{{media_domain}}{{user.products.product.pro_image.image}}" class="image">
                                               
                                            </td>
                                            <td *ngIf="user.products.product && user.products.product.pro_image && !user.products.product.pro_image.image">
                                                <img class="image" src="/assets/default.png" />
                                            </td>
                                                <td>{{ user.products.city }}</td>
                                                    <td>{{ user.count}}
                                            </td>
                                          
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="users$?.length == 0">
                                        <tr>
                                            <td colspan="7" class="no-data-available">No data!</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row paginate">
                                    <div class="col-sm-8">
                                    <div class="shloka_result" *ngIf="users$ != 0">
                                                             
      Total :  {{total}}  &nbsp; 
       Showing &nbsp; {{from}} - {{to}}                     
                                   </div>
                                      </div>
                                   <div class="col-sm-4" *ngIf="users$ && users$.length">
                                    <div class="temple_pagination pg_cls">
                                        <a class="backward" [ngClass]="{'disable':curPage==1}" (click)="pageChange(curPage-1)" style="cursor: pointer;"><i
                                                class="fas fa-step-backward"></i></a> Page
                                        <span>{{curPage}}</span>of {{last_page}}<a [ngClass]="{'disable':curPage==last_page}"
                                            class="forward" (click)="pageChange(curPage+1)" style="cursor: pointer;"><i class="fas fa-step-forward"></i></a>
                                    </div>
                                </div>
                                </div>
                            </div>

                          
                        </div>
                    </div>
                </div>
                <!-- End Panel Kitchen Sink -->
            </div>
        </div>
    </div>
</div>

<!-- End Page -->
<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }
</style>