<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<div class="page">
    <div class="page-header">
        <h1 class="page-title">Video Calling</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Video Calling</li>
        </ol>
    </div>
    <div class="container">
        <div class="page-content ccc container-fluid pt-0">
            <div class="row">
                <div class="col-xl-12">
                    <!-- Panel Kitchen Sink -->
                    <div class="panel">
                        <div class="abharan_main">
                            <div class="row">
                                <div class="panel-body col-md-8">
                                    <button class="btn btn-info btn-sm right" (click)="editSingleOrder()"><i
                                            class="fa fa-edit mr-1"></i>Edit</button>
                                    <button class="btn btn-danger btn-sm right ml-2" (click)="goBack()"><i
                                            class="fa fa-arrow-left mr-1"></i>Back</button>
                                    <div class="tb-wrapper">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th>Name</th>
                                                    <td>
                                                        {{videoSingle$ && videoSingle$.name ? videoSingle$.name : "NA"}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Email</th>
                                                    <td>
                                                        {{videoSingle$ && videoSingle$.email ? videoSingle$.email : "NA"}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Mobile Number</th>
                                                    <td>
                                                        {{videoSingle$ && videoSingle$.mobile_number ? videoSingle$.mobile_number : "NA"}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Language</th>
                                                    <td>
                                                        {{videoSingle$ && videoSingle$.language ? videoSingle$.language : "NA"}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Date</th>
                                                    <td>
                                                        {{videoSingle$ && videoSingle$.date ? videoSingle$.date : "NA"}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Time</th>
                                                    <td>
                                                        {{videoSingle$ && videoSingle$.time ? videoSingle$.time : "NA"}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Category</th>
                                                    <td>
                                                        {{videoSingle$ && videoSingle$.category ? videoSingle$ && videoSingle$.category : "NA"}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Sub-Category</th>
                                                    <td>
                                                        {{videoSingle$ && videoSingle$.sub_category ? videoSingle$.sub_category : "NA"}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Message</th>
                                                    <td>
                                                       
                                                        {{videoSingle$ && videoSingle$.message ? videoSingle$.message : "NA"}}
                                                   
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>City</th>
                                                    <td>
                                                        {{videoSingle$ && videoSingle$.city ? videoSingle$.city : "NA"}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Status</th>
                                                    <td>
                                                        {{videoSingle$ && videoSingle$.status ? videoSingle$.status : "NA"}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Meeting Link</th>
                                                    <td>
                                                        {{videoSingle$ && videoSingle$.url ? videoSingle$.url : "-"}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Panel Kitchen Sink -->
                </div>
            </div>
        </div>
        <div class="page-content fff container-fluid pt-0" style="display: none">
            <div name="product_edit" class="mmm">
                <h4>
                    <span>Edit Video Calling </span>
                    <div class="pull-right mb-2">
                        <a class="btn btn-danger btn-sm " (click)="cancelOp()" href="javascript:void(0);"
                            name="edit_cancel">
                            <i class="fa fa-arrow-left"></i> Cancel
                        </a>
                    </div>
                </h4>
           
                <form [formGroup]="videoForm" (ngSubmit)="videoSubmit()" class="form-horizontal"
                    name="update_product">
                    <div class="table-responsive">
                        <table class="table table-striped" style="background:#fff !important;">
                            <tbody>
                                <tr>
                                    <td colspan="1">
                                        <fieldset>
                                        <div class="form-group"> 
                                                <label class="col-md-4 control-label">Name <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="name"
                                                            [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors }"
                                                            name="name" placeholder="Name" class="form-control"
                                                            required="true" type="text">
                                                    </div>
                                                    <div *ngIf="(f.name.touched || submitted) && f.name.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.name.errors?.required">Name is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group"> 
                                                <label class="col-md-4 control-label">Mobile Number <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="mobile_number"
                                                            [ngClass]="{ 'is-invalid': (f.mobile_number.touched || submitted) && f.mobile_number.errors }"
                                                            name="mobile_number" placeholder="Mobile Number" class="form-control"
                                                            required="true" type="text">
                                                    </div>
                                                    <div *ngIf="(f.mobile_number.touched || submitted) && f.mobile_number.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.mobile_number.errors?.required">Mobile Number is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group"> 
                                                <label class="col-md-4 control-label">Date<span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="date"
                                                            [ngClass]="{ 'is-invalid': (f.date.touched || submitted) && f.date.errors }"
                                                            name="date" placeholder="date" class="form-control"
                                                            required="true" type="date">
                                                    </div>
                                                    <div *ngIf="(f.date.touched || submitted) && f.date.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.date.errors?.required">Date is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                            <div class="form-group">
                                                <label class="col-md-4 control-label">Category<span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <ngx-select-dropdown #id [config]="mainCategory"
                                                            [options]="main$" [multiple]=false formControlName="category"
                                                            (change)="selectmainCategory($event)"
                                                            [(ngModel)]="main_list"
                                                            [ngClass]="{ 'is-invalid': (f.category.touched || submitted) && f.category.errors?.required }">
                                                        </ngx-select-dropdown>
                                                    </div>
                                                    <div *ngIf="(f.category.touched || submitted) && f.category.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.category.errors?.required">Main category is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group"> 
                                                <label class="col-md-4 control-label">Message<span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="message"
                                                            [ngClass]="{ 'is-invalid': (f.message.touched || submitted) && f.message.errors }"
                                                            name="message" placeholder="Message" class="form-control"
                                                            required="true" type="text">
                                                    </div>
                                                    <div *ngIf="(f.message.touched || submitted) && f.message.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.message.errors?.required">Message is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group"> 
                                                <label class="col-md-4 control-label">Status<span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="status"
                                                            [ngClass]="{ 'is-invalid': (f.status.touched || submitted) && f.status.errors }"
                                                            name="status" placeholder="Status" class="form-control"
                                                            required="true" type="text">
                                                    </div>
                                                    <div *ngIf="(f.status.touched || submitted) && f.status.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.status.errors?.required">Message is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </td>
                                    <td colspan="1">
                                        <fieldset>
                                            <div class="form-group"> 
                                                <label class="col-md-4 control-label">Email <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="email"
                                                            [ngClass]="{ 'is-invalid': (f.email.touched || submitted) && f.email.errors }"
                                                            name="email" placeholder="Email" class="form-control"
                                                            required="true" type="text">
                                                    </div>
                                                    <div *ngIf="(f.email.touched || submitted) && f.email.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.email.errors?.required">Email is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group"> 
                                                <label class="col-md-4 control-label">Language <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="language"
                                                            [ngClass]="{ 'is-invalid': (f.language.touched || submitted) && f.language.errors }"
                                                            name="language" placeholder="Language" class="form-control"
                                                            required="true" type="text">
                                                    </div>
                                                    <div *ngIf="(f.language.touched || submitted) && f.language.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.language.errors?.required">Language is required</div>
                                                    </div>
                                                </div>
                                            </div>
      
                                            <div class="form-group"> 
                                                <label class="col-md-4 control-label">Time <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="time"
                                                            [ngClass]="{ 'is-invalid': (f.time.touched || submitted) && f.time.errors }"
                                                            name="time" placeholder="Time" class="form-control"
                                                            required="true" type="text">
                                                    </div>
                                                    <div *ngIf="(f.time.touched || submitted) && f.time.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.time.errors?.required">Time is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            

                                            <div class="form-group">
                                                <label class="col-md-4 control-label">SUB CATEGORY</label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group">
                                                        <ngx-select-dropdown #id [config]="subCategory" [options]="sub$"
                                                            [multiple]=false formControlName="sub_category"
                                                            (change)="selectSub($event)"
                                                            [ngClass]="{ 'is-invalid': (f.sub_category.touched || submitted) && f.sub_category.errors?.required }">
                                                        </ngx-select-dropdown>
                                                    </div>
                                                    <div *ngIf="(f.sub_category.touched || submitted) && f.sub_category.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.sub_category.errors?.required">Sub category is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="form-group"> 
                                                <label class="col-md-4 control-label">City<span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="city"
                                                            [ngClass]="{ 'is-invalid': (f.city.touched || submitted) && f.city.errors }"
                                                            name="city" placeholder="City" class="form-control"
                                                            required="true" type="text">
                                                    </div>
                                                    <div *ngIf="(f.city.touched || submitted) && f.city.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.city.errors?.required">City is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group"> 
                                                <label class="col-md-4 control-label">Meeting Link<span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-8 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="url"
                                                            [ngClass]="{ 'is-invalid': (f.url.touched || submitted) && f.url.errors }"
                                                            name="url" placeholder="Meeting Link" class="form-control"
                                                            required="true" type="text">
                                                    </div>
                                                    <div *ngIf="(f.url.touched || submitted) && f.url.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.url.errors?.required">Meeting Link is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </td>
                                </tr>
                              
                            </tbody>
                        </table>
                        <button type="submit" class="btn btn-primary ml-4 mb-2">Update</button>
                    </div>
                 
                </form>
            </div>
        </div>
    </div>
</div>
<script src="/assets/js/bootstrap-table.js"></script>
<!-- End Page -->
<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }

</style>
