import { Component, OnInit } from '@angular/core';
import { ProductService, AlertService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';


declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-delete-products',
  templateUrl: './delete-products.component.html',
  styleUrls: ['./delete-products.component.css']
})
export class DeleteProductsComponent implements OnInit {

  constructor(private pro: ProductService, private toastr: ToastrService) { }
  fileToUpload: File = null;
loading = false;
  products$: Observable<any[]>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  media_domain = `${environment.media_domain}`;
  ngOnInit() {
  }
  download() {
    location.href = '/assets/delete.xlsx';
  }


  uploadExcel(e, files: FileList) {
    if (files[0].type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.toastr.error('Please upload excel sheet only');
      // alertify.error('Please upload image of '+imgWidth+'*'+imgHeight);
      this.fileToUpload = null;
$('#upload_file').val('');
      return false;
    } 
    var that = this;

    this.fileToUpload = files.item(0);

  }


  uploadExcelFile() {
    console.log(this.fileToUpload);
    if (this.fileToUpload) {
      var that = this;
      alertify.confirm('Are you sure want to upload file?', function () {
        that.loading = true;
        that.pro.deleteProduct(that.fileToUpload).subscribe(data => {
          that.loading = false;
          console.error(data.status.code);
          if (data.status.code == 0) {
            console.log(data);
            that.products$ = data.data.jawellery;
            that.toastr.success("Successfully uploaded");
            location.reload();
            that.pro.getProduct().subscribe(data => {
              that.products$ = data.data.jawellery;
              this.dtTrigger.next();
              
            }, error => {
              console.error(error);
            });
          } else {
            that.pro.getProduct().subscribe(data => {

              that.products$ = data.data.jawellery;

            }, error => {
              console.error(error);
            });
            console.error(data.status.message);
            that.toastr.error(data.status.message);
          }

        }, error => {
          console.error(error);
        });
      }, function () {
        that.toastr.error("Upload action cancelled");
      });
    } else {
      this.toastr.error('File is required');
    }
  }


  // deleteProduct() {
  //   var that = this;
  //   alertify.confirm('Are you sure want to upload file?', function () {
  //     that.pro.upload(that.fileToUpload).subscribe(data => {
  //       console.error(data.status.code);
  //       if (data.status.code == 0) {
  //         console.log(data);
  //       } else {
  //         console.error(data.status.message);
  //         that.toastr.error(data.status.message);
  //       }

  //     }, error => {
  //       console.error(error);
  //     });
  //   }, function () {
  //     that.toastr.error("Upload action cancelled");
  //   });
  // }

}
