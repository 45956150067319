import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class CategoryService {

  constructor(private http: HttpClient) { }

  getMainCat(): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/category/getMainCategory`).pipe(map(data => { return data; }));
  }


  save(name: string, status: string, image: File) {
    const formData: FormData = new FormData();
    formData.append('image', image, image.name);
    status = status ? '1' : '0';
    formData.append('active', status);
    formData.append('title', name);

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/category/mainCategory`, formData).pipe(map(user => {
      return user;
    }));

  }

  update(id: string, name: string, status: string, image: File) {
    const formData: FormData = new FormData();
    if (image) {
      formData.append('image', image, image.name);
    }
    status = status ? '1' : '0';
    formData.append('active', status);
    formData.append('title', name);

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/category/updateMainCategory/` + id, formData).pipe(map(user => {
      return user;
    }));

  }

  delete(id) {
    //deleteMainCategory
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/category/deleteMainCategory/` + id).pipe(map(data => { return data; }));
  }






  getSubCategory(): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/category/getSubCategory`).pipe(map(data => { return data; }));
  }


  saveSub(name: string, status: string, image: File, main_cat, main_cat_name, gst) {
    const formData: FormData = new FormData();
    if (image) {
      formData.append('image', image, image.name);
    }
    status = status ? '1' : '0';
    formData.append('active', status);
    formData.append('title', name);
    formData.append('mainCategory', JSON.stringify(main_cat));
    formData.append('mainCategoryObj', JSON.stringify(main_cat_name));


    gst = gst ? '1' : '0';

    formData.append('gst', gst);

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/category/subCategory`, formData).pipe(map(user => {
      return user;
    }));

  }

  updateSub(id: string, name: string, status: string, image: File, main_cat, main_cat_name, gst) {
    const formData: FormData = new FormData();
    console.error(image);
    if (image) {
      formData.append('image', image, image.name);
    }
    status = status ? '1' : '0';
    formData.append('active', status);
    formData.append('title', name);
    formData.append('mainCategory', JSON.stringify(main_cat));
    formData.append('mainCategoryObj', JSON.stringify(main_cat_name))

    gst = gst ? '1' : '0';

    formData.append('gst', gst);

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/category/updateSubCategory/` + id, formData).pipe(map(user => {
      return user;
    }));

  }

  deleteSub(id) {
    //deleteMainCategory
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/category/deleteSubCategory/` + id).pipe(map(data => { return data; }));
  }

  saveWebsite(id, amount, flat_r_per, amount_per, status, category) {
    const formData: FormData = new FormData();
    formData.append('amount', amount);
    formData.append('plat_or_percentage', flat_r_per);
    formData.append('p_or_per_amount', amount_per);
    formData.append('status', status);
    formData.append('category', JSON.stringify(category))

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/product/addWebsiteOffer/` + id, formData).pipe(map(user => {
      return user;
    }));
  }


  saveSpecialProduct(id, amount, flat_r_per, amount_per, status, category) {
    const formData: FormData = new FormData();
    formData.append('amount', amount);
    formData.append('plat_or_percentage', flat_r_per);
    formData.append('p_or_per_amount', amount_per);
    formData.append('status', status);
    formData.append('category', JSON.stringify(category))

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/abharana/addSpecial/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  getMail(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/abharana/mail`).pipe(map(user => {
      return user;
    }));
  }

  addMail(name, status, email) {
    const formData: FormData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('status', status ? '1' : '0');
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/abharana/addmail`, formData).pipe(map(user => {
      return user;
    }));
  }

  updateMail(id, name, status, email) {
    const formData: FormData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('status', status ? '1' : '0');
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/abharana/updatemail/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  deleteMail(id) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/abharana/deletemail/` + id).pipe(map(user => {
      return user;
    }));
  }



   //video-scheduler

   saveVideoScheduler(from: any, to: any) {
    const formData: FormData = new FormData();
    formData.append('from', from);
    formData.append('to', to);
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/add-block-time`, formData).pipe(map(user => {
      return user;
    }));
  }

  updateVideoScheduler(id:any,from: any, to: any) {
    const formData: FormData = new FormData();
    formData.append('from', from);
    formData.append('to', to);
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/edit-block-time/`+ id, formData).pipe(map(user => {
      return user;
    }));
  }

  getVideoSchedulerList(): Observable<any> {

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/view-block-time`, '').pipe(map(user => {
      return user;
    }));
  }


  deleteVideoScheduler(id) {
    //deleteMainCategory
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/deleteblocktime/` + id).pipe(map(data => { return data; }));
  }

  getSingleVideoScheduler(id: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/viewblocktime/` + id, '').pipe(map(user => {
      return user;
    }));
  }
}
