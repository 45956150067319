import { Component, OnInit } from '@angular/core';
import { ProductService, AlertService, BannersService, CategoryService, DeliveryService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Placeholder } from '@angular/compiler/src/i18n/i18n_ast';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';

declare var jquery: any; 
declare var $: any;
declare var alertify: any; 


@Component({
  selector: 'app-del-dist', 
  templateUrl: './del_dist.component.html',
  styleUrls: ['./del_dist.component.css']
})
export class DeliveryDistrictComponent implements OnInit {
  submitted: boolean;
  searchForm: FormGroup;
  constructor(private pro: ProductService, private _delivery:DeliveryService, private http: HttpClient, private toastr: ToastrService, private bannersService: CategoryService) { }
  fileToUpload: File = null;
  perPage = 10;

  loading=false;

  last_page=1;
  current_page=1;
  search_txt='';
  products$:any;

  selected_state = '-1';

  dist_link = '/assets/dist_del.xlsx'

  states=[];
  
  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
  

  ngOnInit() {
    this.refresh('',1);

    this._delivery.getStateDelivery('','',0).subscribe(data=>{
      this.states = data.data;
    },error=>{

    });
  }


  refresh(search,page){
    this._delivery.getDistDelivery(search,page,0).subscribe(data=>{
      this.products$ = data.data.data;
      this.last_page = data.data.last_page;
      this.current_page= data.data.current_page;
    },error=>{

    });
  }

  uploadExcel(e,file){
    this.fileToUpload=file[0];
  }


  uploadExcelFile(){
    this.submitted=true;
    if(this.selected_state=='-1'){
      return false;
    }
    if(!this.fileToUpload){
      this.toastr.error('File is required');
      return false;
    }
    this.loading=true;
    this._delivery.uploadDistrictExcel(this.fileToUpload,this.selected_state).subscribe(data=>{
      if(data.status.code==0){
        this.loading=false;
        this.refresh(this.search_txt,1);
        $('[type="file"]').val('');
        $('#state').val('-1')
      }else{
        this.loading=false;
        this.toastr.error(data.status.message);
        $('[type="file"]').val('');
        $('#state').val('-1')
      }
    },error=>{

    });
  }

  pageChange(page){
    this.refresh(this.search_txt,page)
  }

  search(val){
    this.search_txt = val;
    this.refresh(val,1)
  }


  deleteDist(obj){

    alertify.confirm('Are you sure you want to delete this district?',()=>{
      this.loading=true;
      this._delivery.deleteDist(obj._id).subscribe(data=>{
        this.loading=false;
        if(data.status.code==0){
          this.toastr.success(data.status.message);
          this.refresh(this.search_txt,1);
        }else{
          this.toastr.error(data.status.message);
        }
      },error=>{

      });
    },()=>{

    });
  }

  chooseState(e){
    this.selected_state = e.target.value;
  }


   downloadData(){
    // this.submitted = true;
    // this.loading = true;
    // const blob = await this._delivery.download(
    //  );
    // let binaryData = [];
    // binaryData.push(blob);
    // const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
    // 
    // const link = this.downloadZipLink.nativeElement;
    // link.href = url;
    // link.download = 'district.xlsx';
    // link.click();
  }

  download(){
    location.href=this.dist_link;
  }
}