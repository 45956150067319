import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { CategoryService, AlertService, ProductService, HomeService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
	selector: 'app-missed-orders',
	templateUrl: './missed-orders.component.html',
	styleUrls: ['./missed-orders.component.css']
})
export class MissedOrdersComponent implements OnInit {
	orders$: any[];
	orderss$: any[];
	loading = false;

	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<any> = new Subject();


	constructor(private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private bannersService: CategoryService,
		private alertService: AlertService,
		private route: ActivatedRoute,
		private router: Router,
		private home: HomeService,
		private pro: ProductService) { }
	type = '0';




	ngOnInit() {
this.loading = true;
		this.pro.getOrders(this.type).subscribe(data => {
			this.loading = false;
			console.log(data);
			this.orders$ = data.data;
			this.dtTrigger.next();
		}, error => {
			console.error(error);
		});

		this.dtOptions = {
			pagingType: 'full_numbers',
			processing: true,
			pageLength: 10,
			language: {
				searchPlaceholder: "Name"
			},
			columnDefs: [{
				searchable: false,
				orderable: false,
				targets: 0
			}]
			//order: [[ 1, 'asc' ]]
		};

	}


}