import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { identifierModuleUrl } from '@angular/compiler';

@Injectable()
export class ProductService {

  constructor(private http: HttpClient) { }

  getProduct(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/excel/get_products`).pipe(map(user => {
      return user;
    }));
  }

  getOfferProduct(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/abharana/getOfferProducts`).pipe(map(user => {
      return user;
    }));
  }
  upload(file: File) {
    const formData: FormData = new FormData();
    formData.append('excel', file);

    formData.append('offer_product', '1');

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/excel/uploadExcel`, formData).pipe(map(user => {
      return user;
    }));
  }



  uploadOfferProduct(file: File) {
    const formData: FormData = new FormData();
    formData.append('excel', file);

    formData.append('offer_product', '1');

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/excel/uploadProductOfferExcel`, formData).pipe(map(user => {
      return user;
    }));
  }



  editProduct(
    id,
    sku,
    p_name,
    c_grades,
    weight,
    q_weight,
    purity,
    metal,

    m_charges,

    mc_per_gram,
    cents,
    daimond_value,
    stone_details,
    cost,
    detailed_desc,
    max_shipping_days,
    certifications,
    cut,
    size,

    lock_type,
    lace,
    lace_cost,
    chain_weight,
    chain_purity,
    chain_metal,
    chain_cost,
    chain_making_charges_percentage,
    chain_making_charges,
    chain_certification_charges,

    color,
    clarity,
    collection,
    occassion,
    mainCat,
    subCat,

    desc,
    stone_weight,
    stone_value,

    other_stone,
    other_tags,
    note,
    men,
    women,
    kids,
    stock,
    image,

    cer_charges,
    making_type,
    include_backchain,
  ) {

    const formData: FormData = new FormData();
    formData.append('sku', sku);
    formData.append('p_name', p_name);
    formData.append('c_grades', c_grades);
    formData.append('weight', weight);
    formData.append('q_weight', q_weight);
    formData.append('purity', purity);
    formData.append('metal', metal);

    formData.append('m_charges', m_charges);

    formData.append('mc_per_gram', mc_per_gram);
    formData.append('cents', cents);
    formData.append('daimond_value', daimond_value);
    formData.append('stone_details', stone_details);
    formData.append('cost', cost);
    formData.append('desc', detailed_desc);
    formData.append('max_shipping_days', max_shipping_days);
    formData.append('certifications', certifications);
    formData.append('cut', cut);
    formData.append('size', size);

    formData.append('lock_type', lock_type);
    formData.append('lace', lace);
    formData.append('lace_cost', lace_cost);

    formData.append('chain_weight', chain_weight);
    formData.append('chain_purity', chain_purity);
    formData.append('chain_metal', chain_metal);
    formData.append('chain_cost', chain_cost);
    formData.append('chain_making_charges_percentage', chain_making_charges_percentage);
    formData.append('chain_making_charges', chain_making_charges);
    formData.append('chain_certification_charges', chain_certification_charges);

    formData.append('color', color);
    formData.append('clarity', clarity);
    formData.append('collection', collection);
    formData.append('occassion', occassion);
    formData.append('mainCat', mainCat);
    formData.append('subCat', subCat);

    formData.append('detailed_desc', desc);

    formData.append('stone_weight', stone_weight);
    formData.append('stone_value', stone_value);

    formData.append('other_stone', other_stone);
    formData.append('other_tags', other_tags);
    formData.append('note', note);

    formData.append('men', men ? '1' : '0');
    formData.append('women', women ? '1' : '0');
    formData.append('kids', kids ? '1' : '0');
    formData.append('stock', stock ? '1' : '0');

    formData.append('certification_charges', cer_charges);
    formData.append('making_type', making_type);

    if (image) {
      formData.append('image', image);
    }
    formData.append('include_backchain', include_backchain);
    //detailed_desc

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/product/updateProduct/` + id, formData).pipe(map(user => {
      return user;
    }));


  }

  getAllimage(id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/product/get_pro_images/` + id).pipe(map(user => {
      return user;
    }));
  }

  getsinglePro(id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/product/singleProduct/` + id).pipe(map(user => {
      return user;
    }));
  }

  uploadProductImage(images: File[]) {
    var ary = [];
    var ary1 = [];
    var i = 0;
    var j = 0;
    const formData: FormData = new FormData();

    images.map(image => {
      formData.append('image' + i++, image, image.name);
    });
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/product/upload_images`, formData).pipe(map(user => {
      return user;
    }));
  }


  uploadProductVideo(video: File[]) {
    var ary = [];
    var ary1 = [];
    var i = 0;
    var j = 0;
    const formData: FormData = new FormData();

    video.map(video => {
      formData.append('video' + j++, video, video.name);
    });

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/product/upload_videos`, formData).pipe(map(user => {
      return user;
    }));
  }

  getProImage(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/product/get_pro_images`).pipe(map(user => {
      return user;
    }));
  }

  getProImage1(params:any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/product/get_pro_images1`, params).pipe(map(user => {
      return user;
    }));
    // return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/product/get_pro_images1`).pipe(map(user => {
    //   return user;
    // }));
  }

  getFeedback(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/customer/getFeedback`).pipe(map(user => {
      return user;
    }));
  }

  getReport(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/website/get-report-problem`).pipe(map(user => {
      return user;
    }));
  }

  getEnquiry(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/product/getEnquirie`).pipe(map(user => {
      return user;
    }));
  }


  addPromo(coupon_code, mobile, amount, status, category, coupon_type, mainCat) {
    const formData: FormData = new FormData();
    formData.append('coupon_code', coupon_code);
    formData.append('mobile', mobile);
    formData.append('status', status);
    formData.append('amount_or_percent', amount);
    formData.append('coupon_type', coupon_type);

    if (mainCat == 'All') {
      formData.append('all', '1');
    } else {
      formData.append('mainCat', mainCat._id);
    }

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/promocode/addPromocode`, formData).pipe(map(user => {
      return user;
    }));
  }

  deleteSinglePro(id) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/abharana/deleteSingleProduct/` + id).pipe(map(user => {
      return user;
    }));
  }



  updatePromo(id, coupon_code, mobile, amount, status, category, coupon_type, mainCat) {
    const formData: FormData = new FormData();
    formData.append('coupon_code', coupon_code);
    formData.append('mobile', mobile);
    formData.append('status', status);
    formData.append('amount_or_percent', amount);
    formData.append('coupon_type', coupon_type);

    if (mainCat == 'All') {
      formData.append('all', '1');
    } else {
      formData.append('mainCat', mainCat._id);
    }

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/promocode/updatePromocode/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  getPromo(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/promocode/getPromocode`).pipe(map(user => {
      return user;
    }));
  }

  getPrivacy(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/privacy/getPrivacy`).pipe(map(user => {
      return user;
    }));
  }

  submitPrivacy(id, description, terms) {
    const formData: FormData = new FormData();
    formData.append('description', description);
    formData.append('terms', terms);
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/privacy/updatePrivacy/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  addremarketing(description) {
    const formData: FormData = new FormData();
    formData.append('metatag', description);
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/addmarketing`, formData).pipe(map(user => {
      return user;
    }));
  }

  updateremarketing(id, description) {
    const formData: FormData = new FormData();
    formData.append('metatag', description);
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/updatemarketing/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  getremarketing(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/viewmarketing`).pipe(map(user => {
      return user;
    }));
  }

  deleteRemark(id) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/deletemarketing/` + id).pipe(map(user => {
      return user;
    }));
  }


  addbackchain(image: File, laceimage: File) {
    const formData: FormData = new FormData();
    if (image) {
      formData.append('image', image, image.name);
    }

    if (laceimage) {
      formData.append('laceimage', laceimage, laceimage.name);
    }

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/addchaintype`, formData).pipe(map(user => {
      return user;
    }));
  }

  updatebackchain(id: any, image: File, laceimage: File) {
    const formData: FormData = new FormData();

    if (image) {
      formData.append('image', image, image.name);
    }
    if (laceimage) {
      formData.append('laceimage', laceimage, laceimage.name);
    }

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/updatechaintype/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  deleteBackchain(id) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/chaintype/` + id).pipe(map(user => {
      return user;
    }));
  }

  getChaintype(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/chaintype`).pipe(map(user => {
      return user;
    }));
  }

  deletePromocode(id) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/promocode/deletePromocode/` + id).pipe(map(user => {
      return user;
    }));
  }

  getWebsite(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/product/getWebsite`).pipe(map(user => {
      return user;
    }));
  }

  deleteProduct(file: File) {
    const formData: FormData = new FormData();
    formData.append('excel', file);
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/excel/deleteProduct`, formData).pipe(map(user => {
      return user;
    }));
  }


  getOrders(type) {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/product/getOrders/` + type).pipe(map(user => {
      return user;
    }));
  }

  downloadPdf(id) {
    // const params = new HttpParams().set('id', id);
    const formData = new FormData();
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/print/printOrder/` + id, formData).pipe(map(user => {
      return user;
    }));
  }


  sendMail() {
    // const params = new HttpParams().set('id', id);
    const formData = new FormData();
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/mail`, formData).pipe(map(user => {
      return user;
    }));
  }


  getSpecialProduct() {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/abharana/getSpecialOffer`).pipe(map(user => {
      return user;
    }));
  }
  getSchemes() {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/scheme`).pipe(map(user => {
      return user;
    }));
  }

  deleteScheme(id: string) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/scheme/` + id).pipe(map(user => {
      return user;
    }));
  }

  deleteImage(id: string) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/product/delete-image/` + id).pipe(map(user => {
      return user;
    }));
  }


  uploadScheme(scheme: File) {
    const formData: FormData = new FormData();
    formData.append('scheme', scheme);


    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/scheme`, formData).pipe(map(user => {
      return user;
    }));
  }

  product_download(stock_filter, sku_filter, main_cat_filter, sub_cat_filter): Promise<Blob> {
    var formData = {};
    formData['stock_filter'] = stock_filter;
    formData['sku_filter'] = sku_filter;
    formData['main_cat_filter'] = main_cat_filter;
    formData['sub_cat_filter'] = sub_cat_filter;

    formData['download'] = 1;

    const file = this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/excel/product_download?` + $.param(formData),
      { responseType: 'blob' as 'json' }).toPromise();
    return file;
  }

  upload_out_stock(upload: File, outstock: any) {
    const formData: FormData = new FormData();
    formData.append('excel', upload);
    if (outstock) {
      outstock = 'outstock';
    } else {
      outstock = 'instock'
    }
    formData.append('outstock', outstock);
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/excel/outofstock`, formData).pipe(map(user => {
      return user;
    }));
  }



  makeOrderConfirm(id) {
    var formData = {};
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/order/update-confirm-order/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  //approved transaction

  getApprovedTranscation(page:any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/trans-detail?page=`+page).pipe(map(user => {
      return user;
    }));
  }




  main(year: any,page:any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/analytics/allmain?Year=` + year + '&page=' + page, '').pipe(map(user => {
      return user;
    }));
  }

  prod(year: any,page:any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/analytics/allprod?Year=` + year + '&page=' + page, '').pipe(map(user => {
      return user;
    }))
  }

  sub(year: any,page:any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/analytics/allsub?Year=` + year +  '&page=' + page, '').pipe(map(user => {
      return user;
    }))
  }

  // main(year: any,month:any,page:any): Observable<any> {
  //   return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/analytics/allmain?Year=` + year + '&Month=' + month + '&page=' + page, '').pipe(map(user => {
  //     return user;
  //   }));
  // }

  // prod(year: any,month:any,page:any): Observable<any> {
  //   return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/analytics/allprod?Year=` + year + '&Month=' + month + '&page=' + page, '').pipe(map(user => {
  //     return user;
  //   }))
  // }

  // sub(year: any,month:any,page:any): Observable<any> {
  //   return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/analytics/allsub?Year=` + year + '&Month=' + month + '&page=' + page, '').pipe(map(user => {
  //     return user;
  //   }))
  // }








  ApprovedTranscation(id: any, status: any) {
    const formData: FormData = new FormData();
    // formData.append('_id', id);
    formData.append('order_status', status);
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/status-update/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  Filter(Month: any, Year: any) {
    const formData: FormData = new FormData();
    formData.append('Month', Month);
    formData.append('Year', Year);
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/analytics/get_main_cat_count/`, formData).pipe(map(user => {
      return user;
    }));
  }

  getUserfeedback(page:any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/getfeedbackuser?page=` + page).pipe(map(user => user));
  }


  getSingleorder(id:any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/getOrdersingle/`+ id).pipe(map(user => user));
  }


  updateOrderview(id: any, extra_weight: any, amount:any, payment_link:any) {
    const formData: FormData = new FormData();
    formData.append('extra_weight', extra_weight);
    formData.append('amount', amount);
    formData.append('payment_link', payment_link);
  
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/updateOrder/` + id, formData).pipe(map(user => {
      return user;
    }));
  }


 

}
