<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Remarketing Ads</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Remarketing Ads</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row remarketing_main">
            <div class="col-md-6">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <!-- <header class="panel-heading">
                        <h3 class="panel-title">
                            Remarketing Ads
                        </h3>
                    </header> -->
                    <div class="panel-body">
                        <div class="row">
                            <form [formGroup]="privacyForm" (ngSubmit)="privacySubmit()">
                                <div class="col-md-12">
                                    <!-- <label>Terms & policy</label>
                                    <select formControlName="terms" class="form-control" disabled>
                                        <option></option>
                                        <option>Terms</option>
                                        <option>Policy</option>
                                       <option>Certified jewellery</option>
                                        <option>Shipping</option>
                                    </select> -->
                                    <label>Description</label>
                                    <!--  <angular-editor formControlName="description" id="editor2" [(ngModel)]="htmlContent"
                                        aria-placeholder="Enter text here..." [config]="editorConfig">
                                    </angular-editor> -->
                                    <angular-editor id="editor2" aria-placeholder="Enter text here..."
                                        formControlName="description" [config]="config"></angular-editor>
                                    <button type="submit" class="btn btn-info mt-4" *ngIf="!id">Submit</button>
                                    <!-- <ng-container *ngIf="cancelButton"> -->
                                    <button type="submit" class="btn btn-info mt-4" *ngIf="id">Update</button>
                                    <button type="button" class="btn btn-danger mt-4 ml-2"
                                        (click)="cancelClick()">Cancel</button>
                                    <!-- </ng-container> -->
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 new_s">
                <div class="panel">
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="tb-wrapper">
                                    <div class="table-responsive">
                                        <table cellspacing="0" id="table"
                                            class="table table-hover dataTable table-striped table-bordered remarketing_table w-full"
                                            datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                            <thead>
                                                <tr>
                                                    <th width="8%">Sl No.</th>
                                                    <th width="50%">Description</th>
                                                    <th width="10%">Send Mail</th>
                                                    <th width="10%">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let privacy of privacy$;let j=index">


                                                    <td>{{j+1}}</td>
                                                    <td>
                                                        <!-- <span [innerHTML]="privacy.metatag"></span> -->
                                                        {{privacy.metatag}}
                                                    </td>
                                                    <button class="btn btn-info mt-4 remarket_sendbtn" (click)="mailSend($event)">
                                                        Send Mail
                                                    </button>
                                                    <td>
                                                        <i style="cursor: pointer;" class="fa fa-edit text-success"
                                                            (click)="editPrivacy(privacy)" aria-hidden="true"></i>

                                                        <i style="cursor: pointer;" class="fa fa-trash text-error"
                                                            (click)="deleteRem(privacy)" aria-hidden="true"></i>
                                                    </td>


                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>