import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AlertService, ModalService } from '../_services';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from '../_services/PaymentService';
import { HttpClient } from '@angular/common/http';
declare var jquery: any;
declare var $: any;
declare var alertify: any;
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {


  loading = false;
  user_details$ : any;
  payment_details$ : any;

  submitted = false;
  //table = false;

  cancelButton = false;
  payments$: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  width_limit = `${environment.bannerWidth}`;
  height_limit = `${environment.bannerHeight}`;
  curPage = 1;
  perPage = 10;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private paymentService: PaymentService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private router: Router,
    private http: HttpClient
  ) { }

  ngOnInit() {


    const that = this;


    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "User name / Plan"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };

    this.paymentService.getPayment().subscribe(data => {
      this.payments$ = data.data.payment;
      this.dtTrigger.next();
    });


  }

  closeModal(id: string) {

    this.modalService.close(id);

  }

  userDetails(user) {
if(typeof user.payment != 'undefined'){
    this.payment_details$ = user.payment;
}
if(typeof user.user != 'undefined'){
  this.user_details$ = user.user;
}
console.log(this.payment_details$);



    this.modalService.open('user_details');
  }

  // convenience getter for easy access to form fields
  // get f() { return this.GenreForm.controls; }


  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }


}
