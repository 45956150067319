import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { BannersService, AlertService, LanguageService, QualificationService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-qualifications',
  templateUrl: './qualifications.component.html',
  styleUrls: ['./qualifications.component.css']
})
export class QualificationsComponent implements OnInit {

  bannerForm: FormGroup;
  loading = false;


  submitted = false;
  cancelButton = false;
  qualifications$: Observable<any[]>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  curPage = 1;
  perPage = 10;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private qualificationService: QualificationService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    this.bannerForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      _id: [{ value: null, disabled: true }],
      name: ['', [Validators.required, Validators.maxLength(500)]],

    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: 'Title'
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };

    this.qualificationService.getQualifications().subscribe(data => {
      this.qualifications$ = data.data.qualification;
      console.log(data.data.qualification)
      this.dtTrigger.next();
    });

  }


  // convenience getter for easy access to form fields
  get f() { return this.bannerForm.controls; }

  onSubmit(form, formWrap) {
    this.submitted = true;
    let id = form.getRawValue()._id;
    let index = form.getRawValue().index;


    // stop here if form is invalid
    if (this.bannerForm.invalid) {
      return;
    }


    if (id != null) {
      document.body.classList.add('jw-modal-open');
      this.loading = true;
      this.qualificationService.update(id, this.f.name.value)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              this.submitted = false;
              document.body.classList.remove('jw-modal-open');
              this.loading = false;
              this.cancelButton = false;
              // document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
              // this.users$[index] = data.data.banner;
              this.bannerForm.setValue({
                index: null,
                _id: null,
                name: '',

              })
              formWrap.reset();


              //this.router.navigate(['/banners']);
              location.href = "/qualifications";

            } else {
              document.body.classList.remove('jw-modal-open');
              this.loading = false;
              this.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            this.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
            document.body.classList.remove('jw-modal-open');
            this.loading = false;
          });
    } else {
      document.body.classList.add('jw-modal-open');
      this.loading = true;
      this.qualificationService.save(this.f.name.value)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              this.submitted = false;
              document.body.classList.remove('jw-modal-open');
              this.loading = false;
              this.cancelButton = false;
              location.reload();

              //this.users$.unshift(data.data.banner)

              this.bannerForm.setValue({
                index: null,
                _id: null,
                name: '',

              })
              formWrap.reset();


              //this.router.navigate(['/banners']);
              location.href = "/qualifications";

            } else {
              document.body.classList.remove('jw-modal-open');
              this.loading = false;
              this.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            this.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
            document.body.classList.remove('jw-modal-open');
            this.loading = false;
          });
    }
    //this.bannerForm.reset() // reset form to empty


  }


  qualificationEdit(event, qualification, i) {

    this.cancelButton = true;
    //this.table = event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
    document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
    this.bannerForm.setValue({
      index: i,
      _id: qualification._id,
      name: qualification.title,


    })

  }

  cancelSubmit(event) {

    this.cancelButton = false;
    document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
    this.bannerForm.setValue({
      index: null,
      _id: null,
      name: '',

    })
    this.submitted = false;
  }
  qualificationDelete(event, language, i) {
    var that = this;
    alertify.confirm('Are you sure want to delete this record', function () {
      that.qualificationService.delete(language._id)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.toastr.success('Deleted Successfully')
              // alertify.success('Deleted Successfully')
              setTimeout(function () {
                location.reload();
              }, 1000);
              //that.users$.splice(i, 1);
            } else {
              that.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            that.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
          });
    }
      , function () { //alertify.error('Delete action cancelled')
        that.toastr.error('Delete action cancelled')

      });

  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }
}
