import { Component, OnInit } from '@angular/core';
import { BannersService, AlertService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';

declare var jquery: any;
declare var $: any;
declare var alertify: any;



@Component({
  selector: 'app-meta',
  templateUrl: './meta.component.html',
  styleUrls: ['./meta.component.css']
})


export class MetaComponent implements OnInit {

  metaForm: FormGroup;
  meta$: Observable<any[]>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  loading = false;
  submitted = false;
  cancelButton = false;

  perPage = 10;
  constructor(
    private service: BannersService,
    private alert: AlertService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
  ) { }

  ngOnInit() {



    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   processing: true,
    //   pageLength: 10,
    //   language: {
    //     searchPlaceholder: "Meta title"
    //   },
    //   columnDefs: [{
    //     searchable: false,
    //     orderable: false,
    //     targets: 0
    //   }]
    //   //order: [[ 1, 'asc' ]]
    // };



    var that = this;
    that.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: that.perPage,

      serverSide: true,
      searching: true,
      ordering: true,
      language: {
        searchPlaceholder: "Search Meta title"
      },
      order: [],
      columnDefs: [{
        "targets": [],
        "orderable": false
      }],
      lengthChange: false,


      ajax: (dataTablesParameters: any, callback) => {
        var that = this;
        that.http.post<any>(
          `${environment.apiUrl}${environment.apiPrefix}/getMeta`,
          dataTablesParameters, {}
        ).subscribe(resp => {

          that.meta$ = resp.data.data;

          //$('.custom-filters').appendTo('.dataTables_filter').show();


          callback({
            recordsTotal: resp.data.total,
            recordsFiltered: resp.data.total,
            data: []
          });
        });

      }
    };



    // this.service.getMeta().subscribe(data => {
    //   console.log(data);
    //   this.meta$ = data.data;
    //   this.dtTrigger.next();
    // }, error => {
    //   console.error(error);
    // });

    this.metaForm = this.formBuilder.group({
      id: [''],
      url: ['', [Validators.required]],
      title: ['', [Validators.required]],
      desc: ['', [Validators.required]],
      keyword: ['', [Validators.required]]
    });

    // setTimeout(function () {
    //   $(function () {
    //     $('#table').DataTable();
    //   });
    // }, 100);
  }

  //, Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)

  get f() { return this.metaForm.controls }
  metaSubmit() {
    console.log(this.metaForm);
    this.submitted = true;
    if (this.metaForm.invalid) {
      return false;
    }
    var that = this;

    if (this.f.id.value) {
      alertify.confirm('Are you sure want to update?', function () {
        that.loading = true;
        that.service.updateMeta(that.f.id.value, that.f.url.value, that.f.title.value, that.f.desc.value, that.f.keyword.value).subscribe(data => {
          console.log(data);
          location.reload();
        }, error => {
          console.error(error);
        });
      }
        , function () {
          that.toastr.error('Update action cancelled')

        });
    } else {
      alertify.confirm('Are you sure want to submit?', function () {
        that.loading = true;
        that.service.addMeta(that.f.url.value, that.f.title.value, that.f.desc.value, that.f.keyword.value).subscribe(data => {
          console.log(data);
          location.reload();
        }, error => {
          console.error(error);
        });
      }
        , function () {
          that.toastr.error('Submit action cancelled')

        });
    }
  }


  deleteMeta(id) {
    var that = this;
    alertify.confirm('Are you sure want to delete?', function () {
      that.loading = true;
      that.service.metaDelete(id).subscribe(data => {
        console.log(data);
        // var table = $('#table').DataTable();
        // table.draw();
        location.reload();
      }, error => {
        console.error(error);
      })
    }
      , function () {
        that.toastr.error('Delete action cancelled')

      });
  }

  cancel() {
    $('.pro').removeClass('disable');
    this.cancelButton = false;
    this.metaForm.patchValue({
      id: '',
      url: '',
      title: '',
      desc: '',
      keyword: ''

    });
  }

  editMeta(met) {
    $('.pro').addClass('disable');
    this.cancelButton = true;
    this.metaForm.patchValue({
      id: met._id,
      url: met.url,
      title: met.meta_title,
      desc: met.desc,
      keyword: met.meta_keyword

    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    if ($.fn.dataTable.isDataTable('#table')) {
      var table = $('#table').DataTable();
      $('[type="search"]').attr('placeholder', 'Search Metatitle');
    }
  }



}


