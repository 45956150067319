import { Component, OnInit } from '@angular/core';
import { ProductService, AlertService, BannersService, CategoryService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';


declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-scheem',
  templateUrl: './scheem.component.html',
  styleUrls: ['./scheem.component.css']
})
export class ScheemComponent implements OnInit {
  constructor(private pro: ProductService, private toastr: ToastrService, private bannersService: CategoryService) { }
  fileToUpload: File = null;

  schemes$: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  media_domain = `${environment.media_domain}`;
  product_excel = '/product/excel/5f2cda916e5aa.xls';
  weblink = `${environment.WEBLINK}`;

  sub$: any[];
  main$: any[];


  subCategory = {

    displayKey: 'title', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select main category', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No main category found', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search main category',
    padding: '15px', // label thats displayed in search input,


    selectAllBtnText: 'Select',
    searchOnKey: 'title' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys

    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  mainCat = {

    displayKey: 'title', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select main category', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No main category found', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search main category',
    padding: '15px', // label thats displayed in search input,


    selectAllBtnText: 'Select',
    searchOnKey: 'title' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys

    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };


  ngOnInit() {

    localStorage.setItem('currentUrl', 'schemes');

    //getProduct
    this.pro.getSchemes().subscribe(data => {
      console.log(data);
      this.schemes$ = data.data.schemes;
      console.log(data.data.schemes)
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });


    


    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Shceme Number/User name"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };
  }

  uploadExcel(e, files: FileList) {
    var that = this;
    if (files[0].type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      that.toastr.error('Please upload excel sheet only');
      // alertify.error('Please upload image of '+imgWidth+'*'+imgHeight);
      that.fileToUpload = null;
    $('#upload_file').val('');
      return false;
    } 
    that.fileToUpload = files.item(0);

  }

  deleteScheme(e,id){
    var that = this;
   
    
    alertify.confirm('Are you sure want to delete this record?', function() {
    that.pro.deleteScheme(id).subscribe(data => {
    if(data.status.code==0){
      that.toastr.success(data.status.message);
      // that.schemes$ = that.schemes$.filter(item => item._id !== id);
      // var table = $('#table').DataTable();
      // table.draw();
    location.reload();
    }else{
      that.toastr.error(data.status.message);
    }
    }, error => {
      console.error(error);
    });
    },function(){
      that.toastr.error('Action Cancelled');
    });
  }

  uploadExcelFile() {

    if (this.fileToUpload) {
      var that = this;
      alertify.confirm('Are you sure want to upload file?', function () {
        that.pro.uploadScheme(that.fileToUpload).subscribe(data => {
          console.error(data.status.code);
          if (data.status.code == 0) {
            console.log(data);
            //that.products$ = data.data.jawellery;
            that.toastr.success("Successfully uploaded");
            location.reload();
          } else {
            console.error(data.status.message);
            that.toastr.error(data.status.message);
          }

        }, error => {
          console.error(error);
        });
      }, function () {
        that.toastr.error("Upload action cancelled");
      });
    } else {
      this.toastr.error('File is required');
    }
  }


  download() {
    location.href = '/assets/scheme.xlsx';
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }

  selectmainCategory(e) {
    console.log(e);
  }

  selectsubCategory(e) {

  }


}
