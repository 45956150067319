import { Component, OnInit } from '@angular/core';
import { ProductService, AlertService, CategoryService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';


declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-product-offer',
  templateUrl: './product-offer.component.html',
  styleUrls: ['./product-offer.component.css']
})
export class ProductOfferComponent implements OnInit {

  constructor(private pro: ProductService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private cat: CategoryService
  ) { }

  websiteProduct$: Observable<any[]>;

  webForm: FormGroup;
  sub$: Observable<any[]>;

  submitted = false;
  loading = false;

  subCategory = {

    displayKey: 'title', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select sub category', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No sub category found', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search sub category',
    padding: '15px', // label thats displayed in search input,


    selectAllBtnText: 'Select',
    searchOnKey: 'title' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys

    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  status: any;

  ngOnInit() {

    this.webForm = this.formBuilder.group({
      id: [''],
      amount: [''],
      status: ['1'],
      flat_or_per: ['1'],
      amount_per: [''],
      sub_cat: [''],
    });

    // $(document).on('click', '[name="edit-offer"]', function () {
    //   $(this).closest('tr').find('[name="edit-offer"]').addClass('hidden');
    //   $(this).closest('tr').find('[name="edit-save"], [name="edit-cancel"]').removeClass('hidden');
    //   var offer_id = $(this).closest('tr').find('[name="offer_id"]').val().trim();
    //   var status = $(this).closest('tr').find('td:nth-child(3) span[name=offer_status]').html().trim();
    //   var amount = $(this).closest('tr').find('td:nth-child(2) span').html().trim();

    //   var input = $('<select class="form-control" name="offer_status" ><option value="0">Inactive</option><option value="1">Active</option></select>');
    //   var amnt = $('<input class="form-control" name="offer_amount" type="text" value="' + amount + '" /><input class="form-control" name="offer_id" type="hidden" value="' + offer_id + '" />');

    //   var flat = $('<input class="form-control" name="offer_amount" type="text" value="" /><input class="form-control" name="offer_id" type="hidden" value="" />');

    //   var drop = $('<select class="form-control" name="offer_status" ><option value="0">Inactive</option><option value="1">Active</option></select>');

    //   $(this).closest('tr').find('td:nth-child(3)').html(input);
    //   $(this).closest('tr').find('td:nth-child(2)').html(amnt);
    //   $(this).closest('tr').find('td:nth-child(5)').html(flat);
    //   $(this).closest('tr').find('td:nth-child(4)').html(drop);
    //   $(this).closest('tr').find('td:nth-child(3)').find('[name=offer_status]').val(status);


    // });

    this.pro.getWebsite().subscribe(data => {
      console.log(data);
      this.websiteProduct$ = data.data;
      this.status = data.data.status;
    }, error => {
      console.error(error);
    });

    this.cat.getSubCategory().subscribe(data => {
      console.log(data);
      this.sub$ = data.data.main;
    }, error => {
      console.error(error);
    });




  }


  get m() { return this.webForm.controls; }
  webSubmit() {
    this.submitted = true;
    console.log(this.webForm);
    var sub_category = [];
    for (let sub of this.webForm.value.sub_cat) {
      sub_category.push(sub._id);
    }
    console.log(sub_category);

    if (this.webForm.invalid) {
      return false;
    }

    var that = this;
    alertify.confirm('Are you sure want to submit?', function () {
      that.loading = true;
      that.cat.saveWebsite(
        that.m.id.value,
        that.m.amount.value,
        that.m.flat_or_per.value,
        that.m.amount_per.value,
        that.m.status.value,
        sub_category
      ).subscribe(data => {
        that.loading = false;
        console.log(data);
        location.reload();
      }, error => {
        console.error(error);
      })
    }, function () {
      that.toastr.error('Action cancelled');
    });

  }


  selectSub(e) {

  }




  edit(e, n, web) {

    for (var y = 0; y < 3; y++) {
      console.log(y == n);
      if (y == n) {
        $('#amount' + n).attr('hidden', false);
        $('#amount_enter' + n).hide();

        $('#status_text' + n).hide();
        $('#status' + n).attr('hidden', false);

        $('#percentage_text' + n).hide();
        $('#percentage' + n).attr('hidden', false);

        $('#percentage_amt' + n).attr('hidden', false);
        $('#select_pre' + n).hide();

        $('#cat_text' + n).hide();
        $('#cat_select' + n).attr('hidden', false);

        $('#edit-save' + n).removeClass('hidden');
        $('#edit-cancel' + n).removeClass('hidden');

        $('#edit-offer' + n).addClass('hidden');
        console.log(e);

        $('#sub' + n).hide();
        $('#sub_drop' + n).attr('hidden', false);
      } else {
        $('#amount' + y).attr('hidden', true);
        $('#amount_enter' + y).show();

        $('#status_text' + y).show();
        $('#status' + y).attr('hidden', true);

        $('#percentage_text' + y).show();
        $('#percentage' + y).attr('hidden', true);

        $('#percentage_amt' + y).attr('hidden', true);
        $('#select_pre' + y).show();

        $('#cat_text' + y).show();
        $('#cat_select' + y).attr('hidden', true);

        $('#edit-save' + y).addClass('hidden');
        $('#edit-cancel' + y).addClass('hidden');

        $('#edit-offer' + y).removeClass('hidden');

        $('#sub' + y).show();
        $('#sub_drop' + y).attr('hidden', true);

      }
    }

    console.log(web);

    //$('tr').toggle();

    this.webForm.patchValue({
      id: web._id,
      amount: web.amount,
      status: web.status,
      flat_or_per: web.plat_or_percentage,
      amount_per: web.p_or_per_amount,
      sub_cat: web.subcategory,

    });
    this.webForm.controls.amount_per.setValidators([Validators.required]);
    this.webForm.controls.amount_per.updateValueAndValidity();
    // this.webForm.controls.sub_cat.setValidators([Validators.required]);
    // this.webForm.controls.sub_cat.updateValueAndValidity();

    this.webForm.controls.amount.setValidators([Validators.required]);
    this.webForm.controls.amount.updateValueAndValidity();

  }

  cancel(e, n) {
    $('#amount' + n).attr('hidden', true);
    $('#amount_enter' + n).show();

    $('#status_text' + n).show();
    $('#status' + n).attr('hidden', true);

    $('#percentage_text' + n).show();
    $('#percentage' + n).attr('hidden', true);

    $('#percentage_amt' + n).attr('hidden', true);
    $('#select_pre' + n).show();

    $('#cat_text' + n).show();
    $('#cat_select' + n).attr('hidden', true);

    $('#edit-save' + n).addClass('hidden');
    $('#edit-cancel' + n).addClass('hidden');

    $('#edit-offer' + n).removeClass('hidden');

    $('#sub' + n).show();
    $('#sub_drop' + n).attr('hidden', true);
  }

}
