<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
    <div class="page-header">
        <h1 class="page-title">Customer Feedback</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active">Customer Feedbacks</li>
        </ol>
    </div>
</div>
<div class="container-fluid">

    <div class="panel">
        <div class="panel-body">
            <div class="row">
                <!-- <div class="col-md-9">
            <h4 class="ng-binding">Customer Feedbacks ({{length}})</h4>
        </div> -->
                <!-- <div class="col-md-3 form-inline">
            <label>Search: </label>
            <input type="text" name="email" autocomplete="off" class="form-control"
                placeholder="Email">
        </div> -->
            </div>
            <div class="row feedback_section">
                <div class="col-md-12">

                    <div class="table-responsive feedback_main">
                        <!-- <h3>Customer Feedbacks</h3> -->

                        <!--   <table class="table table-bordered"> -->
                        <table cellspacing="0" id="table"
                            class="table table-hover dataTable table-striped table-bordered feedback_table w-full"
                            datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr style="font-weight: bold;">
                                    <th width="50px;">Sl. No.</th>
                                    <th >NAME</th>
                                    <th width="200px;">MOBILE </th>
                                    <th width="250px;">EMAIL </th>
                                    <th width="200px;">MESSAGE</th>
                                    <th width="200px;">CREATED ON</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let feed of feedback$;let n=index">
                                    <td>{{n+1}}</td>
                                    <td>{{feed.name}}</td>
                                    <td>{{feed.mobile}}</td>
                                    <td>{{feed.email}}</td>
                                    <td><a style="color:#337ab7;" data-toggle="modal"
                                            (click)="clickFeedback(feed.message)" class="pop"
                                            data-target="#exampleModalCenter">{{feed.message}}</a></td>
                                    <td>{{feed.created_at}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<div class="modal fade feedback_modal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                Message
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <span class="message_text"></span>
            </div>
        </div>
    </div>
</div>
