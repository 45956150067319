import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class SettingsService {
    constructor(private http: HttpClient) { }

    save(username: string, is_reset: string, password: string, new_pass: string) {
        const formData: FormData = new FormData();

        formData.append('username', username);
        formData.append('password', password);
        if (is_reset) {
            formData.append('new_password', new_pass);
        }

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/oauth/update-password`, formData).pipe(map(user => {
            return user;
        }));

    }
}