import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class VideoService {

  constructor(private http: HttpClient) { }

//   getProduct(): Observable<any> {
//     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/excel/get_products`).pipe(map(user => {
//       return user;
//     }));
//   }

//   getOfferProduct(): Observable<any> {
//     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/abharana/getOfferProducts`).pipe(map(user => {
//       return user;
//     }));
//   }
//   upload(file: File) {
//     const formData: FormData = new FormData();
//     formData.append('excel', file);

//     formData.append('offer_product', '1');

//     return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/excel/uploadExcel`, formData).pipe(map(user => {
//       return user;
//     }));
//   }

//   uploadOfferProduct(file: File) {
//     const formData: FormData = new FormData();
//     formData.append('excel', file);

//     formData.append('offer_product', '1');

//     return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/excel/uploadProductOfferExcel`, formData).pipe(map(user => {
//       return user;
//     }));
//   }



  editVideo(
    // id,
    // sku,
    // p_name,
    // c_grades,
    // weight,
    // q_weight,
    // purity,
    // metal,
    
    // m_charges,
 
    // mc_per_gram,
    // cents,
    // daimond_value,
    // stone_details,
    // cost,
    // detailed_desc,
    // max_shipping_days,
    // certifications,
    // cut,
    // size,
    // color,
    // clarity,
    // collection,
    // occassion,
    // mainCat,
    // subCat,

    // desc,
    // stone_weight,
    // stone_value,

    // other_stone,
    // other_tags,
    // note,
    // men,
    // women,
    // kids,
    // stock,
    // image,

    // cer_charges,
    //   making_type,       
    id,
    name,
    email,
    mobile_number,
    language,
    date,
    time,
    category,
    sub_category,
    city,
    message,
    status,
    url
  ) {

    const formData: FormData = new FormData();
    formData.append('id', id),
    formData.append('name', name);
    formData.append('email', email);
    formData.append('mobile_number', mobile_number);
    formData.append('language', language);
    formData.append('date', date);
    formData.append('time', time);
    formData.append('category', category);
    formData.append('sub_category', sub_category);
    formData.append('city', city);
    formData.append('message', message);
    formData.append('status', status);
    formData.append('url', url);
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/vediocalling/updateVedio/` + id, formData).pipe(map(user => {
      return user;
    }));

  }


 
  // deleteSingleVideo(id){
  //   return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/vediocalling/deleteVideocalling/` + id).pipe(map(user => {
  //     return user;
  //   }));
  // }




  getsingleVideo(id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/vediocalling/singleVideocalling/` + id).pipe(map(user => {
      return user;
    }));
  }

  // uploadProductImage(images: File[]) {
  //   console.error(images);
  //   var ary = [];
  //   var i = 0;
  //   const formData: FormData = new FormData();
  //   images.map(image => {
  //     formData.append('image' + i++, image, image.name);
  //   });
  //   //formData.append('image', JSON.stringify(ary));
  //   return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/product/upload_images`, formData).pipe(map(user => {
  //     return user;
  //   }));
  // }

  // getProImage(): Observable<any> {
  //   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/product/get_pro_images`).pipe(map(user => {
  //     return user;
  //   }));
  // }

  // getFeedback(): Observable<any> {
  //   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/customer/getFeedback`).pipe(map(user => {
  //     return user;
  //   }));
  // }

  // getEnquiry(): Observable<any> {
  //   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/product/getEnquirie`).pipe(map(user => {
  //     return user;
  //   }));
  // }


  // addPromo(coupon_code, mobile, amount, status, category, coupon_type,mainCat) {
  //   const formData: FormData = new FormData();
  //   formData.append('coupon_code', coupon_code);
  //   formData.append('mobile', mobile);
  //   formData.append('status', status);
  //   formData.append('amount_or_percent', amount);
  //   formData.append('coupon_type', coupon_type);

  //   if(mainCat=='All'){
  //     formData.append('all', '1');
  //   }else{
  //     formData.append('mainCat', mainCat._id);
  //   }

    // return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/promocode/addPromocode`, formData).pipe(map(user => {
    //   return user;
    // }));
  }

  // deleteSinglePro(id){
  //   return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/abharana/deleteSingleProduct/` + id).pipe(map(user => {
  //     return user;
  //   }));
  // }



  // updatePromo(id, coupon_code, mobile, amount, status, category, coupon_type,mainCat) {
  //   const formData: FormData = new FormData();
  //   formData.append('coupon_code', coupon_code);
  //   formData.append('mobile', mobile);
  //   formData.append('status', status);
  //   formData.append('amount_or_percent', amount);
  //   formData.append('coupon_type', coupon_type);

  //   if (mainCat == 'All') {
  //     formData.append('all', '1');
  //   } else {
  //     formData.append('mainCat', mainCat._id);
  //   }

  //   return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/promocode/updatePromocode/` + id, formData).pipe(map(user => {
  //     return user;
  //   }));
  // }

  // getPromo(): Observable<any> {
  //   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/promocode/getPromocode`).pipe(map(user => {
  //     return user;
  //   }));
  // }

  // getPrivacy(): Observable<any> {
  //   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/privacy/getPrivacy`).pipe(map(user => {
  //     return user;
  //   }));
  // }

  // submitPrivacy(id, description, terms) {
  //   const formData: FormData = new FormData();
  //   formData.append('description', description);
  //   formData.append('terms', terms);
  //   return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/privacy/updatePrivacy/` + id, formData).pipe(map(user => {
  //     return user;
  //   }));
  // }

  // deletePromocode(id) {
  //   return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/promocode/deletePromocode/` + id).pipe(map(user => {
  //     return user;
  //   }));
  // }

  // getWebsite(): Observable<any> {
  //   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/product/getWebsite`).pipe(map(user => {
  //     return user;
  //   }));
  // }

  // deleteProduct(file: File) {
  //   const formData: FormData = new FormData();
  //   formData.append('excel', file);
  //   return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/excel/deleteProduct`, formData).pipe(map(user => {
  //     return user;
  //   }));
  // }


  // getOrders(type){
  //   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/product/getOrders/`+type).pipe(map(user => {
  //     return user;
  //   }));
  // }

  // downloadPdf(id) {
  //   // const params = new HttpParams().set('id', id);
  //   const formData = new FormData();
  //   return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/abharana/printOrder/` + id, formData).pipe(map(user => {
  //     return user;
  //   }));
  // }


  // getSpecialProduct() {
  //   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/abharana/getSpecialOffer`).pipe(map(user => {
  //     return user;
  //   }));
  // }
  // getSchemes() {
  //   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/scheme`).pipe(map(user => {
  //     return user;
  //   }));
  // }
  
  // deleteScheme(id:string) {
    
  //   return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/scheme/`+id).pipe(map(user => {
  //     return user;
  //   }));
  // }
  // uploadScheme(scheme:File) {
  //   const formData: FormData = new FormData();
  //   formData.append('scheme', scheme);
   

  //   return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/scheme`, formData).pipe(map(user => {
  //     return user;
  //   }));
  // }

  // product_download(stock_filter,sku_filter,main_cat_filter,sub_cat_filter): Promise<Blob> {
  //   var formData = {};
  //   formData['stock_filter'] = stock_filter;
  //   formData['sku_filter'] = sku_filter;
  //   formData['main_cat_filter'] = main_cat_filter;
  //   formData['sub_cat_filter'] = sub_cat_filter;
    
  //   formData['download'] = 1;

  //   const file = this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/excel/product_download?` + $.param(formData),
  //     { responseType: 'blob' as 'json' }).toPromise();
  //   return file;
  // }

  // upload_out_stock(upload:File,outstock:any) {
  //   const formData: FormData = new FormData();
  //   formData.append('excel', upload);
  //   if(outstock){
  //     outstock = 'outstock';
  //   }else{
  //     outstock = 'instock'
  //   }
  //   formData.append('outstock', outstock);
  //   return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/excel/outofstock`, formData).pipe(map(user => {
  //     return user;
  //   }));
  // }



  // makeOrderConfirm(id) {
  //   var formData = {};
  //   return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/order/update-confirm-order/`+id, formData).pipe(map(user => {
  //     return user;
  //   }));
  // }
// }
