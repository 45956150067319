import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageService {
    constructor(private http: HttpClient) { }

    save(title: string) {
        const formData: FormData = new FormData();

        formData.append('title', title);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/languages`, formData).pipe(map(user => {
            return user;
        }));

    }

    update(id: string, title: string, ) {
        const formData: FormData = new FormData();
        formData.append('title', title);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/languages/` + id, formData).pipe(map(user => {
            return user;
        }));

    }
    delete(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/languages/` + id).pipe(map(user => {
            return user;
        }));

    }
    getLanguages(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/languages`).pipe(map(user => user));

    }
}
