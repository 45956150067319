import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { CategoryService, AlertService,BannersService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-video-request',
  templateUrl: './video-request.component.html',
  styleUrls: ['./video-request.component.css']
})
export class VideorequestComponent implements OnInit {
  bannerForm: FormGroup;
  loading = false;
  imageExist = '';
  fileToUpload: File = null;

  submitted = false;
  //table = false;
  cancelButton = false;
  users$:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  width_limit = `${environment.bannerWidth}`;
  height_limit = `${environment.bannerHeight}`;
  media_domain = `${environment.media_domain}`;
  maincatwidth_limit = `${environment.maincatWidth}`;
  maincatheight_limit = `${environment.maincatHeight}`;
  curPage = 1;
  perPage = 10;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private bannersService: BannersService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
  
    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };

    this.bannersService.getVideo().subscribe(data => {
    
      this.users$ = data.data.video;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });

  }

  statusChange(user){

    var that = this;
    var disp = '';
    disp = user.status == 1 ? 'unblock' : 'block';
    alertify.confirm('Are you sure want to ' + disp + ' this video request', function () {
      that.bannersService.updateStatus(user._id,user.status == 1 ? 0 : 1)
        .subscribe(
          data => {
        // location.reload();

            if (data.status.code == 0) {
            
              that.bannersService.getVideo().subscribe(data => {
    
                that.users$ = data.data.video;
                that.dtTrigger.next();
              }, error => {
                console.error(error);
              });

            } else {
              that.toastr.error(data.status.message)
            }
          },
          error => {
            that.toastr.error(error)
          });
    }
      , function () { 
        that.toastr.error('Action cancelled')

      });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }
}
