<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
	<div class="page-header">
		<h1 class="page-title"> Missed Orders</h1>
		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a href='/home'>Home</a></li>
			<li class="breadcrumb-item active"> Missed Orders</li>
		</ol>
	</div>
	<div class="container">
		<div class="page-content container-fluid">
			<div class="row" style="top: 10px; position: relative;">
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane" style="display: block !important;"  role="tabpanel" aria-labelledby="contact-tab">
						<div class="row">
							<div class="col-md-3">
								<h4 class="ng-binding"> Missed Order ({{orders$?.length}})</h4>
							</div>
						</div>
						<table class="table dataTable table-bordered" datatable
							[dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
							<thead>
								<tr>
									<th>Sl. No.</th>
									<th>Name</th>
									<th>Mobile</th>
									<th>Email</th>
									<th>Product Name</th>
									<th>SKU</th>
									<th>Quantity</th>
								</thead>
								<tbody>
									<tr *ngFor="let order of orders$;let n=index">
										<td>{{n+1}}</td>
										<td>{{order.user ? order.user.first_name:'NA'}}</td>
										<td>{{order.user ? order.user.mobile_no:'NA'}}</td>
										<td>{{order.user ? order.user.email:'NA'}}</td>
										<td>{{order.product && order.product.name?order.product.name:'NA'}}</td>
										<td>{{order.product && order.product.SKU?order.product.SKU:'NA'}}</td>
										<td>{{order.quantity?order.quantity:'NA'}}</td>
									</tr>
									
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>