import { Component, OnInit } from '@angular/core';
import { ProductService, AlertService, BannersService, CategoryService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Placeholder } from '@angular/compiler/src/i18n/i18n_ast';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';

declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  submitted: boolean;
  searchForm: FormGroup;
  constructor(private pro: ProductService, private http: HttpClient, private toastr: ToastrService, private bannersService: CategoryService) { }
  fileToUpload: File = null;
  perPage = 10;
  stock_filter = '';
  sku_filter='';
  main_cat_filter:any;
  sub_cat_filter:any;
  products$ = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  media_domain = `${environment.media_domain}`;
  product_excel = '/product/excel/5f2cda916e5aa.xls';
  weblink = `${environment.WEBLINK}`;

  sub$: any[];
  main$: any[];
  curPage = 1;
  total: any;

  loading = false;
  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
  subCategory = {

    displayKey: 'title', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select main category', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No main category found', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search main category',
    padding: '15px', // label thats displayed in search input,


    selectAllBtnText: 'Select',
    searchOnKey: 'title' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys

    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  mainCat = {

    displayKey: 'title', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select main category', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No main category found', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search main category',
    padding: '15px', // label thats displayed in search input,


    selectAllBtnText: 'Select',
    searchOnKey: 'title' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys

    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  get f() { return this.searchForm.controls; }
  public async reportDown(): Promise<void> {
   
    this.submitted = true;
   
this.loading = true;
const product = [];


const blob = await this.pro.product_download(
  this.stock_filter,
this.sku_filter,
this.main_cat_filter,
this.sub_cat_filter


        );
let binaryData = [];
binaryData.push(blob);
const url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/ms-excel'}));
// const url = window.URL.createObjectURL(blob);

const link = this.downloadZipLink.nativeElement;
link.href = url;
link.download = 'products.xlsx';
link.click();

// window.URL.revokeObjectURL(url);
this.loading = false;
document.body.classList.remove('jw-modal-open');
// link.stop();
}

  ngOnInit() {

    localStorage.setItem('currentUrl', 'product');

    this.bannersService.getSubCategory().subscribe(data => {
  
      this.sub$ = data.data.main;
    }, error => {
      console.error(error);
    });

    this.bannersService.getMainCat().subscribe(data => {
  
      this.main$ = data.data.main;
    }, error => {
      console.error(error);
    });
this.loading = true;
    const that = this;


    that.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: that.perPage,
      // language: {
      //   searchPlaceholder: "SKU"
      // },
      serverSide: true,
      processing: true,
      searching: false,
      ordering: true,
      order: [],
      columnDefs: [{
        "targets": [],
        "orderable": false
      }],
      lengthChange: false,

      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters['sku_filter'] = that.sku_filter;
        dataTablesParameters['stock_filter'] = that.stock_filter;
        dataTablesParameters['main_cat_filter'] = that.main_cat_filter;
        dataTablesParameters['sub_cat_filter'] = that.sub_cat_filter;
        that.http
          .post<any>(
            `${environment.apiUrl}${environment.apiPrefix}/excel/get_products`,
            dataTablesParameters, {}
          ).subscribe(resp => {
that.loading = false;
            that.products$ = resp.data.jawellery;
            that.total = resp.data.totalRecords;
            that.curPage = resp.data.cur_page;
            callback({
              recordsTotal: resp.data.totalRecords,
              recordsFiltered: resp.data.filteredRecords,
              data: []
            });
          });

      }
    };

  }

  uploadExcel(e, files: FileList) {
   
    if (files[0].type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.toastr.error('Please upload excel sheet only');
      // alertify.error('Please upload image of '+imgWidth+'*'+imgHeight);
      this.fileToUpload = null;
$('#upload_file').val('');
      return false;
    } 
    var that = this;

    this.fileToUpload = files.item(0);

  }
  searchFilter(search,type){
    if(type=='sku'){
      this.sku_filter = search;
    }

    if(type=='stock'){
      this.stock_filter = search;
    }

    if(type=='main_cat'){
      this.main_cat_filter = search;
    }

    if(type=='sub_cat'){
      this.sub_cat_filter = search;
    }
    
    var table = $('#table').DataTable();
    table.draw();
  }

  deleteProduct(e, id) {
    var that = this;
  
    alertify.confirm('Are you sure want to delete this record?', function () {
      this.loading = true;
      that.pro.deleteSinglePro(id).subscribe(data => {
    
        if(data.status.code == 0){
          that.loading = false;
          that.toastr.success('Delete Successfully');
        }
       
        
       that.dtTrigger.next();
      location.reload();
      }, error => {
        console.error(error);
      });
    }, function () {
      that.toastr.error('Action Cancelled');
    });
  }

  uploadExcelFile() {

    if (this.fileToUpload) {
      var that = this;

      alertify.confirm('Are you sure want to upload file?', function () {
        that.loading = true;
        that.pro.upload(that.fileToUpload).subscribe(data => {
          that.loading = false;
          if (data.status.code == 0) {
            that.toastr.success("Successfully uploaded");
            location.reload();
          } else if(data.status.code == 1) {  
            //that.toastr.error(data.status.message);
            location.reload();
          }else if(data.status.code == 2){
            that.toastr.error(data.status.message);
          }else{
            that.toastr.error("Products not uploaded");
          }
        }, error => {

        });
      }, function () {
        that.toastr.error("Upload action cancelled");
      });
    } else {
      this.toastr.error('File is required');
    }
  }


  download() {
    location.href = '/assets/excel.xlsx?' + new Date().getTime();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }

  selectmainCategory(e) {
    console.log(e);
  }

  selectsubCategory(e) {

  }


}
