import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class JobSeekerService {
    constructor(private http: HttpClient) { }
    get_details(id:string): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/jobseeker-details?id`).pipe(map(user => user));
    }

    getGrounds(vendorId: string) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/grounds/all?vendorId=` + vendorId).pipe(map(user => user));
    }
}
