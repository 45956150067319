<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<link rel="stylesheet" href="/assets/css/bootstrap-table.css">
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Products Images</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Products Images</li>
        </ol>
    </div>
</div>
<div class="container-fluid">

    <div class="panel">
        <div class="panel-body">
            <div class="row ">
                <div class="col-md-12">
                    <!-- <h3>Products Images</h3> -->
                    <div class="form-group cv prod_image">
                        <form [formGroup]="imageForm" (ngSubmit)="imageUpload()">

                            <table class="tbl table-center">
                                <tr class="prod_upload">
                                    <td>
                                        <label for="url_en_point" class="upload_txt">Upload images</label>
                                    </td>
                                    <td style="width: 250px;"><input multiple
                                            (change)="handleFileInput($event, $event.target.files, 1)" type="file"
                                            id="url_en_point" formControlName="image" name="images[]"
                                            placeholder="Enter url end point" class="form-control"
                                            [ngClass]="{ 'is-invalid': (submitted) && f.image.errors }">

                                        <div *ngIf="(submitted) && f.image.errors" class="invalid-feedback">
                                            <div *ngIf="f.image.errors?.required">Image is required</div>
                                        </div>
                                        <small class="imgdim_txt" style="display: block">(Image Dimensions:
                                            {{branchwidth_limit}}*{{branchheight_limit}})</small>
                                    </td>
                                    <!-- (click)="uploadFile($event,1)" -->
                                    <!-- <td class="video_cls">
                                        <label for="url_en_point1">Upload Video</label>
                                    </td>
                                    <td style="width: 250px;"><input multiple
                                            (change)="handleFileInput($event, $event.target.files, 2)" type="file"
                                            id="url_en_point1" formControlName="video" name="images[]"
                                            placeholder="Enter url end point" class="form-control"
                                            [ngClass]="{ 'is-invalid': (submitted) && f.video.errors }">
                                     
                                    </td> -->
                                    <td>
                                        <button type="submit" class="btn btn-info ml-5 prodimg_submit"
                                            style="bottom: 7px;" value="Submit">Submit</button>
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </div>
                </div>
            </div>
            <!-- <hr> -->

            <div class="row">

                <!--  <div class="col-md-9">
            <h4 class="ng-binding">Total Images ({{length}})</h4>
        </div> -->

                <!-- <div class="col-md-3 form-inline">
            <input type="text" name="global_name" class="form-control float-right" placeholder="Image Name( SKU )">
        </div> -->

            </div>

            <div class="col-md-7 prod_image_full">
                <div class="prod_image_search">
                    <div id="table_filter" class="dataTables_filter"><label><span>Search:</span><input type="search" class="" (keyup)="handlePageseachChange($event)" placeholder="SKU" aria-controls="table"></label></div>
                </div>
                <hr>
                <table cellspacing="0" id="table" class="table table-hover dataTable table-striped table-bordered w-full prodimg_table"
               >
                <thead>
                    <tr>
                        <th width="40px;">Sl No.</th>
                        <th width="40px;">Image Name</th>
                        <th width="40px;">Image</th>
                        <th width="40px;">View</th>

                    </tr>
                </thead>
                <tbody *ngIf="imageList$">
                    <tr *ngFor="let img of imageList$| paginate : {
                        itemsPerPage: pageSize,
                        currentPage: page,
                        totalItems: count
                      };
                let i = index">
                   
                        <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                        <td>{{img.name}}</td>
                        <td *ngIf="img.image"><img src="{{media_domain}}{{img.image}}" class="image"></td>
                        <td *ngIf="!img.image"><img src="/assets/default.png" class="image"></td>
                        <td>
                            <a id="a" (click)="setImage(media_domain+img.image,img)" data-toggle="modal"
                                data-target="#exampleModalCenter">view</a>
                        </td>
                        <!-- (click)="deleteState(pro)"  -->
                    </tr>
                </tbody>
                <tbody *ngIf="!imageList$">
                    <th></th>
                </tbody>
            </table>
            <div class="pagination_box">
            <div><p>Showing {{pagingCounter}} to {{page*10}} of {{count}} entries</p></div>
            <pagination-controls *ngIf="imageList$ && imageList$.length > 0"
                previousLabel="Prev"
                nextLabel="Next"
                responsive="true"
                (pageChange)="handlePageChange($event)"
              ></pagination-controls>
            </div>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade image_view" id="exampleModalCenter" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <span>Total : {{viewall$ && viewall$.length}}</span>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">

                    <div class="col-sm-6" *ngFor="let imgs of viewall$">
                        <button *ngIf="imgs.video" class="btn btn-primary" (click)="videoplay($event, imgs)">View</button>
                    
                        <div class="prodimg">
                        <!-- <a (click)="userDelete($event, imgs)"> -->
                            <i
                            (click)="userDelete($event, imgs)"  class="icon md-delete text-danger" aria-hidden="true"></i>
                        <!-- </a> -->
                        <img src="{{media_domain}}{{imgs.image}}" alt="image" class="img_cls" *ngIf="imgs.image">
                      
                        <!-- <a (click)="videoplay($event, imgs)"> -->
                            <div class="video" *ngIf="imgs.video">
                            <video class="vd_cls">
                                <source src="{{media_domain}}{{imgs.video}}" type="video/mp4">
                            </video>
                            
                         

                            <!-- <i
                            (click)="userDelete($event, imgs)"  class="icon md-delete text-danger" aria-hidden="true"></i> -->
                            <i
                            (click)="userDelete($event, imgs)" class="icon md-delete text-danger" aria-hidden="true"></i>
                            <!-- <i class="fa-solid fa-eye text-danger" aria-hidden="true"></i> -->
                        </div>
                        <!-- <br> -->
                      
                            <!-- <video (click)="playVideo($event)">
                                <source src="video.mp4" type="video/mp4">
                              </video> -->
                        <!-- </a> -->
                    </div>
                    </div>
                    
                    <!-- <div class="col-sm-6">
                            <img src="/assets/U12420.png" class="img_cls">
                        </div>
                        <div class="col-sm-6">
                            <img src="/assets/U12420.png" class="img_cls">
                        </div>
                        <div class="col-sm-6">
                            <img src="/assets/U12420.png" class="img_cls">
                        </div>
                        <div class="col-sm-6">
                            <video class="vd_cls">
                                <source src="/assets/U12420.mp4">
                            </video>
                        </div> -->


                </div>
                <!-- <div class="new_one" *ngFor="let imgs of viewall$">
                  
                    <img src="" class="img" id="mmm">
                    <span *ngIf="imgs.image">
                        <img src="{{media_domain}}{{imgs.image}}" alt="image" class="img" *ngIf="imgs.image">
                    </span>
                    <span *ngIf="imgs.video">
                        <video class="img">
                            <source src="{{media_domain}}{{imgs.video}}">
                        </video>
                    </span>
                </div> -->
            </div>
        </div>
    </div>
</div>