import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class IndustryService {
    constructor(private http: HttpClient) { }

    save(title: string, image: File) {
        const formData: FormData = new FormData();
        formData.append('icon', image, image.name);

        formData.append('title', title);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/industries`, formData).pipe(map(user => {
            return user;
        }));

    }

    update(id: string, title: string,image: File) {
        const formData: FormData = new FormData();
        if (image) {
            formData.append('icon', image, image.name);
        }

        formData.append('title', title);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/industries/` + id, formData).pipe(map(user => {
            return user;
        }));

    }
    delete(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/industries/` + id).pipe(map(user => {
            return user;
        }));

    }
    getIndustries(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/industries`).pipe(map(user => user));

    }
}
