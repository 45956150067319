<site-header></site-header>
<style type="text/css">
img {
    height: auto;
    max-width: 100%;
}

.cell {
    display: table-cell;
}

.cell-fluid {
    width: 100%;
}
</style>
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Consultants</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Consultants</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row">
            <div class="col-xl-12">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <header class="panel-heading">
                        <h3 class="panel-title">
                          Consultants
                            <div class="row custom-filters" style="display:none;margin-left: 12px;">
                                <div class="form-group col-md-4">
                                    <input type="text" class="form-control" placeholder="Registered on" autocomplete="off" id="fini" readonly>
                                </div>
                            </div>
                        </h3>
                    </header>
                    <div class="panel-body">
                        <div class="tb-wrapper">
                            <table cellspacing="0" id="table" class="table table-hover dataTable table-striped w-full" datatable [dtOptions]="dtOptions">
                                <thead>
                                    <tr>
                                        <th width="8%">Sl No.</th>
                                        <th width="10%">Name</th>
                                        <th width="10%">Email</th>
                                        <th width="10%">Mobile No.</th>

                                        <th width="10%">View</th>
                                        <th width="10%">Registered On.</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="users$?.length != 0">
                                    <tr *ngFor="let user of users$; let i = index" >
                                        <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                        <td *ngIf="user.name;else name">{{ user.name}}</td>
                                        <ng-template #name>
                                            <td>-</td>
                                        </ng-template>
                                        <td *ngIf="user.email;else email">{{ user.email}} </td>
                                        <ng-template #email>
                                            <td>-</td>
                                        </ng-template>
                                        <td>{{ user.full_mobile }}</td>

                                        <td  *ngIf="user.name && user.email;else view"><a href="javascript:void(0)" class="btn btn-info view" (click)="userDetails(user)">View</a></td>
                                        <ng-template #view>
                                            <td><a href="javascript:void(0)" class="btn btn-primary view disabled"  (click)="userDetails(user)">View</a></td>
                                        </ng-template>
                                        <td>{{ user.created_on }}</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="users$?.length == 0">
                                    <tr>
                                        <td colspan="7" class="no-data-available">No data!</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- End Panel Kitchen Sink -->
            </div>
        </div>
    </div>
</div>
<jw-modal id="user_details">
    <div id="login">
        <div class="col-md-12">
            <div class="modal-header" style=" padding: 0; padding-bottom: 10px;">
                <h4 style="width: 100%" class="modal-title" id="myModalLabel">
                    Consultant Details <a href="javascript:void(0);" style="float: right" (click)="closeModal('user_details')"><i class="icon md-close   text-primary "></i></a>
                </h4>
            </div>
            <!-- Example Horizontal Form -->
            <div class="">
                <div class="example" *ngIf="user_details">
                    <div class="row">
                        <div class="col-md-5 mx-auto " *ngIf="user_details.profile">
                            <img src="{{media_domain}}{{user_details.profile}}" class="user_img">
                        </div>

                        <div class="col-md-4 mx-auto" *ngIf="!user_details.profile">
                          <img src="/assets/one-level/images/default_user.png" class="user_img">
                      </div>
                        <div class="col-md-8 mx-auto consultant_info">
                            <tr>
                                <td class="col-md-1 sub">Name</td>:
                                <td class="col-md-1 details" *ngIf="user_details.name;name_user">{{ user_details.name}}</td>
                                <ng-template #name_user>
                                    <td class="col-md-1">-</td>
                                </ng-template>
                            </tr>
                            <tr>
                                <td class="col-md-1 sub"> Email</td>:
                                <td class="col-md-1 details" *ngIf="user_details.email;else email_details">{{ user_details.email}}</td>
                                <ng-template #email_details>
                                    <td class="col-md-1">-</td>
                                </ng-template>
                            </tr>
                            <!-- tr>
                                <td class="col-md-1 sub">Gender</td>:
                                <td class="col-md-1 details" *ngIf="user_details.gender;else gender_details">{{ user_details.gender}}</td>
                                <ng-template #gender_details>
                                    <td class="col-md-1">-</td>
                                </ng-template>
                            </tr>
                            <tr>
                                <td class="col-md-1 sub">Date of Birth</td>:
                                <td class="col-md-1 details" *ngIf="user_details.dob;else dob">{{ user_details.dob}}</td>
                                <ng-template #dob>
                                    <td class="col-md-1">-</td>
                                </ng-template>
                            </tr>
 -->
                            <tr>
                                <td class="col-md-3 sub">Mobile Number</td>:
                                <td class="col-md-3 details" *ngIf="user_details.mobile_no;else mobile_no_details">{{ user_details.country_code}}{{ user_details.mobile_no}}</td>
                                <ng-template #mobile_no_details>
                                    <td class="col-md-1">-</td>
                                </ng-template>
                            </tr>
                            <tr>
                              <td class="col-md-3 sub">Location</td>:
                              <td class="col-md-3 details" *ngIf="user_details.location;else location">{{ user_details.location}}</td>
                              <ng-template #location>
                                  <td class="col-md-1">-</td>
                              </ng-template>
                          </tr>

                          <tr>
                              <td class="col-md-3 sub">Company</td>:
                              <td class="col-md-3 details" *ngIf="user_details.company.company_name">{{ user_details.company.company_name}}</td>
                                <td class="col" *ngIf="!user_details.company.company_name">-</td>

                          </tr>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</jw-modal>
<!-- End Page -->
<style type="text/css">
.example-wrap {
    margin-bottom: 30px;
}

.disabledTable {
    pointer-events: none;
    opacity: 0.4;
}
</style>
