import { Component, OnInit } from '@angular/core';
import { BannersService, AlertService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
    selector: 'app-daily-rates',
    templateUrl: './daily-rates.component.html',
    styleUrls: ['./daily-rates.component.css']
})


export class DailyRatesComponent implements OnInit {
    loading = false;
    submitted = false;
    dailyrateForm: FormGroup;
    rates$: Observable<any[]>;
    active = true;

    constructor(private service: BannersService,
        private formBuilder: FormBuilder,
        private toastr: ToastrService) { }

    gold22: any;
    gold18: any;
    silver: any;

    ngOnInit() {

        this.service.getDaily().subscribe(data => {
            console.log(data);
            this.gold22 = data.data.gold22;
            this.gold18 = data.data.gold18;
            this.silver = data.data.silver;
        }, error => {
            console.error(error);
        });


        this.dailyrateForm = this.formBuilder.group({
            gold22: ['', [Validators.pattern(/^(\d{0,5}(\.\d{0,2})?)$/)]],
            gold18: ['', [Validators.pattern(/^(\d{0,5}(\.\d{0,2})?)$/)]],
            silver: ['', [Validators.pattern(/^(\d{0,5}(\.\d{0,2})?)$/)]]
        });

    }
    editDaily() {
        $('.sc').removeClass('hidden');
        $('.dn').removeClass('hidden');
        $('.edit').hide();
        $('.service').addClass('disable');
        $('.form-inline').addClass('hidden');
        $('.disp').show();

        this.dailyrateForm.patchValue({
            gold22: this.gold22,
            gold18: this.gold18,
            silver: this.silver

        });
        $('.disp').show();

        /*   $('.sc').removeClass('hidden');
           $('.dn').removeClass('hidden');
           $('.edit').hide();
   
           $('.form-inline').addClass('hidden');
           $('.disp').show();
   
           $('#gold22').val(this.gold22);
           $('#gold18').val(this.gold18);
           $('#silver').val(this.silver);
   */
    }

    cancelDaily() {
        $('.sc').addClass('hidden');
        $('.dn').addClass('hidden');
        $('.edit').show();

        $('.form-inline').removeClass('hidden');
        $('.disp').hide();
    }


    get f() { return this.dailyrateForm.controls; }
    submitDaily() {

        var gold22 = $('#gold22').val();
        var gold18 = $('#gold18').val();
        var silver = $('#silver').val();

        this.submitted = true;
        if (this.dailyrateForm.invalid) {
            return false;
        }
        var that = this;
        alertify.confirm('Are you sure want to submit?', function () {
            that.loading = true;
            that.service.submitDailyRate(that.f.gold22.value, that.f.gold18.value, that.f.silver.value).subscribe(data => {
                console.log(data);

                if (data.status.code == 0) {
                    that.submitted = false;
                    that.toastr.success('Daily rates');
                    that.loading = false;
                    that.dailyrateForm.patchValue({
                        gold22: '',
                        gold18: '',
                        silver: ''
                    });

                    that.service.getDaily().subscribe(data => {
                        console.log(data);
                        that.gold22 = data.data.gold22;
                        that.gold18 = data.data.gold18;
                        that.silver = data.data.silver;

                    }, error => {
                        console.error(error);
                    });
                }

                that.loading = false;

                $('.sc').addClass('hidden');
                $('.dn').addClass('hidden');
                $('.edit').show();

                $('.form-inline').removeClass('hidden');
                $('.disp').hide();
            }, error => {
                console.error(error);
                that.loading = false;
            });
        }
            , function () { //alertify.error('Delete action cancelled')
                that.toastr.error('Submit action cancelled')

            });

    }

}
