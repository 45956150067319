import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class VideoCallingService {
    constructor(private http: HttpClient) { }

   
    update(id: string, title: string, ) {
        const formData: FormData = new FormData();
        formData.append('title', title);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/website/video-request/` + id, formData).pipe(map(user => {
            return user;
        }));
    }
    delete(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/vediocalling/deleteVideocalling/` + id).pipe(map(user => {
            return user;
        }));

    }
    getvideorequest(search,page): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/website/video-request?page=` + page + '&search=' + search).pipe(map(user => user));
    }

    getvideoconfirm(id,url): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('url', url);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/analytics/send_videocall_mail/` + id,formData).pipe(map(user => {
            return user;
        }));
    }


    changecall(id): Observable<any> {
        const formData: FormData = new FormData();
     //   formData.append('url', url);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/analytics/update-call-status/` + id,{}).pipe(map(user => {
            return user;
        }));
    }
}
