<style type="text/css">


    .box_1 {
        background-color: #ffffff;
        padding: 45px;
    }

    .box_1,
    .box_1 h3 {
        color: #0f0c0c;
    }

    #login aside,
    #register aside {
        width: auto;
        padding: 0px 0px 0px;
        margin: auto;
        left: 0;
        top: 0;
        overflow-y: inherit;
        background-color: #fff;
        min-height: 100%;
    }

    #login aside form,
    #register aside form {
        margin-bottom: 0px;
    }

    .animated .block-reveal {
        height: auto;
        overflow: hidden;
        position: relative;
        width: 100%;
        box-shadow: 10px 12px 30px rgba(0, 0, 0, 0.2) !important;
        border-radius: 12px;
    }

    .call_section {
        background: url(/assets/website/img/login.jpg) center center no-repeat fixed;
        background-size: cover;
        min-height: 400px;
        padding: 10% 0;
    }

    .nav-tabs a {
        font-size: 1.2rem;
    }

    .reg {
        padding-bottom: 12px;
        color: #878787;
    }

    .centered {
        text-align: center;
    }

    .centered h4 {
        color: green;
    }
 
    @media only screen and (max-width: 480px) {
        .call_section[_ngcontent-c1] {
            padding: 30% 0;
        }

        .nav-tabs a {
            font-size: .7rem;
        }

        .h3,
        h3 {
            font-size: 1.0rem;
        }

        .h5,
        h5 {
            font-size: .8rem;
        }

        .call_section {
            padding: 25% 0;
        }

        jw-modal .jw-modal .jw-modal-body {
            margin: 5% 5% !important;
        }
    }

    button {
        display: block;
    }


</style>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>

<!-- Page -->
<div class="page vertical-align" data-animsition-in="fade-in" data-animsition-out="fade-out">
    <div class="page-content login-content vertical-align-middle">
        <div class="brand text-center">
            <img class="brand-img" src="/assets/one-level/images/logo3.png" alt="...">
            <!--<h2 class="brand-text" style="text-transform: none;">Abharan</h2>-->
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group form-material floating" data-plugin="formMaterial">
                <input type="text" formControlName="username" class="form-control"
                    [ngClass]="{ 'is-invalid': (f.username.touched || submitted) && f.username.errors }" />
                <label class="floating-label" style="color: #000000;" for="username">Email</label>
                <div *ngIf="(f.username.touched || submitted) && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required" style="color: #4c403f;">Email is required</div>
                </div>
            </div>
            <div class="form-group form-material floating" data-plugin="formMaterial">
                <input type="password" formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': (f.password.touched || submitted) && f.password.errors }" />
                <label class="floating-label" style="color:#000000" for="password">Password</label>
                <div *ngIf="(f.password.touched || submitted) && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required" style="color: #4c403f;">Password is required</div>
                </div>
            </div>
            <div class="form-group">
                <button [disabled]="loading" class="btn btn-primary btn-1" type="submit">Login</button>
                <img *ngIf="loading"
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
        </form>
    </div>
</div>
<!-- End Page -->
