<site-header></site-header>
<style type="text/css">
img {
    height: auto;
    max-width: 100%;
}

.cell {
    display: table-cell;
}

.cell-fluid {
    width: 100%;
}
</style>
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Job Lists</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Job Lists</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row">
            <div class="col-xl-12">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <header class="panel-heading">
                        <h3 class="panel-title">
                            Job Lists
                            <div class="row custom-filters" style="display:none;margin-left: 12px;">
                                <div class="form-group col-md-4">
                                    <input type="text" class="form-control" placeholder="Registered on" autocomplete="off" id="fini" readonly>
                                </div>
                            </div>
                        </h3>
                    </header>
                    <div class="panel-body">
                        <div class="tb-wrapper">
                            <table cellspacing="0" id="table" class="table table-hover dataTable table-striped w-full" datatable [dtOptions]="dtOptions">
                                <thead>
                                    <tr>
                                        <th width="8%">Sl No.</th>
                                        <th width="10%">Employer Name</th>
                                        <th width="10%">Job Title</th>
                                        <th width="10%">Industry</th>

                                        <th width="10%">View</th>
                                        <th width="10%">Status</th>
                                        <th width="10%"> Posted On.</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="jobs$?.length != 0">
                                  <tr *ngFor="let job of jobs$; let i = index">
                                        <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                        <td *ngIf="job.user.name;else name"><a href="/employer-details/{{job.user._id}}" class="text-info employer_info"   data-toggle="tooltip" title="View Employer Details!"> {{job.user.name}}</a></td>
                                        <ng-template #name>
                                            <td>-</td>
                                        </ng-template>
                                        <td *ngIf="job.job_title;else job_title">{{ job.job_title}} </td>
                                        <ng-template #job_title>
                                            <td>-</td>
                                        </ng-template>
                                        <td *ngIf="job.industry;else industry">{{ job.industry}} </td>
                                        <ng-template #industry>
                                            <td>-</td>
                                        </ng-template>

                                        <td><a href="javascript:void(0)" class="btn btn-default view" (click)="userDetails(job)">View</a></td>
                                        <td *ngIf="job.status==1">Active</td>
                                        <td *ngIf="job.status!=1">Deactive</td>
                                        <td>{{ job.created_on }}</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="jobs$?.length == 0">
                                    <tr>
                                        <td colspan="7" class="no-data-available">No data!</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- End Panel Kitchen Sink -->
            </div>
        </div>
    </div>
</div>
<jw-modal id="user_details">
    <div id="login">
        <div class="col-md-12">
            <div class="modal-header" style=" padding: 0; padding-bottom: 10px;">
                <h4 style="width: 100%" class="modal-title" id="myModalLabel">
                    Job Details <a href="javascript:void(0);" style="float: right" (click)="closeModal('user_details')"><i class="icon md-close   text-primary "></i></a>
                </h4>
            </div>
            <hr>
            <!-- Example Horizontal Form -->
            <div class="">
                <div class="example" *ngIf="user_details">
                 <div class="row">
                   <div class="col-md-4 sub_title">Job Title</div>
                   <div class="col-md-2">:</div>
                   <div class="col">{{user_details.job_title}}</div>
                 </div>
                 <div class="row">
                  <div class="col-md-4 sub_title">Industry</div>
                  <div class="col-md-2">:</div>
                  <div class="col">{{user_details.industry}}</div>
                </div>
                <div class="row">
                  <div class="col-md-4 sub_title">Experience</div>
                  <div class="col-md-2">:</div>
                  <div class="col">{{user_details.experience_low}}yr - {{user_details.experience_high}}yr</div>
                </div>

                <div class="row">
                  <div class="col-md-4 sub_title">Job Type</div>
                  <div class="col-md-2">:</div>
                  <div class="col">{{user_details.job_type}}</div>
                </div>

                <div class="row">
                  <div class="col-md-4 sub_title">Job Location</div>
                  <div class="col-md-2">:</div>
                  <div class="col">{{user_details.job_location}}</div>
                </div>

                <div class="row">
                  <div class="col-md-4 sub_title">Minimum Qualification</div>
                  <div class="col-md-2">:</div>
                  <div class="col">{{user_details.min_qalification}}</div>
                </div>

                <div class="row">
                  <div class="col-md-4 sub_title">Number of openings</div>
                  <div class="col-md-2">:</div>
                  <div class="col">{{user_details.no_of_openings}}</div>
                </div>

                <div class="row">
                  <div class="col-md-4 sub_title">Salary</div>
                  <div class="col-md-2">:</div>
                  <div class="col">{{user_details.salary_low}} - {{user_details.salary_high}}</div>
                </div>

                <div class="row">
                  <div class="col-md-4 sub_title">Salary Type</div>
                  <div class="col-md-2">:</div>
                  <div class="col">{{user_details.salary_type}} </div>
                </div>

                <div class="row">
                  <div class="col-md-4 sub_title">Working Days</div>
                  <div class="col-md-2">:</div>
                  <div class="col">{{user_details.working_days}} </div>
                </div>

                <div class="row">
                  <div class="col-md-4 sub_title">Working Shift</div>
                  <div class="col-md-2">:</div>
                  <div class="col">{{user_details.working}} </div>
                </div>

                <div class="row">
                  <div class="col-md-4 sub_title">Languages</div>
                  <div class="col-md-2">:</div>
                  <div class="col" ><span *ngFor="let language of user_details.languages">{{language.title}} <span *ngIf="user_details.languages.length > 1">,</span></span></div>
                </div>
                <div class="row">
                  <div class="col-md-4 sub_title">Skills</div>
                  <div class="col-md-2">:</div>
                  <div class="col" ><span *ngFor="let skill of user_details.skills">{{skill.title}}   <span *ngIf="user_details.skills.length > 1">,</span> </span> </div>
                </div>

                <div class="row">
                  <div class="col-md-4 sub_title">Benfits</div>
                  <div class="col-md-2">:</div>
                  <div class="col" ><span *ngFor="let benifit of user_details.benifits">{{benifit.title}}   <span *ngIf="user_details.benifits.length > 1">,</span> </span> </div>
                </div>

                <div class="row">
                  <div class="col-md-4 sub_title">Job Description</div>
                  <div class="col-md-2">:</div>
                  <div class="col" > {{user_details.job_description}}</div>
                </div>
                <div class="row" *ngIf="user_details.cancel_reason">
                  <div class="col-md-4 sub_title">Reason for cancel</div>
                  <div class="col-md-2">:</div>
                  <div class="col" > {{user_details.cancel_reason}}</div>
                </div>

                </div>
            </div>
        </div>
    </div>
</jw-modal>
<!-- End Page -->
<style type="text/css">
.example-wrap {
    margin-bottom: 30px;
}

.disabledTable {
    pointer-events: none;
    opacity: 0.4;
}
</style>
