import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AlertService } from '../_services';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from '../_services/PaymentService';
import { ModalService } from '../_services';
declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-purchase-track',
  templateUrl: './purchase-track.component.html',
  styleUrls: ['./purchase-track.component.css']
})
export class PurchaseTrackComponent implements OnInit {


  loading = false;
 media_domain = ''

  submitted = false;
  //table = false;
payment_details:any;
  cancelButton = false;
  payments: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  width_limit = `${environment.bannerWidth}`;
  height_limit = `${environment.bannerHeight}`;
  curPage = 1;
  perPage = 10;


  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private paymentService: PaymentService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
  ) { }

  ngOnInit() {



    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "name"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };


    this.paymentService.getProducerPayment().subscribe(data => {

      this.payments = data.data.payments;


      this.dtTrigger.next();
    });

  }


  // convenience getter for easy access to form fields
  // get f() { return this.GenreForm.controls; }

  detailsPayment(payment) {

    this.payment_details = payment;


    this.modalService.open('payment_details');
  }
  closeModal(id: string) {

    this.modalService.close(id);
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }

}
