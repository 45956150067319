<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
    <div class="page-header">
        <h1 class="page-title">FAQs</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active">FAQs</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row privacy_main">
            <div class="col-md-6">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <!-- <header class="panel-heading">
                        <h3 class="panel-title">
                            FAQs
                        </h3>
                    </header> -->

                    <div class="panel-body">
                        <div class="row">
                            <form [formGroup]="faqForm" (ngSubmit)="faqSubmit()">
                                <div class="col-md-12">
                                    <label>Question</label>
                                    <input type="text" formControlName="question" class="form-control qust_cls" placeholder="Add question" autocomplete="off">
                                    <div *ngIf="f.question.errors?.required" class="invalid-feedback">
                                        <div *ngIf="f.question.errors?.required">Question is required</div>
                                    </div>
                                    <!-- <select formControlName="terms" class="form-control" disabled>
                                        <option></option>
                                        <option>Terms</option>
                                        <option>Policy</option>
                                       <option>Certified jewellery</option>
                                        <option>Shipping</option>
                                    </select> -->
                                    <br>
                                    <label>Answer</label>
                                 
                                    <angular-editor id="editor2" aria-placeholder="Enter answer here..."
                                    formControlName="answer" [config]="config"></angular-editor>
                                    <div *ngIf="f.answer.errors?.required" class="invalid-feedback">
                                        <div *ngIf="f.answer.errors?.required">Answer is required</div>
                                    </div>
                                    <button type="submit" class="btn btn-info mt-4 faq" *ngIf="!id">Save</button>
                                    <ng-container *ngIf="cancelButton">
                                   
                                    <button type="submit" class="btn btn-info mt-4 faq"  *ngIf="id">Update</button>
                                   
                                    </ng-container>
                                    <button type="button" class="btn btn-danger mt-4 ml-2"
                                    (click)="cancelClick()">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 new_s">
                <div class="panel">
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="tb-wrapper">
                                    <div class="table-responsive">
                                        <table cellspacing="0" id="table"
                                        class="table table-hover dataTable table-striped table-bordered faq_table w-full" datatable
                                        [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                        <thead>
                                            <tr>
                                                <th width="8%">Sl No.</th>
                                                <th width="10%">Question</th>
                                                <th width="50%">Answer</th>
                                                <th width="10%">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let privacy of privacy$;let j=index">
                                            

                                                    <td>{{j+1}}</td>
                                                    
                                                    <td><div class="qtn_cls">{{privacy.question}}</div></td>
                                                    <td><div class="ans_cls">{{privacy.answer}}</div></td>
                                                    <td>
                                                        <i style="cursor: pointer;" class="fa fa-edit text-success"
                                                        (click)="editPrivacy(privacy)" aria-hidden="true"></i>
                                                        <i
                                                        (click)="deleteFaq(privacy)" title="Delete"
                                                        class="fas fa-trash-alt dlt_cls"></i>
                                                    </td>
                                          
                                               
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>