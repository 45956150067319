import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { CategoryService, AlertService, ProductService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { data } from 'jquery';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-remarketingads',
  templateUrl: './remarketingads.component.html',
  styleUrls: ['./remarketingads.component.css']
})

export class RemarketingadsComponent implements OnInit {

  submitted = false;

  cancelButton = false;
  id: any;
  loading = false;

  privacy$: Observable<any[]>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  privacyForm: FormGroup;


  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],

    sanitize: true,
    toolbarPosition: 'top',
    uploadWithCredentials: false,
    toolbarHiddenButtons: [
      ['bold']
    ]
  }

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private bannersService: CategoryService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private pro: ProductService) { }

  ngOnInit() {

    this.privacyForm = this.formBuilder.group({
      id: [''],
      description: [''],
      //  terms: [''],
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Description"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };


    this.pro.getremarketing().subscribe(data => {
      this.privacy$ = data.data.details;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }

  mailSend(e) {
    e.stopPropagation();
    this.loading = true;
    this.pro.sendMail().subscribe(data => {
      var that = this;
      if (data.status.code == 0) {
        that.loading = false;
        that.toastr.success(data.status.message);
      } else {
        that.toastr.error(data.status.message);
      }
    })
  }

  
  get f() { return this.privacyForm.controls; }
  privacySubmit() {
    var that = this;
    if (that.id == null) {
      alertify.confirm('Are you sure want to create this record?', function () {
        that.loading = true;
        that.pro.addremarketing(that.f.description.value).subscribe(data => {
          if (data.status.code == 0) {
            that.toastr.success('Successfully created');
            location.reload();
          } else {
            that.loading = false;
            that.toastr.error(data.status.message);
          }
        }, error => {
          console.error(error);
          that.loading = false;
        });
      }, function () {
        that.loading = false;
        that.toastr.error('Action cancelled');
      });
    } else {
      alertify.confirm('Are you sure want to update this record?', function () {
        that.loading = true;
        that.pro.updateremarketing(that.id, that.f.description.value).subscribe(data => {
          if (data.status.code == 0) {
            that.toastr.success('Successfully updated');
            location.reload();
          } else {
            that.loading = false;
            that.toastr.error(data.status.message);
          }
        }, error => {
          console.error(error);
          that.loading = false;
        });
      }, function () {
        that.loading = false;
        that.toastr.error('Action cancelled');
      });
    }
  }


  editPrivacy(obj) {
    $('.new_s').addClass('disable');
    this.id = obj._id;
    this.cancelButton = true;
    this.privacyForm.patchValue({
      id: obj._id,
      description: obj.metatag
    });
  }


  cancelClick() {
    $('.new_s').removeClass('disable');
    this.cancelButton = false;
    this.id = '';
    this.privacyForm.patchValue({
      id: '',
      // terms: '',
      description: ''
    });
  }

  deleteRem(obj) {
    var that = this;
    alertify.confirm('Are you sure want to delete this record?', function () {
      that.pro.deleteRemark(obj._id).subscribe(data => {
        that.toastr.success('Delete Successfully');
        location.reload();
      }, error => {
        console.error(error);
      });
    }, function () {
      that.toastr.error('Action Cancelled');
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }


}
