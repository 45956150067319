import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductService, AlertService, BannersService, CategoryService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { toArray } from 'rxjs/operators';

declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var moment: any;

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.css']
})



export class OrderViewComponent implements OnInit {


  mainCategory = {

    displayKey: 'title', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select main category', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No main category found', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search main category',
    padding: '15px', // label thats displayed in search input,


    selectAllBtnText: 'Select',
    searchOnKey: 'title' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys

    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  subCategory = {

    displayKey: 'title', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select sub category', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No sub category found', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search sub category',
    padding: '15px', // label thats displayed in search input,


    selectAllBtnText: 'Select',
    searchOnKey: 'title' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys

    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  constructor(private route: ActivatedRoute, private bannerService: BannersService, private cat: CategoryService, private formBuilder: FormBuilder, private pro: ProductService, private toastr: ToastrService) { }
  routeSub: any;

  main$: Observable<any[]>;

  sub$: Observable<any[]>;

  product$: Observable<any[]>;

  main_list: any;

  main: any;
  sub: any;

  productForm: FormGroup;

  images: any;

  submitted = false;

  media_domain = `${environment.media_domain}`;

  weblink = `${environment.WEBLINK}`;

  productSingle$: any;

  curUrl: any;

  men_check: any;
  women_check: any;
  kids_check: any;
  in_stock: any;


  branchwidth_limit = `${environment.prodImgWidth}`;
  branchheight_limit = `${environment.prodImgHeight}`;


  new_promofile: any;
  images_array: any;

  ngOnInit() {
    $('.fff').show();
    this.curUrl = localStorage.getItem('currentUrl');

    this.productForm = this.formBuilder.group({
      orderid: [''],
      address: [''],
      net_weight: [''],
      extra_weight: [''],
      amount: [''],
      invoice_no: [''],
      city: [''],
      name: [''],
      state: [''],
      mobile_no: [''],
      district: [''],
      payment_link: ['']
    });

    this.routeSub = this.route.params.subscribe(params => {
      console.log(params['id']);

      this.pro.getSingleorder(params['id']).subscribe(data => {
        this.productSingle$ = data.data[0];
        this.productForm.patchValue({
          orderid: this.productSingle$.order_no,
          address: this.productSingle$.address,
          net_weight: this.productSingle$.product.net_weight,
          name: this.productSingle$.user.first_name,
          mobile_no:  this.productSingle$.user.mobile_no,
          invoice_no: this.productSingle$.invoice,
          city: this.productSingle$.city,
          state: this.productSingle$.state,
          district: this.productSingle$.district,
          extra_weight: this.productSingle$.extra_weight,
          amount: this.productSingle$.amount,
          payment_link: this.productSingle$.payment_link
          //certification_charges
        });
    
      }, error => {
        console.log(error);
      })
    });
  }


  get f() { return this.productForm.controls; }
  productSubmit() {
 
    this.submitted = true;
   
    // if (this.productForm.invalid) {
    //   return false;
    // }
    var that = this;
 
    alertify.confirm('Are you sure want to update this order-detals?', function () {
      that.pro.updateOrderview(
        that.productSingle$._id,
        that.f.extra_weight.value,
        that.f.amount.value,
        that.f.payment_link.value,
      ).subscribe(data => {
        if (data.status.code == 0) {
          that.toastr.success('Successfully updated');
          location.reload();
          location.href = '/orders';
        } else {
          that.toastr.error(data.status.message);
        }
      }, error => {
        console.error(error);
      });

    }, function () {
      that.toastr.error("Action cancelled");
    });

  }

  goBack() {
    this.curUrl = localStorage.getItem('currentUrl');
    location.href = this.curUrl;
  }



  cancelOp() {
    location.href = '/orders';
  }
}
