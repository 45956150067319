<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
    <div class="page-header">
        <h1 class="page-title">Video Calling</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Video Calling</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row videocall_main">
            <div class="col-xl-12">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <div class="abharan_main">
                        <!-- <header class="panel-heading">
                            <h3 class="panel-title">
                            Video Calling
                            </h3>
                        </header> -->

                        <div class="abharan_upload">
                            <div class="row">
                          <div class="col-sm-6"></div>
                          <div class="col-sm-1" class="search_txt">Search: </div>
                          <div class="col-sm-5"> <input (keyup)="search($event)" class="form-control" type="text" name="custid" placeholder="Search for name, email, mobile no" autocomplete="off"></div>
                            </div>
                        </div>
                        <div class="panel-body">
                            <div class="tb-wrapper">
                                <div class="table-responsive1">
                                    <table cellspacing="0" id="table"
                                        class="table table-hover dataTable table-striped table-bordered videocall_table w-full">
                                        <thead>
                                            <tr>
                                                <th>Sl.No.</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Number</th>
                                                <th>Selected Date</th>
                                                <th>Selected Language</th>
                                                <th>Selected Time</th>
                                                <th>Choosed Category</th>
                                                <th>Choosed Sub-Category</th>
                                                <th>City</th>
                                                <th>Message</th>
                                                <th>Status</th>
                                                <th>Video Call Confirmation</th>
                                                <th>Url</th>
                                                <th>Call Status</th>
                                                <th>View/Edit</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr *ngFor="let pro of products$;let i=index">
                                                <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                                <td>
                                                    <!-- {{pro && pro.name ? pro.name : "NA"}} -->
                                                    <ng-container *ngIf="pro.name; else no_name">
                                                        {{pro && pro.name}}</ng-container>
                                                    <ng-template #no_name>NA</ng-template>
                                                </td>
                                                <td>
                                                    <!-- {{pro && pro.email ? pro.email : "NA"}} -->
                                                    <ng-container *ngIf="pro.email; else no_email">
                                                        {{pro && pro.email}}</ng-container>
                                                    <ng-template #no_email>NA</ng-template>
                                                </td>
                                                <td>
                                                    <!-- {{pro && pro.mobile_number ? pro.mobile_number : "NA"}} -->
                                                    <ng-container *ngIf="pro.mobile_number ; else no_mobile_number">
                                                        {{pro && pro.mobile_number}}</ng-container>
                                                    <ng-template #no_mobile_number>NA</ng-template>

                                                </td>
                                                <td>
                                                    <!-- {{pro && pro.date ? pro.date : "NA"}} -->
                                                    <ng-container *ngIf="pro.date ; else no_date">
                                                        {{pro && pro.date}}</ng-container>
                                                    <ng-template #no_date>NA</ng-template>
                                                </td>
                                                <td>
                                                    <!-- {{pro && pro.language ? pro.language : "NA"}} -->
                                                    <ng-container *ngIf="pro.language ; else no_language">
                                                        {{pro && pro.language}}</ng-container>
                                                    <ng-template #no_language>NA</ng-template>

                                                </td>
                                                <td>
                                                    <!-- {{pro && pro.time ? pro.time : "NA"}} -->
                                                    <ng-container *ngIf="pro.time; else no_time">
                                                        {{pro && pro.time}}</ng-container>
                                                    <ng-template #no_time>NA</ng-template>
                                                </td>
                                                <td>
                                                    <!-- {{pro && pro.category ? pro.category : "NA"}} -->
                                                    <ng-container *ngIf="pro.category ; else no_category">
                                                        {{pro && pro.category}}</ng-container>
                                                    <ng-template #no_category>NA</ng-template>
                                                </td>
                                                <td>
                                                    <ng-container *ngIf="pro.sub_category; else no_sub_category">
                                                        {{pro && pro.sub_category}}</ng-container>
                                                    <ng-template #no_sub_category>NA</ng-template>
                                                    <!-- {{pro && pro.sub_category ? pro.sub_category : "NA"}} -->
                                                </td>
                                                <td>
                                                    <!-- {{pro && pro.city ? pro.city : "NA"}} -->
                                                    <ng-container *ngIf="pro.city; else no_city">
                                                        {{pro && pro.city}}</ng-container>
                                                    <ng-template #no_city>NA</ng-template>
                                                </td>
                                                <td>
                                                    <ng-container *ngIf="pro.message; else no_message">
                                                        <span class="desc_cls"> {{pro && pro.message}}</span></ng-container>
                                                    <ng-template #no_message>NA</ng-template>
                                                    <!-- {{pro && pro.message ? pro.message : "NA"}} -->
                                                </td>
                                                <td>
                                                    <ng-container *ngIf="pro.status ; else no_status">
                                                        {{pro && pro.status }}</ng-container>
                                                    <ng-template #no_status>NA</ng-template>
                                                    <!-- {{pro && pro.status ? pro.status : "NA"}} -->
                                                </td>
                                             
                                                <td>
                                                    <button *ngIf="pro.confirm_status == 0" (click)="makeOrderConfirm($event,pro)" class="btn btn-success btn-sm">Confirm</button>
                                                    <span *ngIf="pro.confirm_status != 0">-</span>
                                                </td>
                                                <td><span class="desc_cls">
                                                    {{pro && pro.url ? pro && pro.url : '-' }}
                                                </span></td>
                                                <td *ngIf="pro && pro.call_status == 1">
                                                    {{pro && pro.call_status == 1 ? 'Done' : 'Pending'}}
                                                </td>
                                                <td *ngIf="pro && pro.call_status == 0"><a style="cursor:pointer" (click)="callStatus(pro)">
                                                    Pending
                                                </a> </td>
                                                <td><a href="/video-view/{{pro._id}}" class="view_btn">View/Edit</a>
                                                </td>
                                                <td><a (click)="deleteProduct($event,pro._id)"
                                                        class="view_btn delete">Delete</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="row paginate">
                                        <div class="col-sm-8">
                                        <div class="shloka_result" *ngIf="products$ != 0">                         
          Total :  {{total}}  &nbsp; 
           Showing &nbsp; {{from}} - {{to}}                     
                                       </div>
                                          </div>
                                       <div class="col-sm-4" *ngIf="products$ && products$.length">
                                        <div class="temple_pagination pg_cls">
                                            <a class="backward" [ngClass]="{'disable':curPage == 1}" (click)="pageChange(curPage-1)"><i
                                                    class="fas fa-step-backward"></i></a> Page
                                            <span>{{curPage}}</span>of {{last_page}}<a [ngClass]="{'disable':curPage==last_page}"
                                                class="forward" (click)="pageChange(curPage+1)"><i class="fas fa-step-forward"></i></a>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
    </div>
</div>
<script src="/assets/js/bootstrap-table.js"></script>
<!-- End Page -->
<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }
</style>

<!-- Modal -->
<div class="modal fade image_view" id="exampleModalCenter" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4>Video Link</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="bannerForm" #myForm="ngForm" (ngSubmit)="onSubmit(bannerForm, myForm)"
                class="">
                <div class="row">
                   
                    <div class="col-md-12 colss">
                        <div class="mb-15">
                            <label>Meeting Link</label>
                            <input autocomplete="off" type="text" class="form-control" formControlName="meeting_link"
                                [ngClass]="{ 'is-invalid': (f.meeting_link.touched || submitted) && f.meeting_link.errors }"
                                placeholder="Meeting link">
                            <div *ngIf="(f.meeting_link.touched || submitted) && f.meeting_link.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="f.meeting_link.errors?.required">Meeting link is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 colss">
                        <button type="submit" class="btn btn-success btn-sm">Submit</button>
                    </div>
                   
                </div>
            </form>
            </div>
        </div>
    </div>
</div>