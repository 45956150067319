import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HomeService } from '../_services';
import { environment } from '../../environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
declare var jquery: any;
declare var moment: any;
declare var $: any;

declare var moment: any;
@Component({
  templateUrl: 'whatsppclick.component.html',
  styleUrls: ['./whatsppclick.component.css']
})
export class WhatsppclickComponent implements OnInit, AfterViewInit {
  //@ViewChild('gmap') gmapElement: ElementRef;
  stats$: any

  vendorConfig = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: '250px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Vendor', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No vendor found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search Vendor', // label thats displayed in search input,
    searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  groundConfig = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: '250px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Ground', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No ground found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search Ground', // label thats displayed in search input,
    searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  role = localStorage.getItem(`${environment.appName}` + '_role');
  adminName = localStorage.getItem(`${environment.appName}` + '_adminName');
  status: string;

  vendors: any;
  grounds: any;
  searchForm: FormGroup;
  user: any;
  order: any;
  order_amount: any;
  daily_rates: any;
  year = '';
  loading = false;
  month = '';
  gold_18: any;
  gold_22: any;
  silver: any;
  perPage = 10;
  orders: any;
  curPage = 1;
  products: any;
  ord_amount: any;
  wcount$:any;
  from:any;
  to:any;
  last_page:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  allmonths = [
    {name:'JANUARY',key:0},
    {name:'FEBRUARY',key:1},
    {name:'APRIL',key:2},
    {name:'MARCH',key:3},
    {name:'APRIL',key:4},
    {name:'JUNE',key:5},
    {name:'JULY',key:6},
    {name:'AUGUST',key:7},
    {name:'SEPTEMBER',key:8},
    {name:'OCTOBER',key:9},
    {name:'NOVEMBER',key:10},
    {name:'DECEMBER',key:10}]
total:any;
    selectedYear: number;
    years: number[] = [];  
  constructor(
    private formBuilder: FormBuilder,
    private homeService: HomeService,
    private http: HttpClient,
  ) {
  
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year >= 2022; year--) {
      this.years.push(year);
    }

  }
  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  ngOnInit() {


    // var that = this;
    // this.searchForm = this.formBuilder.group({
    //   book: '',
    //   vendors: '',
    //   grounds: ''
    // });
    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
    };
this.loading = true;
    this.homeService.getWhatsapp('','','').subscribe(data => {
      this.loading = false;
      this.wcount$ = data.data.items.data;
      this.total= data.data.items.total;
      this.from = data.data.items.from;
      this.to = data.data.items.to;
      this.last_page = data.data.items.last_page;
      this.curPage = data.data.items.current_page;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });



  
    //if (localStorage.getItem(`${environment.appName}` + '_role') == "99") {

    // }
  }



  pageChange(e){
    this.loading = true;
    this.homeService.getWhatsapp(this.year,this.month, e).subscribe(data => {
      this.loading = false;
      this.wcount$ = data.data.items.data;
      this.total= data.data.items.total;
      this.from = data.data.items.from;
      this.to = data.data.items.to;
      this.last_page = data.data.items.last_page;
      this.curPage = data.data.items.current_page;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }


  searchFilter(search,type){
    if(type=='year'){
      this.year = search;
      this.loading = true;

      this.homeService.getWhatsapp(this.year,'', this.curPage).subscribe(data => {
        this.loading = false;
        this.wcount$ = data.data.items.data;
        this.total= data.data.items.total;
        this.from = data.data.items.from;
        this.to = data.data.items.to;
        this.last_page = data.data.items.last_page;
        this.curPage = data.data.items.current_page;
        this.dtTrigger.next();
      }, error => {
        console.error(error);
      });
    }

    if(type=='month'){
      this.month = search;
      this.loading = true;
      this.homeService.getWhatsapp('',this.month,this.curPage).subscribe(data => {
        this.loading = false;
        this.wcount$ = data.data.items.data;
        this.total= data.data.items.total;
        this.from = data.data.items.from;
        this.to = data.data.items.to;
        this.last_page = data.data.items.last_page;
        this.curPage = data.data.items.current_page;
        this.dtTrigger.next();
      }, error => {
        console.error(error);
      });
    }

   
    
//    var table = $('#table').DataTable();
 //   table.draw();
  }

  get f() { return this.searchForm.controls; }



reset(e){
location.reload();
}



  filterDash() {
    var that = this;
    this.homeService.get().subscribe(data => {
      this.stats$ = data.data;
      // this.vendors = data.data.vendors;
    });
  }
 



  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }

}
