// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appName: "populargold",
  production: true,
  // apiUrl: 'http://35.154.176.141:4000/',
  apiUrl: 'https://api.populargold.in/',
  // apiUrl: 'http://192.168.0.133:8000/',
  oneSignalAppId: 'b48d5f14-a656-4383-b34e-725affb0180f', //dev
  media_domain: 'https://api.populargold.in/images/pro_image/',
  apiPrefix: 'api/v1',
  imgWidth: '500',
  imgHeight: '500',
  servImgWidth: '720',
  servImgHeight: '480',
  servCovImgWidth: '360',
  servCovImgHeight: '200',
  banImgWidth: '64',
  banImgHeight: '64',
  gameTypeWidth: '72',
  gameTypeHeight: '72',
  bannerWidth: '1920',
  bannerHeight: '560',
  subcatWidth: '330',
  subcatHeight: '310',
  maincatWidth: '421',
  maincatHeight: '211',
  iconWidth: '96',
  iconHeight: '96',
  branchWidth: '264',
  branchHeight: '264',
  prodImgWidth: '1500',
  prodImgHeight: '1500',
  promotionWidth: '1920',
  promotionHeight: '560',
  whatsnewWidth1: '236',
  whatsnewHeight1: '349',
  whatsnewWidth2: '236',
  whatsnewHeight2: '349',
  WEBLINK: 'https://populargold.in/'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
