import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class BannersService {
    constructor(private http: HttpClient) { }

    save(name: string, url: string, status: string, image: File) {
        const formData: FormData = new FormData();
        formData.append('image', image, image.name);
        status = status ? '1' : '0';
        formData.append('active', status);
        formData.append('title', name);
        formData.append('link', url);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/site/banner`, formData).pipe(map(user => {
            return user;
        }));

    }

    update(id: string, name: string, url: string, status: string, image: File) {
        const formData: FormData = new FormData();
        if (image) {
            formData.append('image', image, image.name);
        }
        status = status ? '1' : '0';
        formData.append('active', status);
        formData.append('title', name);
        formData.append('link', url);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/site/banner/` + id, formData).pipe(map(user => {
            return user;
        }));

    }


    updateProductBanner(id: string, name: string, status: string, image: File) {
        const formData: FormData = new FormData();
        if (image) {
            formData.append('image', image, image.name);
        }
        status = status ? '1' : '0';
        formData.append('active', status);
        formData.append('title', name);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/abharana/updateProductBanner/` + id, formData).pipe(map(user => {
            return user;
        }));

    }


    addProductBanner(name: string, status: string, image: File) {
        const formData: FormData = new FormData();
        if (image) {
            formData.append('image', image, image.name);
        }
        status = status ? '1' : '0';
        formData.append('active', status);
        formData.append('title', name);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/abharana/addProductBanner`, formData).pipe(map(user => {
            return user;
        }));

    }


    delete(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/site/banner/` + id).pipe(map(user => {
            return user;
        }));

    }
    getBanners(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/site/banner`).pipe(map(user => user));

    }


    getPromotions(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/site/promotion`).pipe(map(user => user));
    }


    savePromo(name: string, status: string, image: File) {
        const formData: FormData = new FormData();
        formData.append('image', image, image.name);
        status = status ? '1' : '0';
        formData.append('active', status);
        formData.append('title', name);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/site/promotion`, formData).pipe(map(user => {
            return user;
        }));

    }

    uploadImage(image: File) {
        const formData: FormData = new FormData();
        formData.append('image', image, image.name);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/site/promotion`, formData).pipe(map(user => {
            return user;
        }));

    }

    updatePromo(id: string, name: string, status: string, image: File) {
        const formData: FormData = new FormData();
        if (image) {
            formData.append('image', image, image.name);
        }
        status = status ? '1' : '0';
        formData.append('active', status);
        formData.append('title', name);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/site/promotion/` + id, formData).pipe(map(user => {
            return user;
        }));

    }

    promoDelete(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/site/promotion/` + id).pipe(map(user => {
            return user;
        }));

    }

    submitDailyRate(gold22, gold18, silver) {
        const formData: FormData = new FormData();
        formData.append('gold22', gold22);
        formData.append('gold18', gold18);
        formData.append('silver', silver);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/site/daily_rate`, formData).pipe(map(user => {
            return user;
        }));
    }

    //getRate
    getDaily(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/site/getRate`).pipe(map(user => {
            return user;
        }));

    }

    addMeta(url, title, desc, keyword) {
        const formData: FormData = new FormData();
        formData.append('url', url);
        formData.append('meta_title', title);
        formData.append('desc', desc);
        formData.append('meta_keyword', keyword);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/metaData`, formData).pipe(map(user => {
            return user;
        }));
    }

    updateMeta(id, url, title, desc, keyword) {
        const formData: FormData = new FormData();
        formData.append('url', url);
        formData.append('meta_title', title);
        formData.append('desc', desc);
        formData.append('meta_keyword', keyword);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/meta/updateMeta/` + id, formData).pipe(map(user => {
            return user;
        }));
    }

    matraBannerDelete(id) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/abharana/deleteProductBanner/` + id).pipe(map(user => {
            return user;
        }));
    }


    getMeta(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/getMeta`).pipe(map(user => {
            return user;
        }));

    }

    getMantra(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/abharana/productBanner`).pipe(map(user => {
            return user;
        }));

    }


    metaDelete(id) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/meta/deleteMetaData/` + id).pipe(map(user => {
            return user;
        }));

    }


    addBranches(image: File, image2: File, image3: File, image4: File, title, contact1, contact2, address, email, lat, long,city,state,district,from, to) {
        const formData: FormData = new FormData();
        if (image) {
            formData.append('image', image, image.name);
        }
        if (image2) {
            formData.append('image2', image2, image2.name);
        }
        if (image3) {
            formData.append('image3', image3, image3.name);
        }
        if (image4) {
            formData.append('image4', image4, image4.name);
        }

        formData.append('title', title);
        formData.append('contact1', contact1);
        formData.append('contact2', contact2);
        formData.append('address', address);
        formData.append('email', email);
        formData.append('lat', lat);
        formData.append('long', long);
        formData.append('city', city);
        formData.append('state', state);
        formData.append('district', district);
        formData.append('from', from);
        formData.append('to', to);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/branches/addBranch`, formData).pipe(map(user => {
            return user;
        }));

    }

    getBranch(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/branches/getBranch`).pipe(map(user => {
            return user;
        }));
    }

    branchDelete(id) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/branches/deleteBranch/` + id).pipe(map(user => {
            return user;
        }));
    }

    updateBranch(id, image: File, image2: File, image3: File, image4: File, title, contact1, contact2, address, email, lat, long,city,state,district, from, to) {
        const formData: FormData = new FormData();
        if (image) {
            formData.append('image', image, image.name);
        }
        if (image2) {
            formData.append('image2', image2, image2.name);
        }
        if (image3) {
            formData.append('image3', image3, image3.name);
        }
        if (image4) {
            formData.append('image4', image4, image4.name);
        }
        formData.append('title', title);
        formData.append('contact1', contact1);
        formData.append('contact2', contact2);
        formData.append('address', address);
        formData.append('email', email);
        formData.append('lat', lat);
        formData.append('long', long);
        formData.append('city', city);
        formData.append('state', state);
        formData.append('district', district);

        formData.append('from', from);
        formData.append('to', to);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/branches/updateBranch/` + id, formData).pipe(map(user => {
            return user;
        }));

    }


    updateStatus(id, status:any) {
        const formData: FormData = new FormData();
        formData.append('status', status);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/video/` + id, formData).pipe(map(user => {
            return user;
        }));
    }


    getVideo(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/getvideo`).pipe(map(user => {
            return user;
        }));
    }
}
