<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>

<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Orders</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Orders</li>
        </ol>
    </div>
    <div class="container">
        <div class="page-content container-fluid">
            <div class="row" style="top: 10px; position: relative;">
                
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" style="background-color: #fff;" id="home" role="tabpanel"
                        aria-labelledby="home-tab">
                        <div class="row">
                            <div class="col-md-3">
                                <h4 class="ng-binding"> Online Order ({{orders$?.length}})</h4>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table dataTable table-bordered" datatable
                                [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                <thead>
                                    <tr>
                                        <th width="7%">Sl. No.</th>
                                        <th width="10%">Order-ID</th>
                                        <th width="10%">Invoice No</th>
                                        <th width="5%"> Name</th>
                                        <th width="10%">Mobile </th>
                                        <th width="15%">Address</th>
                                        <th width="10%">City</th>
                                        <th width="10%">State</th>
                                        <th width="10%">District</th>
                                        <th width="5%">Qty</th>
                                        <!-- <th width="30%">Order Confirm</th> -->
                                        <th width="12%">Price</th>
                                        <th width="25%">Date</th>
                                        <th width="15%">Order Mode</th>
                                        <th width="15%">Action</th>
                                        <th width="15%">Print</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="orders$?.length > 0; else no_order">
                                    <tr class="text-right" *ngFor="let order of orders$;let j=index">
                                        <td>{{j+1}}</td>
                                        <td>{{order.order_no?order.order_no:'NA'}}</td>
                                        <td>{{order.invoice?order.invoice:'NA'}}</td>
                                        <td>{{order.recpnt_name?order.recpnt_name:'NA'}}</td>
                                        <td>{{order.mobile?order.mobile:'NA'}}</td>
                                        <td>{{order.address?order.address:'NA'}}</td>
                                        <td>{{order.city?order.city:'NA'}}</td>
                                        <td>{{order.state?order.state:'NA'}}</td>
                                        <td>{{order.district?order.district:'NA'}}</td>
                                        <td>{{order.quantity?order.quantity:'NA'}}</td>
                                        <!-- <td>
                                            <button  *ngIf="!order.status" (click)="makeOrderConfirm($event,order)" class="btn btn-success btn-sm">Confirm</button>
                                            <span *ngIf="order.status">-</span>
                                        </td> -->
                                        <td>
                                            <ng-container *ngIf="order.payments;else no_amt">
                                            {{order.payments.amount?order.payments.amount:'NA'}}
                                            </ng-container>
                                            <ng-template #no_amt>-</ng-template>
                                        </td>
                                        <td style="width: 100px;">{{order.created_at?order.created_at:'NA'}}</td>
                                        <td>{{order.payment_mode=='1'?'Online':'COD'}}</td>
                                        <td>
                                            <a (click)="userEdit($event, order)" href="javascript:void(0);"
                                            class="btn btn-sm btn-icon btn-pure btn-default on-default edit-row"
                                            data-toggle="tooltip" *ngIf="order.is_outof_stock == 1"><i class="icon md-edit text-success"
                                                aria-hidden="true"></i>
                                        </a>
                                        <span *ngIf="order.is_outof_stock == 0">
                                            -
                                        </span>
                                    </td>
                                        <td (click)="pdfDownload($event,order)"><a><i style="color:crimson;"
                                            class="fa fa-print"></i></a></td>
                                    </tr>
                                    </ng-container>
                                    <ng-template #no_order>
                                    <tr class="text-center">
                                        <td colspan="29">No orders to display</td>
                                    </tr>
                                    </ng-template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <div class="row">
                            <div class="col-md-3">
                                <h4 class="ng-binding"> COD Order ()</h4>
                            </div>
                        </div>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th width="7%">Sl. No.</th>
                                    <th width="10%">Name</th>
                                    <th width="5%"> Product Name</th>
                                    <th width="10%">Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-center ng-hide" ng-hide="cod.length">
                                    <td colspan="29">No orders to display</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <div class="row">
                            <div class="col-md-3">
                                <h4 class="ng-binding"> Missed Order ({{orders$?.length}})</h4>
                            </div>
                        </div>
                        <table class="table dataTable table-bordered" datatable
                            [dtOptions]="dtOptions" [dtTrigger]="dtTrigger2">
                            <thead>
                                <tr>
                                    <th>Sl. No.</th>
                                    <th>Name</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Product Name</th>
                                    <th>Quantity</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let order of orderss$;let n=index">
                                        <td>{{n+1}}</td>
                                        <td>{{order.user.first_name?order.user.first_name:'NA'}}</td>
                                        <td>{{order.user.mobile_no?order.user.mobile_no:'NA'}}</td>
                                        <td>{{order.user.email?order.user.email:'NA'}}</td>
                                        <td>{{order.product.name?order.product.name:'NA'}}</td>
                                        <td>{{order.quantity?order.quantity:'NA'}}</td>
                                    </tr>
                                    <!-- <tr class="text-center ng-hide" ng-hide="missed.length">
                                        <td colspan="29">No orders to display</td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>