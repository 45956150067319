import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { SiteHeaderComponent } from './_layouts/site-header/site-header.component';
import { routing } from './app.routing';

import { RouterModule } from '@angular/router';

import { ModalComponent } from './_directives';
import { AuthSuperadminGuard, AuthVendorGuard } from './_guards';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
// tslint:disable-next-line: max-line-length
import { BannersService,DeliveryService, AlertService,WhatsnewService,VideoCallingService, VideoService, AuthenticationService, HomeService, ModalService, LanguageService, QualificationService, RoleService, SkillService, IndustryService, Designationservice, CategoryService, ProductService, SettingsService } from './_services';
import { HomeComponent } from './home';
import { WhatsppclickComponent } from './whatsppclick';
import { LoginComponent } from './login';
import { DataTablesModule } from 'angular-datatables';
import { UserComponent } from './users'
import { BannersComponent } from './banners';
 
import { LSelect2Module } from 'ngx-select2';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPrintModule } from 'ngx-print';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';

import { PaymentComponent } from './payment/payment.component'
import { PaymentService } from './_services/PaymentService';;
// tslint:disable-next-line: import-spacing
import { PurchaseTrackComponent } from './purchase-track/purchase-track.component'
    ;

;
// tslint:disable-next-line: import-spacing
import { ConsultantComponent } from './consultant/consultant.component';
;
import { LanguagesComponent } from './languages/languages.component'
import { SkillsComponent } from './skills/skills.component';
import { RolesComponent } from './roles/roles.component';
import { QualificationsComponent } from './qualifications';
import { DesignationsComponent } from './designations/designations.component';
import { IndustriesComponent } from './industries/industries.component';
// tslint:disable-next-line: import-spacing
import { JobsComponent } from './jobs/jobs.component';
;

import { ProductComponent } from './product/product.component'
    ;
import { ProductViewComponent } from './product-view/product-view.component';;
import { PromotionComponent } from './promotion/promotion.component'
    ;
import { DailyRatesComponent } from './daily-rates/daily-rates.component'
    ;
import { MetaComponent } from './meta/meta.component'
    ;
import { MainCategoryComponent } from './main-category/main-category.component'
    ;
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { ProductImagesComponent } from './product-images/product-images.component';

import { BranchesComponent } from './branches/branches.component';
import { PromocodeComponent } from './promocode/promocode.component';;
import { FeedbackComponent } from './feedback/feedback.component'
    ;
import { EnquiriesComponent } from './enquiries/enquiries.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ProductOfferComponent } from './product-offer/product-offer.component';
import { WebsiteProductsComponent } from './website-products/website-products.component';
import { DeleteProductsComponent } from './delete-products/delete-products.component';
import { OrdersComponent } from './orders/orders.component';
import { SettingsComponent } from './settings/settings.component';
import { EmailComponent } from './email/email.component';
import { BackchainComponent } from './back-chain/back-chain.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { MantraBannerComponent } from './mantra-banner/mantra-banner.component';
import { OfferProductsComponent } from './offer-products/offer-products.component';
import { MissedOrdersComponent } from './missed-orders/missed-orders.component';;
import { ScheemComponent } from './scheem/scheem.component'
;
import { VideoSchedulerComponent } from './video-scheduler/video-scheduler.component';
import { RemarketingadsComponent } from './remarketingads/remarketingads.component';
import { OutofstockComponent } from './outofstock/outofstock.component'    ;
import { FaqComponent } from './faq/faq.component';
import { DeliveryStateComponent } from './del_states/del_states.component'
import { DeliveryDistrictComponent } from './del_dist/del_dist.component'
import { WhatsnewComponent } from './whatsnew/whatsnew.component';
import { VideoCallingComponent } from './video-calling/video-calling.component';
import { VideoViewComponent } from './video-view/video-view.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { DatePipe } from '@angular/common';
import { MostViewedComponent } from './most-viewed/most-viewed.component';
import { ApproveTransactionComponent } from './approve-transaction/approve-transaction.component';
import { ReportProblemComponent } from './report-problem/report-problem.component';
import { FeedbackquestionComponent } from './feedback-question/feedback-question.component';
import { UserFeedbackComponent } from './user-feedback/user-feedback.component';
import { OrderViewComponent } from './order-view/order-view.component';  
import { ProductclickComponent } from './productclick/productclick.component';
import { VideorequestComponent } from './video-request/video-request.component';

@NgModule({
    imports: [
        RouterModule,
        BrowserModule,
        ReactiveFormsModule,
        AngularMyDatePickerModule,
        FormsModule,
        HttpClientModule,
        routing,
        DataTablesModule,
        LSelect2Module,
        SelectDropDownModule,
        NgxPrintModule,
        BrowserAnimationsModule,
        NgxPaginationModule,
        AngularEditorModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circleSwish,
            backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            backdropBorderRadius: '4px',
            primaryColour: '#ffffff',
            secondaryColour: '#ffffff',
            tertiaryColour: '#ffffff'
        }),
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        })
    ],
    declarations: [
        AppComponent,
        ModalComponent,
        HomeComponent,
        VideoSchedulerComponent,
        LoginComponent,
        BannersComponent,
        SiteHeaderComponent,
        FeedbackquestionComponent,
        UserFeedbackComponent,
        OrderViewComponent,
        ProductclickComponent,
        VideorequestComponent,
        FaqComponent,
        WhatsppclickComponent,
        RemarketingadsComponent,
        UserComponent,
        PaymentComponent,
        PurchaseTrackComponent,
        WhatsnewComponent,
        ConsultantComponent,
        LanguagesComponent,
        SkillsComponent,
        RolesComponent,
        VideoCallingComponent,
        QualificationsComponent,
        DesignationsComponent,
        VideoViewComponent,
        MostViewedComponent,
        ApproveTransactionComponent,
        IndustriesComponent,
        JobsComponent,
        ProductComponent,
        ProductViewComponent,
        PromotionComponent,
        BackchainComponent,
        DailyRatesComponent,
        MetaComponent,
        MainCategoryComponent,
        SubCategoryComponent,
        ProductImagesComponent,
        BranchesComponent,
        DeliveryStateComponent,
        PromocodeComponent,
        FeedbackComponent,
        EnquiriesComponent,
        PrivacyComponent,
        ProductOfferComponent,
        DeliveryDistrictComponent,
        WebsiteProductsComponent,
        DeleteProductsComponent,
        OrdersComponent,
        SettingsComponent,
        EmailComponent,
        MantraBannerComponent,
        OfferProductsComponent,
        MissedOrdersComponent,
        ScheemComponent,
        ReportProblemComponent,
        OutofstockComponent],

    providers: [
        DatePipe,
        AuthSuperadminGuard,
        AuthVendorGuard,
        AlertService,
        AuthenticationService,
        SettingsService,
        HomeService,
        ModalService,
        BannersService,
        PaymentService,
        LanguageService,
        QualificationService,
        RoleService,
        SkillService,
        IndustryService,
        Designationservice,
        DeliveryService,
        CategoryService,
        ProductService,WhatsnewService,VideoCallingService, VideoService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
