import { Component, OnInit } from '@angular/core';
import { ProductService, AlertService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';


declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-approve-transaction',
  templateUrl: './approve-transaction.component.html',
  styleUrls: ['./approve-transaction.component.css']
})
export class ApproveTransactionComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  approvedTranscation$: any;
  length: any;
  media_domain = `${environment.media_domain}`;
  total: any;
  from: any;
  to: any;
  image: any;
  last_page: any;
  curPage = 1;
  loading = false;
  constructor(private pro: ProductService, private toastr: ToastrService) { }

  ngOnInit() {
    //$('#exampleModalCenter').modal('hide');
    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };
this.loading = true;
    this.pro.getApprovedTranscation('').subscribe(data => {
      this.loading = false;
      this.approvedTranscation$ = data.data.trans_details.data;
      this.total = data.data.trans_details.total;
      this.from = data.data.trans_details.from;
      this.to = data.data.trans_details.to;
      this.last_page = data.data.trans_details.last_page;
      this.curPage = data.data.trans_details.current_page;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }


  viewImage(transaction) {
    $('#exampleModalCenter').modal('show');
    this.image = transaction.image;
  }


  pageChange(e) {
    this.loading = true;
    this.pro.getApprovedTranscation(e).subscribe(data => {
      this.loading = false;
      this.approvedTranscation$ = data.data.trans_details.data;
      this.total = data.data.trans_details.total;
      this.from = data.data.trans_details.from;
      this.to = data.data.trans_details.to;
      this.last_page = data.data.trans_details.last_page;
      this.curPage = data.data.trans_details.current_page;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }


  clickFeedback(message) {
    $('.message_text').text(message);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }

  acceptTranscation(e) {
    this.pro.ApprovedTranscation(e, 1).subscribe(data => {
      // if(data.data.status == 0)
      // {
      this.ngOnInit();
      // }
    }, error => {
      console.error(error);
    })

  }

  rejectTranscation(e) {
    this.pro.ApprovedTranscation(e, -1).subscribe(data => {
      // if(data.data.status == 0)
      // {
      this.ngOnInit();
      // }
    }, error => {
      console.error(error);
    })
  }

  secrejectTranscation(e) {
    this.pro.ApprovedTranscation(e, -2).subscribe(data => {
      this.ngOnInit();
    }, error => {
      console.error(error);
    })
  }

}
