import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { SettingsService } from '../_services';
import { ToastrService } from 'ngx-toastr';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, AfterViewInit {
  settingForm: FormGroup;
  loading = false;
  submitted = false;
  isResetFlag: boolean = false;
  constructor(
    private setting: SettingsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  static areEqual(c: AbstractControl): ValidationErrors | null {
    const keys: string[] = Object.keys(c.value);
    for (const i in keys) {
      if (i !== '0' && c.value['new_password'] !== c.value['confirm_password']) {
        return { areEqual: true };
      }
    }
  }

  ngOnInit() {
    this.settingForm = this.formBuilder.group({
      upd_username: ['', [Validators.required, Validators.maxLength(100), Validators.email]],
      is_reset_password: '',
      upd_password: ['', Validators.required],
      passwords: new FormGroup({
        new_password: new FormControl(),
        confirm_password: new FormControl()
      }, SettingsComponent.areEqual)
    });
  }
  get f(): any {
    return this.settingForm.controls;
  }

  handleChange(event): void {
    const newPassCtrl = this.settingForm.controls['passwords'].get('new_password');
    const reTypePassCtrl = this.settingForm.controls['passwords'].get('confirm_password');

    if (event.target.checked) {
      newPassCtrl.setValidators([Validators.required, Validators.maxLength(100)]);
      newPassCtrl.updateValueAndValidity();
      reTypePassCtrl.setValidators([Validators.required, Validators.maxLength(100)]);
      reTypePassCtrl.updateValueAndValidity();

      this.isResetFlag = true;
      newPassCtrl.enable();
      reTypePassCtrl.enable();
    } else {
      newPassCtrl.clearValidators();
      newPassCtrl.updateValueAndValidity();
      reTypePassCtrl.clearValidators();
      reTypePassCtrl.updateValueAndValidity();

      this.isResetFlag = false;
      newPassCtrl.disable();
      reTypePassCtrl.disable();
    }
  }
  onSubmit(form, formWrap) {
    this.submitted = true;
    if (this.settingForm.invalid) {
      return;
    }

    document.body.classList.add('jw-modal-open');
    this.loading = true;
    this.setting.save(
      this.f.upd_username.value, this.f.is_reset_password.value, this.f.upd_password.value, this.f.passwords['controls'].new_password.value)
      .subscribe(
        data => {
          if (data.status.code == 0) {
            this.submitted = false;
            this.isResetFlag = false;
            document.body.classList.remove('jw-modal-open');
            this.loading = false;
            formWrap.reset();
            this.toastr.success(data.status.message)

            // alertify.success(data.status.message)
          } else {
            document.body.classList.remove('jw-modal-open');
            this.loading = false;
            this.toastr.error(data.status.message)
            // alertify.error(data.status.message)
          }
        },
        error => {
          this.toastr.error(error)
          // alertify.error(error)
          document.body.classList.remove('jw-modal-open');
          this.loading = false;
        });
  }
  ngAfterViewInit() {
    setTimeout(() => {
      $('.username, .password').val('');
    }, 500);
  }
}

