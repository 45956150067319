import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { CategoryService, AlertService, ProductService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})

export class PrivacyComponent implements OnInit {

  submitted = false;

  cancelButton = false;

  loading = false;

  privacy$: Observable<any[]>;
   dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  privacyForm: FormGroup;


config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    
    sanitize: true,
    toolbarPosition: 'top',
    uploadWithCredentials: false,
     toolbarHiddenButtons: [
      ['bold']
    ] 
    }

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private bannersService: CategoryService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private pro: ProductService) { }

  ngOnInit() {

    this.privacyForm = this.formBuilder.group({
      id: [''],
      description: [''],
      terms: [''],
    });

     this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Page"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };


    this.pro.getPrivacy().subscribe(data => {
      console.log(data);
      this.privacy$ = data.data;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }


  get f() { return this.privacyForm.controls; }
  privacySubmit() {
    console.log(this.privacyForm);
    var that = this;
    alertify.confirm('Are you sure want to update this record?', function () {
      that.loading = true;
      that.pro.submitPrivacy(that.f.id.value, that.f.description.value, that.f.terms.value).subscribe(data => {
        console.log(data);
        if (data.status.code == 0) {
          that.toastr.success('Successfully updated');
      location.reload();
        } else {
          that.loading = false;
          that.toastr.error(data.status.message);
        }
      }, error => {
        console.error(error);
        that.loading = false;
      });
    }, function () {
      that.loading = false;
      that.toastr.error('Action cancelled');
    });
  }


  editPrivacy(obj) {
    $('.new_s').addClass('disable');
    console.log(obj);
    this.cancelButton = true;
    this.privacyForm.patchValue({
      id: obj._id,
      terms: obj.terms,
      description: obj.description
    });
  }


  cancelClick() {
    $('.new_s').removeClass('disable');
    this.cancelButton = false;
    this.privacyForm.patchValue({
      id: '',
      terms: '',
      description: ''
    });
  }

   ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }


}
