import { Component, OnInit } from '@angular/core';
import { ProductService, AlertService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';


declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-report-problem',
  templateUrl: './report-problem.component.html',
  styleUrls: ['./report-problem.component.css']
})
export class ReportProblemComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  feedback$: Observable<any[]>;
  length:any;

  constructor(private pro: ProductService, private toastr: ToastrService) { }

  ngOnInit() {

    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };

    this.pro.getReport().subscribe(data => {
      console.log(data);
      this.feedback$ = data.data.report;
      //this.length=data.data.length;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    })
  }

  clickFeedback(message) {
    $('.message_text').text(message);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }

}
