import { Component, OnInit } from '@angular/core';
import { ProductService, AlertService, VideoCallingService, CategoryService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';


declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-video-calling',
  templateUrl: './video-calling.component.html',
  styleUrls: ['./video-calling.component.css']
})
export class VideoCallingComponent implements OnInit {

  constructor(private toastr: ToastrService, 
     private formBuilder: FormBuilder,
      private bannersService: CategoryService,
       private videoCallingService: VideoCallingService) { }
  fileToUpload: File = null;
  perPage = 10;
  products$: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  id:any;
  weblink = `${environment.WEBLINK}`;
  loading = false;
  sub$: any[];
  main$: any[];
  curPage = 1;
  from:any;
  to:any;
  total:any;
  last_page:any;
  submitted = false;
  bannerForm: FormGroup;
  subCategory = {};
  mainCat = {};


  ngOnInit() {
    localStorage.setItem('currentUrl', 'video-calling');
    this.bannerForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      _id: [{ value: null, disabled: true }],
      meeting_link: ['', [Validators.required]]
    });
this.loading = true;
    this.videoCallingService.getvideorequest('','').subscribe(data => {
      this.loading = false;
      this.products$ = data.data.data;
      this.total= data.data.total;
      this.from = data.data.from;
      this.to = data.data.to;
      this.last_page = data.data.last_page;
      this.curPage = data.data.current_page;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }

  get f(): any {
    return this.bannerForm.controls;
  }

  onSubmit(form, formWrap) {
    this.submitted = true;
  
    let id = form.getRawValue()._id;
    let index = form.getRawValue().index;
    if (this.bannerForm.invalid) {
      return;
    }

     
      alertify.confirm('Are you sure you want to confirm?',()=>{
        this.loading = true;
      this.videoCallingService.getvideoconfirm(this.id, this.f.meeting_link.value).subscribe(data=>{
          if(data.status.code==0){
            this.loading = false;
            this.toastr.success('Confirmed Successfully');
           location.reload();
         
          }else{
            this.toastr.error(data.status.message);
          }
        },()=>{
        });
      },()=>{
  
      });
  }


  callStatus(video){
    alertify.confirm('Are you sure you want to complete?',()=>{
    //  this.loading = false;
      this.videoCallingService.changecall(video._id).subscribe(data=>{
        //this.loading = true;
          if(data.status.code==0){
            this.loading = false;
            this.toastr.success('Video call is completed');
           location.reload();
          }else{
            this.toastr.error(data.status.message);
          }
        },()=>{
        });
      },()=>{
  
      });
  }

  makeRefresh(){
  //  this.loading = true;
    this.videoCallingService.getvideorequest('','').subscribe(data => {
      console.log(data);
      this.products$ = data.data;
      
      this.dtTrigger.next();
    });
  }

  makeOrderConfirm(e,obj){
    $('#exampleModalCenter').modal('show');
this.id = obj._id;
  }


  deleteProduct(e, id) {
    var that = this;
    alertify.confirm('Are you sure want to delete this record?', function() {
      that.videoCallingService.delete(id).subscribe(data => {
        that.loading = true;
        that.videoCallingService.getvideorequest(that.curPage,'').subscribe(data => {
          that.loading = false;
          that.products$ = data.data.data;
          that.total= data.data.total;
          that.from = data.data.from;
          that.to = data.data.to;
          that.last_page = data.data.last_page;
          that.curPage = data.data.current_page;
          that.dtTrigger.next();
        }, error => {
          console.error(error);
        });
      }, error => {
        console.error(error);
      });
    }, function() {
      that.toastr.error('Action Cancelled');
    });
  }

  pageChange(e){
    this.loading = true;
    this.videoCallingService.getvideorequest('',e).subscribe(data => {
      this.loading = false;
      this.products$ = data.data.data;
      
      this.total= data.data.total;
      this.from = data.data.from;
      this.to = data.data.to;
      this.last_page = data.data.last_page;
      this.curPage = data.data.current_page;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }

  search(e){
    this.loading = true;
    this.videoCallingService.getvideorequest(e.target.value,1).subscribe(data => {
      this.loading = false;
      this.products$ = data.data.data;
      this.total= data.data.total;
      this.from = data.data.from;
      this.to = data.data.to;
      this.last_page = data.data.last_page;
      this.curPage = data.data.current_page;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }

}
