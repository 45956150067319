<site-header></site-header>
<link rel="stylesheet" href="/assets/css/bootstrap-table.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<style type="text/css">
	img {
		height: auto;
		max-width: 100%;
	}

	.cell {
		display: table-cell;
	}

	.cell-fluid {
		width: 100%;
	}

</style>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
	<div class="page-header">
		<h1 class="page-title"> Promotion banners</h1>
		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a href='/home'>Home</a></li>
			<li class="breadcrumb-item active"> Promotion banners</li>
		</ol>
	</div>
	<div class="page-content container-fluid">
		<div class="row promotion_main">
			<div class="col-xl-12">
				<!-- Panel Kitchen Sink -->
				<div class="panel">
					<!-- <header class="panel-heading">
						<h3 class="panel-title">
							Promotion banners
						</h3>
					</header> -->

					<div class="panel-body">
						<div class="row">
							<div class="col-md-12">
								<div class="mb-15 news">
									<button type="" id="edit_new" class="btn btn-info mt-2 "
										(change)="handleFileInput($event, $event.target.files)">Upload<i
											class="fa fa-edit ml-1"></i>
										<small style="display: block">(Image Dimensions:
											{{promotionWidth_limit}}*{{promotionHeight_limit}})</small>

									</button>
									<input type="file" (change)="changeFile($event)" class="hidden" id="file_take">
									<button id="edit_new_delete" class="btn btn-danger mt-2"
										(click)="deleteImage(ids)">Delete<i class="fas fa-trash-alt ml-1"></i></button>
									<ng-container *ngIf="image;else no_image">
										<img id="img" src="{{media_domain}}{{image}}" class="img">
									</ng-container>
									<ng-template #no_image>
										<div class="text-center">
											<img class="img" style="height: 30%;width: 30%;"
												src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/600px-No_image_available.svg.png">
										</div>
									</ng-template>

								</div>


							</div>
						</div>
						<!-- End Panel Kitchen Sink -->
					</div>
				</div>
			</div>
			<script src="/assets/js/bootstrap-table.js"></script>
			<!-- End Page -->
			<style type="text/css">
				.example-wrap {
					margin-bottom: 30px;
				}

				.disabledTable {
					pointer-events: none;
					opacity: 0.4;
				}

			</style>
