<site-header></site-header>
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Out of Stock Products</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Out of Stock Products</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row">
            <div class="col-xl-12">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <div class="abharan_main">
                        <!-- <header class="panel-heading">
                            <h3 class="panel-title">
                                Out of Stock Products
                            </h3>
                        </header> -->

                        <div class="abharan_upload">
                            <div class="row">
                                <div class="col-sm-7">
                                    <div class="upload_left">
                                        <ul>
                                            <li><b>Upload Excel</b></li>
                                            <li>
                                                <div class="upload-input"><input type="file"
                                                        (change)="uploadExcel($event,$event.target.files)"
                                                        name="file-upload" id="upload_file"></div>
                                            </li>
                                            <li><input type="checkbox" class="mr-1" (change)="changeCheck($event)">Out of stock</li>
                                            <li>
                                                <button class="upload_btn" (click)="uploadExcelFile()">Upload</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-sm-5">
                                    <div class="upload_right">
                                        <ul>
                                            <li class="diamond">
                                                <a (click)="download()" style="cursor: pointer;">
                                                    <i class="fas fa-download"></i> Download Template
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- End Panel Kitchen Sink -->
            </div>
        </div>
    </div>
</div>
<script src="/assets/js/bootstrap-table.js"></script>

<!-- End Page -->
<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }

</style>
