import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class PaymentService {
    constructor(private http: HttpClient) { }


    getPayment(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/get_payment`).pipe(map(user => user));

    }

     getProducerPayment(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get_producer_payment`).pipe(map(user => user));

    }
}
