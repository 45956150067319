import { Component, OnInit } from '@angular/core';
import { ProductService, AlertService, BannersService, CategoryService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Placeholder } from '@angular/compiler/src/i18n/i18n_ast';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';

declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-most-viewed',
  templateUrl: './most-viewed.component.html',
  styleUrls: ['./most-viewed.component.css']
})
export class MostViewedComponent implements OnInit {
  submitted: boolean;
  searchForm: FormGroup;

  allmonths = [
    {name:'January',month:0},
    {name:'February',month:1},
    {name:'March',month:2},
    {name:'April',month:3},
    {name:'May',month:4},
    {name:'June',month:5},
    {name:'July',month:6},
    {name:'August',month:7},
    {name:'September',month:8},
    {name:'October',month:9},
    {name:'November',month:10},
    {name:'December',month:11}]

  constructor(private pro: ProductService, private http: HttpClient, private toastr: ToastrService, private bannersService: CategoryService) {
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year >= 2022; year--) {
      this.years.push(year);
    }
    
   }
  fileToUpload: File = null;
  perPage = 10;
  stock_filter = '';
  sku_filter = '';
  main_cat_filter: any;
  sub_cat_filter: any;
  year:any;
  month:any;
  products$ = [];
  products1$ = [];
  products2$ = [];

  media_domain = `${environment.media_domain}`;
  product_excel = '/product/excel/5f2cda916e5aa.xls';
  weblink = `${environment.WEBLINK}`;
  prod$:any;
  sub$: any;
  main$: any;

  curPage = 1;
  from:any;
  to:any;
  total:any;
  last_page:any;

  curPage1 = 1;
  from1:any;
  to1:any;
  total1:any;
  last_page1:any;

  curPage2 = 1;
  from2:any;
  to2:any;
  total2:any;
  last_page2:any;

  count = 0
  count1 = 0

  selectedYear: number;
  years: number[] = [];  

  loading = false;
 monthrray:any;

  ngOnInit() {
this.monthrray = this.allmonths;
    localStorage.setItem('currentUrl', 'product');

    this.pro.main('','').subscribe(data => {
      this.main$ = data.data.items.data;
      this.total= data.data.items.total;
      this.from = data.data.items.from;
      this.to = data.data.items.to;
      this.last_page = data.data.items.last_page;
      this.curPage = data.data.items.current_page;
      // this.dtTrigger.next();
    }, error => {
      console.error(error);
    });


    this.pro.sub('','').subscribe(data => {
      this.sub$ = data.data.items.data;
      this.total1= data.data.items.total;
      this.from1 = data.data.items.from;
      this.to1 = data.data.items.to;
      this.last_page1 = data.data.items.last_page;
      this.curPage1 = data.data.items.current_page;
      // this.dtTrigger.next();
    }, error => {
      console.error(error);
    });

    this.pro.prod('','').subscribe(data => {
      this.prod$ = data.data.items.data;
      this.total2 = data.data.items.total;
      this.from2 = data.data.items.from;
      this.to2 = data.data.items.to;
      this.last_page2 = data.data.items.last_page;
      this.curPage2 = data.data.items.current_page;
      // this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }


  pageChange(e){
    this.pro.main(this.year? this.year : '',e).subscribe(data => {
      this.main$ = data.data.items.data;
      this.total= data.data.items.total;
      this.from = data.data.items.from;
      this.to = data.data.items.to;
      this.last_page = data.data.items.last_page;
      this.curPage = data.data.items.current_page;
      // this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }


  pageChange1(e){
    this.pro.sub(this.year? this.year : '',e).subscribe(data => {
      this.sub$ = data.data.items.data;
      this.total1= data.data.items.total;
      this.from1 = data.data.items.from;
      this.to1 = data.data.items.to;
      this.last_page1 = data.data.items.last_page;
      this.curPage1 = data.data.items.current_page;
      // this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }

  pageChange2(e){
    this.pro.prod(this.year? this.year : '',e).subscribe(data => {
      this.prod$ = data.data.items.data;
      this.total2= data.data.items.total;
      this.from2 = data.data.items.from;
      this.to2 = data.data.items.to;
      this.last_page2 = data.data.items.last_page;
      this.curPage2 = data.data.items.current_page;
      // this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }



  searchFilter(search, type) {
    if (type == 'year') {
         this.year = search;
         this.pro.main(this.year,'').subscribe(data => {
         
          this.main$ = data.data.items.data;
          this.total= data.data.items.total;
          this.from = data.data.items.from;
          this.to = data.data.items.to;
          this.last_page = data.data.items.last_page;
          this.curPage = data.data.items.current_page;
        }, error => {
          console.error(error);
        });

        this.pro.sub(this.year,'').subscribe(data => {
          this.sub$ = data.data.items.data;
          this.total1= data.data.items.total;
          this.from1 = data.data.items.from;
          this.to1 = data.data.items.to;
          this.last_page1 = data.data.items.last_page;
          this.curPage1 = data.data.items.current_page;
        }, error => {
          console.error(error);
        });

        this.pro.prod(this.year,'').subscribe(data => {
          this.prod$ = data.data.items.data;
          this.total2= data.data.items.total;
          this.from2 = data.data.items.from;
          this.to2 = data.data.items.to;
          this.last_page2 = data.data.items.last_page;
          this.curPage2 = data.data.items.current_page;
        }, error => {
          console.error(error);
        });
    }

    // if (type == 'month') {
    //   this.month = search;
    //   this.pro.main(this.year ? this.year : '',this.month,'').subscribe(data => {
      
    //    this.main$ = data.data.items.data;
    //    this.total= data.data.items.total;
    //    this.from = data.data.items.from;
    //    this.to = data.data.items.to;
    //    this.last_page = data.data.items.last_page;
    //    this.curPage = data.data.items.current_page;
    //  }, error => {
    //    console.error(error);
    //  });

    //  this.pro.sub(this.year ? this.year : '',this.month,'').subscribe(data => {
    //    this.sub$ = data.data.items.data;
    //    this.total1= data.data.items.total;
    //    this.from1 = data.data.items.from;
    //    this.to1 = data.data.items.to;
    //    this.last_page1 = data.data.items.last_page;
    //    this.curPage1 = data.data.items.current_page;
    //  }, error => {
    //    console.error(error);
    //  });

    //  this.pro.prod(this.year ? this.year : '',this.month,'').subscribe(data => {
    //    this.prod$ = data.data.items.data;
    //    this.total2= data.data.items.total;
    //    this.from2 = data.data.items.from;
    //    this.to2 = data.data.items.to;
    //    this.last_page2 = data.data.items.last_page;
    //    this.curPage2 = data.data.items.current_page;
    //  }, error => {
    //    console.error(error);
    //  });

    // }
console.log(this.year)
console.log(this.month)
    //  this.pro.main(this.year,this.month,'').subscribe(data => {
      
    //   this.main$ = data.data.items.data;
    //   this.total= data.data.items.total;
    //   this.from = data.data.items.from;
    //   this.to = data.data.items.to;
    //   this.last_page = data.data.items.last_page;
    //   this.curPage = data.data.items.current_page;
    // }, error => {
    //   console.error(error);
    // });
 
 


    var table = $('#table').DataTable();
    table.draw();
    var table1 = $('#table1').DataTable();
    table.draw();
    var table2 = $('#table2').DataTable();
    table.draw();
  }


}
