<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page">
    <div class="page-header">
        <h1 class="page-title">Settings</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Settings</li>
        </ol>
        <!-- <div class="page-header-actions">
				<a class="btn btn-sm btn-primary btn-round" href='/services'>
					<i class="icon md-link" aria-hidden="true"></i>
					<span class="hidden-sm-down">Back</span>
				</a>
		</div> -->
    </div>
    <div class="page-content">
        <div class="panel">
            <div class="panel-body container-fluid">
                <div class="row row-lg settings_main">
                    <div class="col-md-6">
                        <form [formGroup]="settingForm" #myForm="ngForm" (ngSubmit)="onSubmit(settingForm, myForm)"
                            class="">
                            <!-- Example Basic Form (Form row) -->
                            <div class="example-wrap">
                                <!-- <h4 class="example-title">Change Email/ Password</h4> -->

                                <div class="example">
                                    <div class="example-wrap">
                                        <label class="form-control-label" for="inputUserName">Email</label>
                                        <input autocomplete="off" type="text" class="form-control username"
                                            id="inputUserName" formControlName="upd_username" onload="this.value=''"
                                            placeholder="Email" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.upd_username.touched || submitted) && f.upd_username.errors }" />
                                        <div *ngIf="(f.upd_username.touched || submitted) && f.upd_username.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.upd_username.errors?.required">Email is required</div>
                                        </div>
                                        <div *ngIf="(f.upd_username.touched || submitted) && f.upd_username.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.upd_username.errors?.maxlength">Maximum 100 characters are
                                                allowed
                                            </div>
                                        </div>
                                    </div>
                                    <div class="example-wrap">
                                        <div class="checkbox-custom checkbox-default">
                                            <input type="checkbox" id="inputIssReset"
                                                formControlName="is_reset_password" (change)="handleChange($event)"
                                                autocomplete="off" />
                                            <label for="inputIssReset">Change Password</label>
                                        </div>
                                    </div>
                                    <div class="example-wrap">
                                        <label class="form-control-label" for="inputCurrent">Current Password</label>
                                        <input autocomplete="off" onload="this.value=''" type="password"
                                            class="form-control password" id="inputCurrent"
                                            formControlName="upd_password" placeholder="Current Password"
                                            autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.upd_password.touched || submitted) && f.upd_password.errors }" />
                                        <div *ngIf="(f.upd_password.touched || submitted) && f.upd_password.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="f.upd_password.errors.required">Current Password is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="example-wrap" formGroupName="passwords" *ngIf="isResetFlag">
                                        <div class="example-wrap">
                                            <label class="form-control-label" for="inputNew">New Password</label>
                                            <input autocomplete="off" type="password" class="form-control password"
                                                id="inputNew" formControlName="new_password" placeholder="New Password"
                                                autocomplete="off"
                                                [ngClass]="{ 'is-invalid': (f.passwords.controls.new_password.touched || submitted) && f.passwords.controls.new_password.errors }" />
                                            <div *ngIf="(f.passwords.controls.new_password.touched || submitted) && f.passwords.controls.new_password.errors?.required"
                                                class="invalid-feedback">
                                                <div *ngIf="f.passwords.controls.new_password.errors?.required">New
                                                    Password is required</div>
                                            </div>
                                            <div *ngIf="(f.passwords.controls.new_password.touched || submitted) && f.passwords.controls.new_password.errors?.maxlength"
                                                class="invalid-feedback">
                                                <div *ngIf="f.passwords.controls.new_password.errors?.maxlength">Maximum
                                                    100 characters are allowed</div>
                                            </div>
                                        </div>
                                        <div class="example-wrap">
                                            <label class="form-control-label" for="inputConfirm">Confirm
                                                Password</label>
                                            <input autocomplete="off" type="password" class="form-control"
                                                id="inputConfirm" formControlName="confirm_password"
                                                placeholder="Confirm Password" autocomplete="off" [ngClass]="{ 'is-invalid': (f.passwords.controls.confirm_password.touched || submitted) && (f.passwords.controls.confirm_password.errors?.required
											|| f.passwords.errors?.areEqual) }" />
                                            <div *ngIf="(f.passwords.controls.confirm_password.touched || submitted) && f.passwords.controls.confirm_password.errors?.required "
                                                class="invalid-feedback">
                                                <div *ngIf="f.passwords.controls.confirm_password.errors?.required">
                                                    Confirm Password is required</div>
                                            </div>
                                            <div *ngIf="(f.passwords.controls.confirm_password.touched || submitted) && f.passwords.controls.confirm_password.errors?.maxlength "
                                                class="invalid-feedback">
                                                <div *ngIf="f.passwords.controls.confirm_password.errors?.maxlength">
                                                    Maximum 100 characters are allowed</div>
                                            </div>
                                            <div *ngIf="(f.passwords.controls.confirm_password.touched || submitted) && (f.passwords.errors?.areEqual)"
                                                class="invalid-feedback">Passwords do not match
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group form-material mt-5 settings_btn">
                                        <button type="submit" class="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- End Example Basic Form (Form row) -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
