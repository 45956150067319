<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="page-header">
    <h1 class="page-title">Product Offer</h1>
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href='/home'>Home</a></li>
        <li class="breadcrumb-item active"> Product Offer</li>
    </ol>
</div>
<div class="container">
    <div id="preloader" style="display: none;">
        <div id="spinner">
            <img style="width:100%" src="http://testing.abharan.com/Backend/assets/images/loading.gif" alt="">
        </div>
        <div id="disable-preloader" class=""></div>
    </div>
    <div class="container ng-scope" ng-controller="ProductOffersController">
        <div class="panel">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-12 ">
                        <input type="hidden" name="_token" value="1kvOpmKciNR0pZOjnTZqaNleVppt9aFP2VJ0a9tV"
                        autocomplete="off">
                        <h4>
                        <span>Product Offer</span>
                        </h4>
                        <form [formGroup]="webForm" (ngSubmit)="webSubmit()">
                            <table class="table table-bordered" width="100%">
                                <tbody name="offer-price">
                                    <tr>
                                        <th width="20%">Type</th>
                                        <th>
                                            Amount
                                        </th>
                                        <th>
                                            Flat/Percentage
                                        </th>
                                        <th>
                                            Amount/Percentage
                                        </th>
                                        <th>Exclude Subcategory</th>
                                        <th>
                                            Update Control
                                        </th>
                                    </tr>
                                    <tr *ngFor="let web of websiteProduct$;let n=index">
                                        <td width="20%"><span id="type{{n}}">{{web.type}}</span></td>
                                        
                                        <td width="7%">
                                            <span id="status_text{{n}}">{{web.status=='1'?'Active':'Inactive'}}</span>
                                            <select class="form-control" formControlName="status" id="status{{n}}"
                                                hidden>
                                                <option value="1" [selected]="web.status=='1'">Active</option>
                                                <option value="0" [selected]="web.status=='0'" >Inactive</option>
                                            </select>
                                        </td>
                                        <td width="7%">
                                            <span id="percentage_text{{n}}">{{web.plat_or_percentage=='1'?'Flat':'Percentage'}}</span>
                                            <select class="form-control" formControlName="flat_or_per" hidden
                                                id="percentage{{n}}">
                                                <option value="1" [selected]="web.plat_or_percentage=='1'">Flat</option>
                                                <option value="0" [selected]="web.plat_or_percentage=='0'">Percentage
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <div hidden id="amount{{n}}">
                                                <input type="text"
                                                [ngClass]="{ 'is-invalid': (m.amount.touched || submitted) && m.amount.errors }"
                                                formControlName="amount" min="1" class="form-control" max="80"
                                                name="offer_id" value="6" autocomplete="off">
                                                <div *ngIf="(m.amount.touched || submitted) && m.amount.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="m.amount.errors.required">Amount is required
                                                    </div>
                                                </div>
                                            </div>
                                            <span id="amount_enter{{n}}">{{web.amount}} </span>
                                        </td>
                                        <td width="15%">
                                            <span id="sub{{n}}">
                                                <ng-container *ngFor="let sub of web.subcategory;">{{sub.title+' '}}
                                                </ng-container>
                                            </span>
                                            <div id="sub_drop{{n}}" hidden>
                                                <ngx-select-dropdown
                                                [ngClass]="{ 'is-invalid': (m.sub_cat.touched || submitted) && m.sub_cat.errors }"
                                                #id [config]="subCategory" formControlName="sub_cat"
                                                [options]="sub$" id="cat_select{{n}}" [multiple]=true
                                                (change)="selectSub($event)">
                                                </ngx-select-dropdown>
                                                <div *ngIf="(m.sub_cat.touched || submitted) && m.sub_cat.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="m.sub_cat.errors.required">Sub category is required
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <a class="btn btn-info btn-sm pull-right" (click)="edit($event,n,web)"
                                                name="edit-offer" id="edit-offer{{n}}"><i class="fa fa-pencil"></i> Edit
                                            </a>
                                            <button class="btn btn-success btn-sm hidden" name="edit-save"
                                            id="edit-save{{n}}"><i class="fa fa-save"></i> Save </button>
                                            <a class="btn btn-danger btn-sm hidden" (click)="cancel($event,n)"
                                                style="margin-left:10px;" id="edit-cancel{{n}}" name="edit-cancel"><i
                                                class="fa fa-times"></i> Cancel
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <script src="http://testing.abharan.com/Backend/jscontrols/product_controls.js"></script>
</div>