import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { BannersService, AlertService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
	selector: 'app-promotion',
	templateUrl: './promotion.component.html',
	styleUrls: ['./promotion.component.css']
})
export class PromotionComponent implements OnInit {
	bannerForm: FormGroup;
	loading = false;
	imageExist = '';
	fileToUpload: File = null;

	image: any;

	ids: any;

	submitted = false;
	//table = false;
	cancelButton = false;
	users$: Observable<any[]>;
	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<any> = new Subject();
	promotionWidth_limit = `${environment.promotionWidth}`;
	promotionHeight_limit = `${environment.promotionHeight}`;
	media_domain = `${environment.media_domain}`;
	curPage = 1;
	perPage = 10;

	new_promofile: File = null;

	constructor(
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private bannersService: BannersService,
		private alertService: AlertService,
		private route: ActivatedRoute,
		private router: Router
	) { }

	ngOnInit() {
		const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
		this.bannerForm = this.formBuilder.group({
			index: [{ value: null, disabled: true }],
			_id: [{ value: null, disabled: true }],
			name: [''],
			large_image: ['', [Validators.required]],
			status: ''
		});

		this.dtOptions = {
			pagingType: 'full_numbers',
			processing: true,
			pageLength: 10,
			language: {
				searchPlaceholder: "Status"
			},
			columnDefs: [{
				searchable: false,
				orderable: false,
				targets: 0
			}]
			//order: [[ 1, 'asc' ]]
		};

		this.bannersService.getPromotions().subscribe(data => {
			console.log(data);
			this.image = data.data.promo.image;
			this.ids = data.data.promo._id;
		}, error => {
			console.error(error);
		});


		$('#edit_new').on('click', function () {
			$('#file_take').click();

			console.log($('#file_take').val());
		});


		// if (!(this.image)) {
		// 	$('#edit_new').show();
		// 	$('#edit_new_delete').show();
		// 	$('.mb-15').removeClass('news');
		// }
	}



	changeFile(e) {
		console.error(e.target.files[0]);
		this.new_promofile = e.target.files[0];

		var that = this;

		alertify.confirm('Are you sure want to upload image?', function () {

			that.bannersService.uploadImage(that.new_promofile).subscribe(data => {
				console.log(data);
				that.bannersService.getPromotions().subscribe(data => {
					console.log(data);

					that.image = data.data.promo.image;
					that.ids = data.data.promo._id;
				}, error => {
					console.error(error);
				});
			}, error => {
				console.error(error);
			});
		}
			, function () { //alertify.error('Delete action cancelled')
				that.toastr.error('Upload action cancelled')
			});
	}

	handleFileInput(event: any, files: FileList) {
		var that = this;
		var reader = new FileReader();

		reader.onload = function (e) {
			var img = new Image;
			img.onload = function () {
				var imgWidth = parseInt(that.promotionWidth_limit)
				var imgHeight = parseInt(that.promotionHeight_limit)
				if (img.width != imgWidth || img.height != imgHeight) {
					that.toastr.error('Please upload image of ' + imgWidth + '*' + imgHeight)
					that.bannerForm.get('large_image').setValue('');

					return false;
				} else {
					that.fileToUpload = files.item(0);
					that.changeFile(event);
				}
			};
			img.src = reader.result as string;
		};

		reader.readAsDataURL(files.item(0));

	}
	// convenience getter for easy access to form fields
	get f() { return this.bannerForm.controls; }

	onSubmit(form, formWrap) {
		this.submitted = true;
		let id = form.getRawValue()._id;
		let index = form.getRawValue().index;

		if (id == null || !this.imageExist) {
			this.bannerForm.get('large_image').setValidators([Validators.required]);
			this.bannerForm.get('large_image').updateValueAndValidity();
		}
		// stop here if form is invalid
		if (this.bannerForm.invalid) {
			return;
		}


		if (id != null) {
			document.body.classList.add('jw-modal-open');
			this.loading = true;
			this.bannersService.updatePromo(id, this.f.name.value, this.f.status.value, this.fileToUpload)
				.subscribe(
					data => {
						if (data.status.code == 0) {
							this.submitted = false;
							document.body.classList.remove('jw-modal-open');
							this.loading = false;
							this.cancelButton = false;
							// document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
							// this.users$[index] = data.data.banner;
							this.bannerForm.setValue({
								index: null,
								_id: null,
								name: '',
								status: '',
								large_image: ''
							})
							formWrap.reset();
							this.imageExist = '';

							this.fileToUpload = null;

							//this.router.navigate(['/banners']);
							location.href = "/promotion";

						} else {
							document.body.classList.remove('jw-modal-open');
							this.loading = false;
							this.toastr.error(data.status.message)
							// alertify.error(data.status.message)
							//this.alertService.error(data.status.message);
						}
					},
					error => {
						this.toastr.error(error)
						// alertify.error(error)
						//this.alertService.error(error);
						document.body.classList.remove('jw-modal-open');
						this.loading = false;
					});
		} else {
			document.body.classList.add('jw-modal-open');
			this.loading = true;
			this.bannersService.savePromo(this.f.name.value, this.f.status.value, this.fileToUpload)
				.subscribe(
					data => {
						if (data.status.code == 0) {
							this.submitted = false;
							document.body.classList.remove('jw-modal-open');
							this.loading = false;
							this.cancelButton = false;
							location.reload();

							//this.users$.unshift(data.data.banner)

							this.bannerForm.setValue({
								index: null,
								_id: null,
								name: '',
								status: '',
								large_image: ''
							})
							formWrap.reset();
							this.fileToUpload = null;

							//this.router.navigate(['/banners']);
							location.href = "/promotion";

						} else {
							document.body.classList.remove('jw-modal-open');
							this.loading = false;
							this.toastr.error(data.status.message)
							// alertify.error(data.status.message)
							//this.alertService.error(data.status.message);
						}
					},
					error => {
						this.toastr.error(error)
						// alertify.error(error)
						//this.alertService.error(error);
						document.body.classList.remove('jw-modal-open');
						this.loading = false;
					});
		}
		//this.bannerForm.reset() // reset form to empty


	}

	imageEdit(event) {
		this.imageExist = '';
	}
	userEdit(event, user, i) {

		this.cancelButton = true;
		//this.table = event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
		document.getElementsByClassName("tb-wrapper")[0].classList.add('disabledTable');
		this.bannerForm.setValue({
			index: i,
			_id: user._id,
			name: user.title,
			status: parseInt(user.active),
			large_image: ''

		})
		this.imageExist = user.image;
	}

	cancelSubmit(event) {
		this.imageExist = '';
		this.cancelButton = false;
		document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
		this.bannerForm.setValue({
			index: null,
			_id: null,
			name: '',
			status: '',
			large_image: ''
		})
		this.submitted = false;
	}

	deleteImage(id) {
		var that = this;
		alertify.confirm('Are you sure want to delete this image', function () {
			that.bannersService.promoDelete(id)
				.subscribe(
					data => {
						that.bannersService.getPromotions().subscribe(data => {
							console.log(data);

							that.image = '';
							that.ids = '';
						}, error => {
							console.error(error);
						});
					},
					error => {
						that.toastr.error(error)
						// alertify.error(error)
						//this.alertService.error(error);
					});
		}
			, function () { //alertify.error('Delete action cancelled')
				that.toastr.error('Delete action cancelled')

			});

	}


	userDelete(event, user, i) {
		var that = this;
		alertify.confirm('Are you sure want to delete this record', function () {
			that.bannersService.promoDelete(user._id)
				.subscribe(
					data => {
						if (data.status.code == 0) {
							that.toastr.success('Deleted Successfully')
							// alertify.success('Deleted Successfully')
							setTimeout(function () {
								location.reload();
							}, 1000);
							//that.users$.splice(i, 1);
						} else {
							that.toastr.error(data.status.message)
							// alertify.error(data.status.message)
							//this.alertService.error(data.status.message);
						}
					},
					error => {
						that.toastr.error(error)
						// alertify.error(error)
						//this.alertService.error(error);
					});
		}
			, function () { //alertify.error('Delete action cancelled')
				that.toastr.error('Delete action cancelled')

			});

	}

	ngOnDestroy(): void {
		this.dtTrigger.unsubscribe();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			if ($.fn.dataTable.isDataTable('#table')) {
				var table = $('#table').DataTable();
			}
		}, 500);
	}
}
