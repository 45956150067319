<site-header></site-header>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Products</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Products</li>
        </ol>
    </div>
    <div class="container">
        <div class="page-content ccc container-fluid pt-0">
            <div class="row">
                <div class="col-xl-12">
                    <!-- Panel Kitchen Sink -->
                    <div class="panel">
                        <div class="abharan_main">
                            <div class="row">
                                <div class="panel-body col-md-8">
                                    <button class="btn btn-info btn-sm right prodview_edit"
                                        (click)="editSingleOrder()"><i class="fa fa-edit mr-1"></i>Edit</button>
                                    <button class="btn btn-danger btn-sm right ml-2 prodview_back" (click)="goBack()"><i
                                            class="fa fa-arrow-left mr-1"></i>Back</button>
                                    <div class="tb-wrapper">
                                        <table class="table table-bordered table-striped">
                                            <tbody *ngFor="let sin of productSingle$;let i=index">
                                                <tr>
                                                    <th>SKU</th>
                                                    <td>
                                                        <span>
                                                            <ng-container *ngIf="sin.SKU; else no_sku">
                                                                <a style="color:blue;"
                                                                    href="{{weblink}}/product-details/{{sin.SKU}}"
                                                                    target="_blank">
                                                                    {{sin.SKU}}
                                                                </a>
                                                            </ng-container>
                                                            <ng-template #no_sku>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>MAIN CATEGORY </th>
                                                    <td>
                                                        <span>
                                                            <ng-container *ngIf="sin.main_cat.title; else no_title">
                                                                {{sin.main_cat.title}}
                                                            </ng-container>
                                                            <ng-template #no_title>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <!-- <tr>
                                                    <th>HUID </th>
                                                    <td>
                                                        <span>
                                                            <ng-container *ngIf="sin.pro_details.HUID; else no_title">
                                                                {{sin.pro_details.HUID}}
                                                            </ng-container>
                                                            <ng-template #no_title>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr> -->
                                                <tr>
                                                    <th>SUB CATEGORY</th>
                                                    <td>
                                                        <span>
                                                            <ng-container *ngIf="sin.sub_cat.title; else no_title">
                                                                {{sin.sub_cat.title}}
                                                            </ng-container>
                                                            <ng-template #no_title>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>PRODUCT NAME</th>
                                                    <td>
                                                        <span>
                                                            <ng-container *ngIf="sin.name; else no_name">{{sin.name}}
                                                            </ng-container>
                                                            <ng-template #no_name>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>CATEGORY GRADES</th>
                                                    <td><span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.category_grade; else no_c_grades">
                                                                {{sin.pro_details.category_grade}}
                                                            </ng-container>
                                                            <ng-template #no_c_grades>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>WEIGHT</th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.weight; else no_weight">
                                                                {{sin.pro_details.weight}}
                                                            </ng-container>
                                                            <ng-template #no_weight>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>QUOTE WEIGHT</th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.q_weight; else no_quote">
                                                                {{sin.pro_details.q_weight}}
                                                            </ng-container>
                                                            <ng-template #no_quote>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>PURITY</th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.purity; else no_purity">
                                                                {{sin.pro_details.purity}}
                                                            </ng-container>
                                                            <ng-template #no_purity>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>METAL</th>
                                                    <td>
                                                        <span>
                                                            <ng-container *ngIf="sin.pro_details.metal; else no_metal">
                                                                {{sin.pro_details.metal}}
                                                            </ng-container>
                                                            <ng-template #no_metal>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <!-- <tr>
                                                <th>STONE WEIGHT</th>
                                                <td>
                                                    <span>
                                                        <ng-container *ngIf="sin.pro_details.s_weight; else no_s_weight">{{sin.pro_details.s_weight}}</ng-container>
                                                        <ng-template #no_s_weight>NA</ng-template>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>STONE VALUE</th>
                                                <td>
                                                    <span>
                                                        <ng-container *ngIf="sin.pro_details.s_value; else no_s_value">{{sin.pro_details.s_value}}</ng-container>
                                                        <ng-template #no_s_value>NA</ng-template>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>STONE DETAILS</th>
                                                <td>
                                                    <span>
                                                        <ng-container *ngIf="sin.pro_details.s_details; else no_s_details">{{sin.pro_details.s_details}}</ng-container>
                                                        <ng-template #no_s_details>NA</ng-template>
                                                    </span>
                                                </td>
                                            </tr> -->

                                                <tr>
                                                    <th>MAKING CHARGES PERCENTAGE</th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.making_charges_percentage; else no_making_charges_percentage">
                                                                {{sin.pro_details.making_charges_percentage}}
                                                            </ng-container>
                                                            <ng-template #no_making_charges_percentage>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>MAKING CHARGES</th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.making_amount; else no_making_amount">
                                                                {{sin.pro_details.making_amount}}
                                                            </ng-container>
                                                            <ng-template #no_making_amount>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>

                                                <!-- <tr>
                                                    <th>CENTS</th>
                                                    <td>
                                                        <span>
                                                            <ng-container *ngIf="sin.pro_details.cents; else no_cents">
                                                                {{sin.pro_details.cents}}
                                                            </ng-container>
                                                            <ng-template #no_cents>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr> -->
                                                <!-- <tr>
                                                    <th>DIAMOND VALUE</th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.daimond; else no_daimond_value">
                                                                {{sin.pro_details.daimond}}
                                                            </ng-container>
                                                            <ng-template #no_daimond_value>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr> -->

                                                <tr>
                                                    <th>COST</th>
                                                    <td>
                                                        <span>
                                                            <ng-container *ngIf="sin.pro_details.cost; else no_cost">
                                                                {{sin.pro_details.cost}}
                                                            </ng-container>
                                                            <ng-template #no_cost>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>DESCRIPTION</th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.description; else no_description">
                                                                {{sin.pro_details.description}}
                                                            </ng-container>
                                                            <ng-template #no_description>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>DETAILED DESCRIPTION</th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.detailed_description; else no_d_description">
                                                                {{sin.pro_details.detailed_description}}
                                                            </ng-container>
                                                            <ng-template #no_d_description>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>PRODUCT TAXABLE(YES/NO)</th>
                                                    <td><span>
                                                            NA
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>MAX SHIPPING DAYS</th>
                                                    <td>
                                                        <span>
                                                            <ng-container *ngIf="sin.pro_details.max; else no_max">
                                                                {{sin.pro_details.max}}
                                                            </ng-container>
                                                            <ng-template #no_max>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>CERTIFICATIONS</th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.certification; else no_certification">
                                                                {{sin.pro_details.certification}}
                                                            </ng-container>
                                                            <ng-template #no_certification>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>CERTIFICATION CHARGES</th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.certification_charges; else cer_charge">
                                                                {{sin.pro_details.certification_charges?sin.pro_details.certification_charges:'NA'}}
                                                            </ng-container>
                                                            <ng-template #cer_charge>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <!-- <tr>
                                                    <th>CUT</th>
                                                    <td>
                                                        <span>
                                                            <ng-container *ngIf="sin.pro_details.cut; else no_cut">
                                                                {{sin.pro_details.cut}}
                                                            </ng-container>
                                                            <ng-template #no_cut>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr> -->
                                                <tr>
                                                    <th>STONE WEIGHT </th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.stone_weight; else no_stone_weight">
                                                                {{sin.pro_details.stone_weight}}
                                                            </ng-container>
                                                            <ng-template #no_stone_weight>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>STONE VALUE</th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.stone_value; else no_stone_value">
                                                                {{sin.pro_details.stone_value}}
                                                            </ng-container>
                                                            <ng-template #no_stone_value>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>STONE DETAILS</th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.stone_details; else no_stone">
                                                                {{sin.pro_details.stone_details}}
                                                            </ng-container>
                                                            <ng-template #no_stone>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <!-- <tr>
                                                    <th>LACE COST</th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.lace_cost; else no_stone">
                                                                {{sin.pro_details.lace_cost}}
                                                            </ng-container>
                                                            <ng-template #no_stone>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr> -->

                                                <!-- <tr>
                                                    <th>INCLUDE BACKCHAIN (YES/NO)</th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.include_backchain; else no_stone">
                                                                {{sin.pro_details.include_backchain}}
                                                            </ng-container>
                                                            <ng-template #no_stone>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr> -->
                                                <tr>
                                                    <th>OTHER TAGS</th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.other_tags; else no_tags">
                                                                {{sin.pro_details.other_tags}}
                                                            </ng-container>
                                                            <ng-template #no_tags>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>NOTE</th>
                                                    <td>
                                                        <span>
                                                            <ng-container *ngIf="sin.pro_details.note; else no_note">
                                                                {{sin.pro_details.note}}
                                                            </ng-container>
                                                            <ng-template #no_note>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <!-- <tr>
                                            <th>SIZE</th>
                                            <td>
                                                <span>
                                                    <ng-container *ngIf="sin.pro_details.size; else no_size">
                                                    {{sin.pro_details.size}}</ng-container>
                                                    <ng-template #no_size>NA</ng-template>
                                                </span>
                                            </td>
                                        </tr> -->
                                                <tr>
                                                    <th>COLOR</th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.colour; else no_colour">
                                                                {{sin.pro_details.colour}}
                                                            </ng-container>
                                                            <ng-template #no_colour>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>CLARITY</th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.clarity; else no_clarity">
                                                                {{sin.pro_details.clarity}}
                                                            </ng-container>
                                                            <ng-template #no_clarity>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <!-- <tr>
                                                    <th>WOMEN</th>
                                                    <td>
                                                        <span>
                                                            <ng-container>
                                                                {{sin.pro_details.women?'Yes':'No'}}
                                                            </ng-container>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>MEN</th>
                                                    <td>
                                                        <span>
                                                            <ng-container>
                                                                {{sin.pro_details.men?'Yes':'No'}}
                                                            </ng-container>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>KIDS</th>
                                                    <td>
                                                        <span>
                                                            <ng-container>
                                                                {{sin.pro_details.kids?'Yes':'No'}}
                                                            </ng-container>
                                                        </span>
                                                    </td>
                                                </tr> -->
                                                <tr>
                                                    <th>SIZE</th>
                                                    <td>
                                                        <span>
                                                            <ng-container *ngIf="sin.pro_details.size; else no_size">
                                                                {{sin.pro_details.size}}
                                                            </ng-container>
                                                            <ng-template #no_size>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>OCCASION</th>
                                                    <td>
                                                        <span>
                                                            <ng-container
                                                                *ngIf="sin.pro_details.occasion; else no_occasion">
                                                                {{sin.pro_details.occasion}}
                                                            </ng-container>
                                                            <ng-template #no_occasion>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>IN STOCK</th>
                                                    <td>
                                                        <span>
                                                            <ng-container *ngIf="sin.in_stock; else no_in_stock">
                                                                {{sin.in_stock?'Yes':'No'}}
                                                            </ng-container>
                                                            <ng-template #no_in_stock>NA</ng-template>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>PRODUCT PRICE</th>
                                                    <td>
                                                        <span>
                                                            <ng-container *ngIf="sin.c_gross_value; else no_in_stock">
                                                                Rs. {{sin.c_gross_value ? sin.c_gross_value : 0}}
                                                            </ng-container>
                                                            <ng-template #no_in_stock>NA</ng-template>
                                                        </span>

                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>TOTAL PRICE</th>
                                                    <td>
                                                        <span>
                                                            <ng-container>
                                                                Rs. {{sin.total_price}}
                                                            </ng-container>
                                                            <ng-template #no_in_stock>NA</ng-template>
                                                        </span>

                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="panel-body col-md-4">
                                    <div class="tb-wrapper">
                                        <ng-container *ngIf="images;else no_way">
                                            <img class="single_img one_more"
                                                src="{{media_domain}}{{productSingle$[0].pro_images[0].image}}">
                                        </ng-container>
                                        <ng-template #no_way>
                                            <img class="single_img" src="/assets/default.png">
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Panel Kitchen Sink -->
                </div>
            </div>
        </div>
        <div class="page-content fff container-fluid pt-0" style="display: none">
            <div name="product_edit" class="mmm">
                <h4>
                    <span>Edit Product </span>
                    <div class="pull-right mb-2">
                        <a class="btn btn-danger btn-sm " (click)="cancelOp()" href="javascript:void(0);"
                            name="edit_cancel">
                            <i class="fa fa-arrow-left"></i> Cancel
                        </a>
                    </div>
                </h4>
                <form [formGroup]="productForm" (ngSubmit)="productSubmit()" class="form-horizontal"
                    name="update_product">
                    <div class="table-responsive">
                        <table class="table table-striped table_view" style="background:#fff !important;">
                            <tbody>
                                <tr>
                                    <td colspan="1">
                                        <fieldset>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">SKU <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="sku"
                                                            [ngClass]="{ 'is-invalid': (f.sku.touched || submitted) && f.sku.errors }"
                                                            name="sku" placeholder="SKU" class="form-control"
                                                            required="true" type="text">
                                                    </div>
                                                    <div *ngIf="(f.sku.touched || submitted) && f.sku.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.sku.errors?.required">Item No is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">MAIN CATEGORY <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group">
                                                        <ngx-select-dropdown #id [config]="mainCategory"
                                                            [options]="main$" [multiple]=false formControlName="mainCat"
                                                            (change)="selectmainCategory($event)"
                                                            [(ngModel)]="main_list"
                                                            [ngClass]="{ 'is-invalid': (f.mainCat.touched || submitted) && f.mainCat.errors?.required }">
                                                        </ngx-select-dropdown>
                                                    </div>
                                                    <div *ngIf="(f.mainCat.touched || submitted) && f.mainCat.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.mainCat.errors?.required">Main category is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">STONE VALUE </label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="stone_value"
                                                            [ngClass]="{ 'is-invalid': (f.stone_value.touched || submitted) && f.stone_value.errors }"
                                                            name="description" placeholder="Stone Value"
                                                            class="form-control" type="text">
                                                    </div>
                                                    <div *ngIf="(f.stone_value.touched || submitted) && f.stone_value.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.stone_value.errors?.required">Stone value is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">OCCASION <span
                                                        class="text-danger">*</span>
                                                </label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="occassion"
                                                            [ngClass]="{ 'is-invalid': (f.occassion.touched || submitted) && f.occassion.errors }"
                                                            name="occasion" placeholder="Occasion" class="form-control"
                                                            required="true" type="text"></div>
                                                    <div *ngIf="(f.occassion.touched || submitted) && f.occassion.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.occassion.errors?.required">Occasion is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">WEIGHT <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="weight"
                                                            [ngClass]="{ 'is-invalid': (f.weight.touched || submitted) && f.weight.errors }"
                                                            name="weight" placeholder="Weight" class="form-control"
                                                            required="true" type="text" data-parsley-type="number">
                                                    </div>
                                                    <div *ngIf="(f.weight.touched || submitted) && f.weight.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.weight.errors?.required">Weight is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">QUOTE WEIGHT <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group">
                                                        <div class="input-group"><input formControlName="q_weight"
                                                                [ngClass]="{ 'is-invalid': (f.q_weight.touched || submitted) && f.q_weight.errors }"
                                                                name="quote" placeholder="Quote Weight"
                                                                class="form-control" required="true" type="text"
                                                                data-parsley-type="number">
                                                        </div>
                                                    </div>
                                                    <div *ngIf="(f.q_weight.touched || submitted) && f.q_weight.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.q_weight.errors?.required">Quote weight is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">CERTIFICATIONS <span
                                                        class="text-danger">*</span>
                                                </label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="certifications"
                                                            [ngClass]="{ 'is-invalid': (f.certifications.touched || submitted) && f.certifications.errors }"
                                                            name="certification" placeholder="Certifications"
                                                            class="form-control" required="true" type="text"
                                                            data-parsley-type="number">
                                                    </div>
                                                    <div *ngIf="(f.certifications.touched || submitted) && f.certifications.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.certifications.errors?.required">Certification is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">PURITY <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="purity"
                                                            [ngClass]="{ 'is-invalid': (f.purity.touched || submitted) && f.purity.errors }"
                                                            name="purity" placeholder="Purity" class="form-control"
                                                            required="true" type="text" data-parsley-type="number">
                                                    </div>
                                                    <div *ngIf="(f.purity.touched || submitted) && f.purity.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.purity.errors?.required">Purity is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                         

                                        

                                            <!-- <div class="form-group">
                                        <label class="col-md-4 control-label">STONE WEIGHT <span
                                        class="text-danger">*</span>
                                    </label>
                                    <div class="col-md-8 inputGroupContainer">
                                        <div class="input-group"><input formControlName="s_weight"
                                            [ngClass]="{ 'is-invalid': (f.s_weight.touched || submitted) && f.s_weight.errors }"
                                            name="beeds" placeholder="Stone Weight" class="form-control"
                                            required="true" type="text" data-parsley-type="number">
                                        </div>
                                        <div *ngIf="(f.s_weight.touched || submitted) && f.s_weight.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.s_weight.errors?.required">Stone weight is
                                                required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-md-4 control-label">STONE VALUE <span
                                    class="text-danger">*</span>
                                </label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group"><input formControlName="s_value"
                                        [ngClass]="{ 'is-invalid': (f.s_value.touched || submitted) && f.s_value.errors }"
                                        name="stone_value" placeholder="Stone Value"
                                        class="form-control" required="true" type="text"
                                        data-parsley-type="number">
                                    </div>
                                    <div *ngIf="(f.s_value.touched || submitted) && f.s_value.errors?.required"
                                        class="invalid-feedback">
                                        <div *ngIf="f.s_value.errors?.required">Stone value is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-4 control-label">STONE DETAILS </label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group"><input formControlName="s_details"
                                        [ngClass]="{ 'is-invalid': (f.s_details.touched || submitted) && f.s_details.errors }"
                                        name="beeds_description" placeholder="Stone Details"
                                        class="form-control" type="text">
                                    </div>
                                    <div *ngIf="(f.s_details.touched || submitted) && f.s_details.errors?.required"
                                        class="invalid-feedback">
                                        <div *ngIf="f.s_details.errors?.required">Stone details is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                                         
                                        </fieldset>
                                    </td>
                                    <td colspan="1">
                                        <fieldset>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">MAKING CHARGES <span
                                                        class="text-danger">*</span>
                                                </label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="m_charges"
                                                            [ngClass]="{ 'is-invalid': (f.m_charges.touched || submitted) && f.m_charges.errors }"
                                                            name="mak_charges" placeholder="Making Charges"
                                                            class="form-control" required="true" type="text"
                                                            data-parsley-type="number">
                                                    </div>
                                                    <div *ngIf="(f.m_charges.touched || submitted) && f.m_charges.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.m_charges.errors?.required">Making charges
                                                            is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">STONE WEIGHT<span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="stone_weight"
                                                            [ngClass]="{ 'is-invalid': (f.stone_weight.touched || submitted) && f.stone_weight.errors }"
                                                            name="cost" placeholder="Stone weight" class="form-control"
                                                            required="true" type="text" data-parsley-type="number">
                                                    </div>
                                                    <div *ngIf="(f.stone_weight.touched || submitted) && f.stone_weight.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.stone_weight.errors?.required">Stone weight is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">PRODUCT NAME <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input name="prod_name"
                                                            placeholder="Product Name" formControlName="p_name"
                                                            [ngClass]="{ 'is-invalid': (f.p_name.touched || submitted) && f.p_name.errors }"
                                                            class="form-control" required="true" type="text">
                                                    </div>
                                                    <div *ngIf="(f.p_name.touched || submitted) && f.p_name.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.p_name.errors?.required">Product name is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label class="col-md-12 control-label">CATEGORY GRADES <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="c_grades"
                                                            [ngClass]="{ 'is-invalid': (f.c_grades.touched || submitted) && f.c_grades.errors }"
                                                            name="cat_grade" placeholder="Category Grades"
                                                            class="form-control" required="true" type="text"
                                                            data-parsley-type="number">
                                                    </div>
                                                    <div *ngIf="(f.c_grades.touched || submitted) && f.c_grades.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.c_grades.errors?.required">Category grade is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">NOTE1 </label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input name="stones" formControlName="note"
                                                            [ngClass]="{ 'is-invalid': (f.note.touched || submitted) && f.note.errors }"
                                                            placeholder="Note" class="form-control" type="text">
                                                    </div>
                                                    <div *ngIf="(f.note.touched || submitted) && f.note.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.note.errors?.required">Note is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label class="col-md-12 control-label">SIZE</label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input name="stones" formControlName="size"
                                                            [ngClass]="{ 'is-invalid': (f.size.touched || submitted) && f.size.errors }"
                                                            placeholder="Size" class="form-control" type="text">
                                                    </div>
                                                    <div *ngIf="(f.size.touched || submitted) && f.size.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.size.errors?.required">Size is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label class="col-md-12 control-label">METAL <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="metal"
                                                            [ngClass]="{ 'is-invalid': (f.metal.touched || submitted) && f.metal.errors }"
                                                            name="metal" placeholder="Metal" class="form-control"
                                                            required="true" type="text"></div>
                                                    <div *ngIf="(f.metal.touched || submitted) && f.metal.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.metal.errors?.required">Metal is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="form-group">
                                                <label class="col-md-12 control-label">MAKING TYPE <span
                                                        class="text-danger">*</span>
                                                </label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group">

                                                        <select name="making_type" formControlName="making_type"
                                                            (change)="changeMakingAmount($event)"
                                                            [ngClass]="{ 'is-invalid': (f.making_type.touched || submitted) && f.making_type.errors }"
                                                            placeholder="Size" class="form-control" required="true"
                                                            type="text" data-parsley-type="number">
                                                            <option value="1" selected> Flat </option>
                                                            <option value="2"> Percentage </option>

                                                        </select>

                                                    </div>
                                                    <div *ngIf="(f.making_type.touched || submitted) && f.making_type.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.making_type.errors?.required">Making type
                                                            is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                      
                                          

                                        </fieldset>
                                    </td>
                                    <td colspan="1">
                                        <fieldset>

                                            <div class="form-group">
                                                <label class="col-md-12 control-label">COST <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="cost"
                                                            [ngClass]="{ 'is-invalid': (f.cost.touched || submitted) && f.cost.errors }"
                                                            name="cost" placeholder="Cost" class="form-control"
                                                            required="true" type="text" data-parsley-type="number">
                                                    </div>
                                                    <div *ngIf="(f.cost.touched || submitted) && f.cost.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.cost.errors?.required">Cost is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">CLARITY <span
                                                        class="text-danger">*</span>
                                                </label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="clarity"
                                                            [ngClass]="{ 'is-invalid': (f.clarity.touched || submitted) && f.clarity.errors }"
                                                            name="clarity" placeholder="Clarity" class="form-control"
                                                            required="true" type="text"></div>
                                                    <div *ngIf="(f.clarity.touched || submitted) && f.clarity.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.clarity.errors?.required">Clarity is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">STONE DETAILS<span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input name="stones" formControlName="stone_details"
                                                            [ngClass]="{ 'is-invalid': (f.stone_details.touched || submitted) && f.stone_details.errors }"
                                                            placeholder="Stones Details" class="form-control"
                                                            required="true" type="text" data-parsley-type="number">
                                                    </div>
                                                    <div *ngIf="(f.stone_details.touched || submitted) && f.stone_details.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.stone_details.errors?.required"> Stone details is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">OTHER TAGS <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input name="stones" formControlName="other_tags"
                                                            [ngClass]="{ 'is-invalid': (f.other_tags.touched || submitted) && f.other_tags.errors }"
                                                            placeholder="Other tags" class="form-control"
                                                            required="true" type="text" data-parsley-type="number">
                                                    </div>
                                                    <div *ngIf="(f.other_tags.touched || submitted) && f.other_tags.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.other_tags.errors?.required">Other tags is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                           <div class="form-group">
                                                <label class="col-md-12 control-label">PRODUCT TAXABLE<span
                                                        class="text-danger">*</span>
                                                </label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group prod_tax"><input name="production"
                                                            value="Yes" type="radio" class="rdo" checked>
                                                        Yes
                                                        <input name="production" class="rdo prod_taxselect" value="No"
                                                            type="radio"> No
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">MAX SHIPPING DAYS <span
                                                        class="text-danger">*</span> </label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input name="max"
                                                            formControlName="max_shipping_days"
                                                            [ngClass]="{ 'is-invalid': (f.max_shipping_days.touched || submitted) && f.max_shipping_days.errors }"
                                                            placeholder="Max Shipping Days" class="form-control"
                                                            required="true" type="text" data-parsley-type="number">
                                                    </div>
                                                    <div *ngIf="(f.max_shipping_days.touched || submitted) && f.max_shipping_days.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.max_shipping_days.errors?.required">Max shipping
                                                            days is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">CERTIFICATION CHARGES <span
                                                        class="text-danger">*</span>
                                                </label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input
                                                            formControlName="certification_charges"
                                                            [ngClass]="{ 'is-invalid': (f.certification_charges.touched || submitted) && f.certification_charges.errors }"
                                                            name="certification" placeholder="Certification Charges"
                                                            class="form-control" required="true" type="text"
                                                            data-parsley-type="number">
                                                    </div>
                                                    <div *ngIf="(f.certification_charges.touched || submitted) && f.certification_charges.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.certification_charges.errors?.required">
                                                            Certification Charges is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                         
                                         
                                         


                                        </fieldset>
                                    </td>
                                    <td colspan="1">
                                        <fieldset>


                                            <!-- <div class="form-group">
    <label class="col-md-4 control-label">SIZE <span
    class="text-danger">*</span>
</label>
<div class="col-md-8 inputGroupContainer">
    <div class="input-group"><input formControlName="size"
        [ngClass]="{ 'is-invalid': (f.size.touched || submitted) && f.size.errors }"
        name="size" placeholder="Size" class="form-control"
    required="true" type="text"></div>
    <div *ngIf="(f.size.touched || submitted) && f.size.errors?.required"
        class="invalid-feedback">
        <div *ngIf="f.size.errors?.required">Size is
            required
        </div>
    </div>
</div>
</div> -->
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">COLOR <span
                                                        class="text-danger">*</span></label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input name="color" formControlName="color"
                                                            [ngClass]="{ 'is-invalid': (f.color.touched || submitted) && f.color.errors }"
                                                            placeholder="Color" class="form-control" required="true"
                                                            type="text"></div>
                                                    <div *ngIf="(f.color.touched || submitted) && f.color.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.color.errors?.required">Color is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">SUB CATEGORY</label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group">
                                                        <ngx-select-dropdown #id [config]="subCategory" [options]="sub$"
                                                            [multiple]=false formControlName="subCat"
                                                            (change)="selectSub($event)"
                                                            [ngClass]="{ 'is-invalid': (f.subCat.touched || submitted) && f.subCat.errors?.required }">
                                                        </ngx-select-dropdown>
                                                    </div>
                                                    <div *ngIf="(f.subCat.touched || submitted) && f.subCat.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.subCat.errors?.required">Sub category is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">DESCRIPTION </label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="desc"
                                                            [ngClass]="{ 'is-invalid': (f.desc.touched || submitted) && f.detailed_desc.errors }"
                                                            name="description" placeholder="Description"
                                                            class="form-control" type="text">
                                                    </div>
                                                    <div *ngIf="(f.desc.touched || submitted) && f.desc.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.desc.errors?.required"> description is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <!-- <div class="form-group">
<label class="col-md-4 control-label">COLLECTION <span
class="text-danger">*</span>
</label>
<div class="col-md-8 inputGroupContainer">
<div class="input-group"><input formControlName="collection"
[ngClass]="{ 'is-invalid': (f.collection.touched || submitted) && f.collection.errors }"
name="collection" placeholder="Collection"
class="form-control" required="true" type="text"></div>
<div *ngIf="(f.collection.touched || submitted) && f.collection.errors?.required"
class="invalid-feedback">
<div *ngIf="f.collection.errors?.required">Collection is
    required
</div>
</div>
</div>
</div> -->
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">DETAILED DESCRIPTION </label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input formControlName="detailed_desc"
                                                            [ngClass]="{ 'is-invalid': (f.detailed_desc.touched || submitted) && f.detailed_desc.errors }"
                                                            name="description" placeholder="Detailed Description"
                                                            class="form-control" type="text">
                                                    </div>
                                                    <div *ngIf="(f.detailed_desc.touched || submitted) && f.detailed_desc.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="f.detailed_desc.errors?.required">Detailed
                                                            description is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-md-12 control-label">IN STOCK </label>
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group"><input name="in_stock"
                                                            [checked]="in_stock==1" id="stock" value="0"
                                                            type="checkbox">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="col-md-12">
                                                    <div class="tb-wrapper">
                                                        <ng-container *ngIf="images;else no_ways">
                                                            <img id="direct" style="height: 100px;width: 150px;"
                                                                src="{{media_domain}}{{images}}">
                                                        </ng-container>
                                                        <ng-template #no_ways>
                                                            <img id="direct" style="height: 100px;width: 150px;"
                                                                src="/assets/default.png">
                                                        </ng-template>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="col-md-12 inputGroupContainer">
                                                    <div class="input-group">
                                                        <input (change)="handleFileInput($event, $event.target.files)"
                                                            type="file" class="form-control w-25">
                                                    </div>
                                                </div>
                                            </div>
                                         
                                        


                                       
                                        </fieldset>
                                    </td>
                                </tr>
                                <button type="submit" class="btn btn-primary ml-4 mb-2 prodedit_update">Update</button>
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<script src="/assets/js/bootstrap-table.js"></script>
<!-- End Page -->
<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }
</style>