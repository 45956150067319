import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ModalService, HomeService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var moment: any;
@Component({
  selector: 'app-productclick',
  templateUrl: './productclick.component.html',
  styleUrls: ['./productclick.component.css']
})
export class ProductclickComponent implements OnInit, AfterViewInit {

  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
  sel_usr_type = '';
  media_domain = `${environment.media_domain}`;
  dob: any;
  previousRoute: string;
  creditForm: FormGroup;
  creditUser: any = [];
  currentIndex = null;
  loading = false;
  previousUrl = '';
  submitted = false;
  users$: any;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  user_details: any;
  curPage = 1;
  perPage = 10;
  apiUrl="";
  search:any;

  last_page: any;

  total: any;
  from:any;
  to:any;
  searchUser: any;
  news$ : any;
  limit:any;
  pageCounter:any;
  topage:any;
  page:any;



  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private home: HomeService
  ) {
  }
  get f() { return this.creditForm.controls; }


  public async down(): Promise<void> {
    this.submitted = true;
    document.body.classList.add('jw-modal-open');
    this.loading = true;



    const blob = await this.home.download(null


    );
    let binaryData = [];
    binaryData.push(blob);
    const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
    // const url = window.URL.createObjectURL(blob);

    const link = this.downloadZipLink.nativeElement;
    //$('.select_check').prop('checked', false);
    link.href = url;
    console.log(link.href);
    link.download = 'orders.xlsx';
    link.click();

    // window.URL.revokeObjectURL(url);
    this.loading = false;
    document.body.classList.remove('jw-modal-open');
    // link.stop();
  }

  ngOnInit() {

    const that = this;



  this.apiUrl = `${environment.apiUrl}`;
    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 10,
      language: {
        searchPlaceholder: "Name"
      },
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }]
      //order: [[ 1, 'asc' ]]
    };

    this.search = 'products';
    this.loading = true;
    this.home.getMostclick(this.search, '').subscribe(data => {
      that.loading = false;
 
      this.users$ = data.data.items.data;

      this.total= data.data.items.total;
      this.from = data.data.items.from;
      this.to = data.data.items.to;
      this.last_page = data.data.items.last_page;
      this.curPage = data.data.items.current_page;
      // this.page = data.data.items.page;
      // this.pageCounter = data.data.items.pagingCounter;
      // this.topage = (this.limit * (this.page - 1)) + this.users$.length;

      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }


  pageChange(e){
    this.loading = true;
    this.home.getMostclick(this.search,e).subscribe(data => {
      this.loading = false;
      this.users$ = data.data.items.data;
      this.total= data.data.items.total;
      this.from = data.data.items.from;
      this.to = data.data.items.to;
      this.last_page = data.data.items.last_page;
      this.curPage = data.data.items.current_page;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });
  }

  closeModal(id: string) {

    this.modalService.close(id);
  }



  searchFilter(search){
    this.search = search;
 //   var that = this;
 this.loading = true;
 this.home.getMostclick(this.search, this.curPage).subscribe(data => {
  this.loading = false;
  this.users$ = data.data.items.data;
  this.dtTrigger.next();
}, error => {
  console.error(error);
});

if ($.fn.dataTable.isDataTable('#table')) {
  var table = $('#table').DataTable();
}
    // var table = $('#table').DataTable();
    // table.draw();
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }
 
//   ngAfterViewInit() {
//     if ($.fn.dataTable.isDataTable('#table')) {
//       $('#table').DataTable().clear().destroy();               
//  }
// $('#table').DataTable({
// paging:false,
// searching:false
// })
//   }
}
