<site-header></site-header>
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Schemes</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Schemes</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row">
            <div class="col-xl-12">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <div class="abharan_main">
                        <!-- <header class="panel-heading">
                            <h3 class="panel-title">
                                Schemes
                            </h3>
                        </header> -->
                        <div class="abharan_upload ">
                            <div class="row schemes_main">
                                <div class="col-sm-6">
                                    <div class="upload_left">
                                        <ul>
                                            <li><b>Upload Excel</b></li>
                                            <li>
                                                <div class="upload-input"><input type="file" id="upload_file"
                                                        (change)="uploadExcel($event,$event.target.files)"
                                                        name="file-upload"></div>
                                            </li>
                                            <li>
                                                <button class="upload_btn" (click)="uploadExcelFile()">Upload</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <!-- <div class="col-sm-3">
                                        <ngx-select-dropdown #id [config]="mainCat" [options]="main$" [multiple]=true (change)="selectmainCategory($event)"
                                        class="clientSelect" style="height:35px;">
                                        </ngx-select-dropdown>
                                    </div>
                                    
                                    <div class="col-sm-3">
                                        <ngx-select-dropdown #id [config]="subCategory" [options]="sub$" [multiple]=true
                                        (change)="selectsubCategory($event)" class="clientSelect" style="height:35px;">
                                        </ngx-select-dropdown>
                                    </div> -->
                                    <div class="upload_right">
                                        <ul>
                                            <li class="diamond">
                                                <a (click)="download()" style="cursor: pointer;">
                                                    <i class="fas fa-download"></i> Download Template
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel-body">
                            <div class="row scheme_table_main">
                                <div class="col-sm-12">
                                    <div class="tb-wrapper">
                                        <div class="table-responsive">
                                            <table cellspacing="0" id="table"
                                            class="table table-hover dataTable table-striped table-bordered scheme_table w-full" datatable
                                            [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                            <thead>
                                                <tr>
                                                    <th>Sl.No.</th>
                                                    <th>SCHEME NUMBER</th>
                                                    <th>USER NAME</th>
                                                    <th>SCHEME TYPE</th>
                                                    <th>BRANCH</th>
                                                    <th>MOBILE NUMBER</th>
                                                    <th>AMOUNT</th>
                                                    <th>LAST PAID</th>
        
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let scheme of schemes$;let i=index">
                                                    <td>{{i+1}}</td>
                                                    <td>{{scheme.scheme_number ? scheme.scheme_number : '-' }}</td>
                                                    <td>{{scheme.user_name ? scheme.user_name : '-' }}</td>
                                                    <td>{{scheme.scheme_type ? scheme.scheme_type : '-' }}</td>
                                                    <td>{{scheme.branch ? scheme.branch : '-' }}</td>
                                                    <td>{{scheme.mobile_number ? scheme.mobile_number : '-' }}</td>
                                                    <td>{{scheme.amount ? scheme.amount : '-' }}</td>
                                                    <td>{{scheme.last_paid ? scheme.last_paid : '-'}} </td>
        
        
        
                                                    <td>
                                                        <!-- <a (click)="editScheme($event,scheme._id)" class="view_btn edit">Edit</a> -->
                                                        <a (click)="deleteScheme($event,scheme._id)"
                                                            class="view_btn delete">Delete</a>
        
        
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                        

                                    </div>
                                </div>
                            </div>
                           

                        </div>
                    </div>
                </div>
                <!-- End Panel Kitchen Sink -->
            </div>
        </div>
    </div>
</div>
<script src="/assets/js/bootstrap-table.js"></script>
<!-- End Page -->
<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }
</style>