<site-header></site-header>
<style type="text/css">
img {
    height: auto;
    max-width: 100%;
}

.cell {
    display: table-cell;
}

.cell-fluid {
    width: 100%;
}
</style>
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Payments</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Payments</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row">
            <div class="col-xl-12">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <header class="panel-heading">
                        <h3 class="panel-title">
                          Payments

                        </h3>
                    </header>
                    <div class="panel-body">
                        <div class="tb-wrapper">
                          <table cellspacing="0" id="table" class="table table-hover dataTable table-striped w-full"
                          datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                              <thead>
                                <tr>
                                    <th width="20%">Sl No.</th>
                                    <th width="20%">User Name </th>
                                    <th width="20%">Plan </th>


                                    <th width="20%">Amount</th>
                                   <th width="20%">View</th>

                                </tr>
                            </thead>
                            <tbody *ngIf="payments$?.length > 0">
                              <tr *ngFor="let payment of payments$; let i = index">
                                  <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                  <td>
                                      <div class="cell">{{payment.user.name}}
                                      </div>
                                  </td>
                                  <td >
                                    <div class="cell" *ngIf="payment.payment">{{payment.payment.plan}}
                                    </div>

                                    <div class="cell" *ngIf="!payment.payment">NA
                                    </div>
                                </td>

                                  <td>
                                      <div class="cell">Rs. {{payment.amount}}
                                      </div>
                                  </td>
                                  <td>
                                    <div class="cell"><a href="javascript::void(0)" class="btn btn-wning payment_view" (click)="userDetails(payment)">View</a>
                                    </div>
                                </td>


                              </tr>
                          </tbody>
                                <tbody *ngIf="payments$?.length == 0">
                                    <tr>
                                        <td colspan="7" class="no-data-available">No data!</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- End Panel Kitchen Sink -->
            </div>
        </div>
    </div>
</div>
<jw-modal id="user_details">
  <div id="login">
      <div class="col-md-12">
          <div class="modal-header" style=" padding: 0; padding-bottom: 10px;">
              <h4 style="width: 100%" class="modal-title" id="myModalLabel">
                  Payment  <a href="javascript:void(0);" style="float: right" (click)="closeModal('user_details')"><i class="icon md-close   text-primary "></i></a>
              </h4>
          </div>
          <hr>
          <!-- Example Horizontal Form -->
          <div class="">
            <h4 class="title">Payment Details</h4>
              <div class="example" *ngIf="payment_details$">
               <div class="row">
                 <div class="col-md-4 sub_title">Plan</div>
                 <div class="col-md-2">:</div>
                 <div class="col-md-3">{{payment_details$.plan}}</div>
               </div>
               <div class="row">
                <div class="col-md-4 sub_title"> Title</div>
                <div class="col-md-2">:</div>
                <div class="col-md-3">{{payment_details$.title}}</div>
              </div>
              <div class="row">
                <div class="col-md-4 sub_title"> Amount</div>
                <div class="col-md-2">:</div>
                <div class="col-md-3">{{payment_details$.amount}}</div>
              </div>
              <div class="row">
                <div class="col-md-4 sub_title">Payment Description</div>
                <div class="col-md-2">:</div>
                <div class="col-md-3">{{payment_details$.discription}}</div>
              </div>

              </div>
              <div class="example no-details-available" *ngIf="!payment_details$">
                No details Found
              </div>
              <hr>
              <h4 class="title">User Details</h4>
              <div class="example" *ngIf="user_details$">
                <div class="row">
                  <div class="col-md-4 sub_title">Name</div>
                  <div class="col-md-2">:</div>
                  <div class="col-md-3">{{user_details$.name}}</div>
                </div>
                <div class="row">
                 <div class="col-md-4 sub_title"> Email</div>
                 <div class="col-md-2">:</div>
                 <div class="col-md-3">{{user_details$.email}}</div>
               </div>
               <div class="row">
                 <div class="col-md-4 sub_title"> Mobile Number</div>
                 <div class="col-md-2">:</div>
                 <div class="col-md-3">{{user_details$.country_code}}{{user_details$.mobile_no}}</div>
               </div>
               <div class="row">
                 <div class="col-md-4 sub_title">Gender</div>
                 <div class="col-md-2">:</div>
                 <div class="col-md-3">{{user_details$.gender}}</div>
               </div>
               <div class="row">
                 <div class="col-md-4 sub_title">Location</div>
                 <div class="col-md-2">:</div>
                 <div class="col-md-3">{{user_details$.location}}</div>
               </div>


               </div>

               <div class="example no-details-available" *ngIf="!user_details$">
                No details Found
              </div>
          </div>
      </div>

  </div>
</jw-modal>
<script src="/assets/js/bootstrap-table.js"></script>
<!-- End Page -->
<style type="text/css">
.example-wrap {
    margin-bottom: 30px;
}

.disabledTable {
    pointer-events: none;
    opacity: 0.4;
}
</style>
